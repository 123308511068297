import * as types from './mutation-types'

// state
export const state = () => ({
  overlap: false,
})

// getters
export const getters = {
  overlap: state => state.overlap,
}

// mutations
export const mutations = {
  [types.SET_NAV_OVERLAP] (state, payload) {
    state.overlap = payload;
  },
}

// actions
export const actions = {
  setNavOverlap ({ commit }, payload ) {
    commit(types.SET_NAV_OVERLAP, payload);
  },
}
