import * as types from './../mutation-types'

export const state = () => ({
  list: {
    total: 0,
  },
  selected: {},
  busy: true,
})

export const getters = {
  listPagination: state => state.list,
  list: state => state.list.data,
  selected: state => state.selected,
  busy: state => state.busy,
}

export const mutations = {
  [types.FETCH_FORMS_LIST_SUCCESS] (state, data) {
    state.list = data
  },
  [types.FETCH_FORMS_LIST_FAILURE] (state) {
    state.list = {}
  },
  [types.SET_FORMS_BUSY] (state, status) {
    state.busy = status
  },
}

export const actions = {
  async fetchFormsList({ commit, rootState }, payload) {
    commit(types.SET_FORMS_BUSY, true)
    const page = payload.page ?? 1
    const franchiseId = rootState['business-v2'].franchise?.id ?? null

    let url = `/api/v2/franchisor-contacts/forms/?page=${page}`
    if (franchiseId) url = `${url}&franchise_id=${franchiseId}`

    try {
      const { data } = await this.$axios.get(url)
      commit(types.FETCH_FORMS_LIST_SUCCESS, data.result)
    } catch (e) {
      console.log(e);
      commit(types.FETCH_FORMS_LIST_FAILURE)
    }
    commit(types.SET_FORMS_BUSY, false)
  },
}
