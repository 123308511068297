import * as types from './mutation-types'

export const state = () => ({
  message: '',
  messageArray: [],
  messageArrayAsNumberList: false
})

export const getters = {
  message: state => state.message,
  messageArray: state => state.messageArray,
  messageArrayAsNumberList: state => state.messageArrayAsNumberList
}

export const mutations = {
  [types.SET_MODAL_MESSAGE_DATA] (state, payload) {
    if (payload.messageArray) {
      state.message = ''
      state.messageArray = payload.messageArray

      if (payload.messageArrayAsNumberList) {
        state.messageArrayAsNumberList = payload.messageArrayAsNumberList
      }
    } else if (payload.message) {
      state.message = payload.message
      state.messageArray = []
      state.messageArrayAsNumberList = false
    }
  },
  [types.CLEAR_MODAL_MESSAGE_PAYLOAD] (state) {
    state.message = ''
    state.messageArray = []
    state.messageArrayAsNumberList = false
  }
}

export const actions = {
  fireMessage({ commit }, payload) {
    commit(types.SET_MODAL_MESSAGE_DATA, payload);
  },
  resetModal({ commit} ) {
    commit(types.CLEAR_MODAL_MESSAGE_PAYLOAD);
  }
}
