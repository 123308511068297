import * as types from './mutation-types'

// helpers

const convertToBinary = (landing) => {
  const clean = input => {
    const limitChar = 127
    let output = "";
    for (let i=0; i<input.length; i++) {
      if (input.charCodeAt(i) <= limitChar) {
        output += input.charAt(i);
      }
    }
    return output;
  }
	const content = clean(JSON.stringify(landing))
	return content.split('').map((char) => char.charCodeAt(0).toString(2)).join(' ')
}

// State
export const state = () => ({
	busy: false,
	landing:{},
	franchise: {
		slug: '',
		franchiseId: '',
		versionId: '',
		name:'',
	},
	main_styles: {
		fontFamily: 'Inter, sans-serif',
		backgroundColor: '#00a769',
		color: 'white',
	},
	menu: [],
	logo: {
		url: "",
		title: "",
	},
	max_index: 0,
	modal_ids: {
		contact: 'landing_modal_contact_us',
		mobileMenu: 'landing_modal_mobile_menu'
	},
	defaultMenu:[
		{
			name: "Concept",
			sectionIndex: "our_concept"
		},
		{
			name: "Investment",
			sectionIndex: "investment"
		},
		{
			name: "Earnings",
			sectionIndex: "earnings"
		},
		{
			name: "Next Steps",
			sectionIndex: "next_steps"
		},
		{
			name: "Insight",
			sectionIndex: "insight"
		},
		{
			name: "Videos",
			sectionIndex: "videos"
		}
	],

})

// Getters
export const getters = {
	landing: state => state.landing,
	franchise: state => state.franchise,
	main_styles: state => state.main_styles,
	modal_ids: state => state.modal_ids,
	max_index: state => state.max_index,
  modalFileUrl: state => state.modalFileUrl,
	menu: state => state.menu,
	logo: state => state.logo,
	defaultMenu: state => state.defaultMenu,
}

// Mutations
export const mutations = {
  [types.SET_MODAL_FILE_URL] (state, payload) {
    state.modalFileUrl = payload;
  },
	[types.FETCH_FRANCHISE_SUCCESS](state, data) {
		state.franchise = data.franchise;
		state.landing = data.landing;
	},
	[types.FETCH_FRANCHISE_FAILURE](state) {
		state.franchise = null;
	},
	[types.CHANGE_MAIN_STYLES](state, mainStyles) {
		state.main_styles = mainStyles;
	},
	[types.CHANGE_MENU_ARR](state, menu) {
		state.menu = menu;
	},
	[types.CHANGE_LOGO](state, logo) {
		state.logo = logo;
		state.landing.logo = logo;
	},
	[types.CHANGE_MAX_INDEX](state, count) {
		state.max_index = count;
	},
	[types.CHANGE_INDEX_SECTION](state, data) {
  	const sections = state.landing.body.section
		if (data.fromIndex > data.toIndex){
			[sections[data.toIndex],sections[data.fromIndex]] = [sections[data.fromIndex],sections[data.toIndex]]
		}else {
			[sections[data.fromIndex],sections[data.toIndex]] = [sections[data.toIndex],sections[data.fromIndex]]
		}
	},
	[types.CHANGE_VISIBILITY_SECTION](state, index) {
		const currentObj = state.landing.body.section[index]
		const hasDefaultMenu = state.defaultMenu.findIndex(item => item.sectionIndex === currentObj.id) !== -1

		currentObj.visible = !currentObj.visible

		if (!hasDefaultMenu)
			return

		const currentMenu = state.menu
		const hasCurrentMenu = !!currentMenu.filter(item => item.sectionIndex === currentObj.id).length


		if (currentObj.visible && hasCurrentMenu)
			return

		const indexMenuItem = currentMenu.findIndex(item => item.sectionIndex === currentObj.id)
		const itemMenu = state.defaultMenu.find(item => item.sectionIndex === currentObj.id)

		if (currentObj.visible && !hasCurrentMenu)
			state.menu.push(itemMenu)

		if (!currentObj.visible && hasCurrentMenu)
			currentMenu.splice(indexMenuItem, 1)

	},

	[types.CLEAR_BACKGROUND_IMAGE_SECTION](state, index) {
		state.landing.body.section[index].styles.backgroundImage = ''
	},
}
// Actions
export const actions = {
  setModalFileUrl ({ commit }, payload ) {
    commit(types.SET_MODAL_FILE_URL, payload);
  },
	async fetchFranchise({state, commit}, route = {}) {
		try {
			const slug = typeof route.slug !== "undefined" ? route.slug : state.franchise.slug
			const franchiseId = typeof route.franchise_id !== "undefined" ? route.franchise_id : state.franchise.franchiseId
			const versionId = typeof route.version_id !== "undefined" ? route.version_id : state.franchise.versionId

			let url;
			const active = 0;
			if (slug !== '') {
				url = `/api/v2/landing/${slug}`;
			} else if (franchiseId !== '' && versionId !== '') {
				url = `/api/v2/landing/${franchiseId}/${versionId}/${active}`;
			} else {
				commit(types.FETCH_FRANCHISE_FAILURE);
				return
			}

			const {data} = await this.$axios.get(url);
			const landing = JSON.parse(data.result.landing.gjs_html)
			const landingMainStyles = landing.body.section[0].block.button.styles

			const franchise = {
				franchiseId: data.result.franchise_id,
				versionId: data.result.version_id,
        industry: data.result.industry,
				name: data.result.business_name,
				slug
			}

			const mainStyles = {
				fontFamily: typeof landing.main_styles === "undefined" ? state.main_styles.fontFamily : landing.main_styles.fontFamily,
				backgroundColor: typeof landingMainStyles.backgroundColor === "undefined" ? state.main_styles.backgroundColor : landingMainStyles.backgroundColor,
				color: typeof landingMainStyles.color === "undefined" ? state.main_styles.color : landingMainStyles.color,
			}

			commit(types.FETCH_FRANCHISE_SUCCESS, { franchise, landing });
			commit(types.CHANGE_MAIN_STYLES, mainStyles);
			commit(types.CHANGE_MENU_ARR, landing.menu);
			commit(types.CHANGE_LOGO, landing.logo);
			commit(types.CHANGE_MAX_INDEX, landing.body.section.length-1);
		} catch (e) {
			console.log(e)
			commit(types.FETCH_FRANCHISE_FAILURE)
		}

	},
	async updateSection({dispatch, state}, data = {}) {
		const landingArr = state.landing

		landingArr.body.section[data.index] = data.section

		dispatch('saveLanding')
	},
	async clearBackgroundImageSection({dispatch, commit}, index) {

		commit(types.CLEAR_BACKGROUND_IMAGE_SECTION, index)

		dispatch('saveLanding')
	},
	async changeSectionPosition({dispatch,commit}, data = {}) {

  	if(typeof data.fromIndex === "undefined")
  		return

		commit(types.CHANGE_INDEX_SECTION, data)
		dispatch('saveLanding')
	},
	async changeSectionVisibility({dispatch,commit}, index) {
		commit(types.CHANGE_VISIBILITY_SECTION, index)
		dispatch('saveLanding')
	},
	async updateLogo({dispatch, state, commit}, logoId){
		const logo = {
			url: logoId,
			title: '',
		}
		commit(types.CHANGE_LOGO, logo)

		dispatch('saveLanding')
	},
	async saveLanding({dispatch, state}, active = 0) {
		this.$toast.info('Data sent')

		try {
			const codedContent = convertToBinary(state.landing);
			await this.$axios.put(
			  `/api/v2/landing/${state.franchise.franchiseId}/${state.franchise.versionId}/${active}`,
        { content: codedContent }
      )
			dispatch('fetchFranchise')

			const msg = active ? "Changes are saved and published" : "Changes are saved";
			this.$toast.success(msg);
		} catch (e) {
			this.$toast.error("Something went wrong")
			console.log(e);
		}
	},
}
