import {USER_PREFERENCES_TOTAL_AMOUNT_INVEST} from "@/store/constants";
import {cloneDeep} from 'lodash/fp'
import Vue from 'vue'
import * as types from '~/store/mutation-types'

// state
export const state = () => ({
  preferencesIndustries: [],
  preferencesStates: [],
  preferencesInvestment: null,
  franchiseTotalCounter: 0,
  user_edit_modal: false,
  show_preloader: true,
  selectedFranchise: {},
  showFdd: false,
  focusFilter: false,
  mobile_step_investment: 0,
  sort: {
    field: 'business_name',
    direction: 'asc',
  },
  pagination: {
    "first": null,
    "last": null,
    "prev": null,
    "next": null,
    "current_page": 1,
    "from": 1,
    "last_page": null,
    "path": "",
    "per_page": 6,
    "to": null,
    "total": null
  },
  listFranchise: [],
  paginationCompare: {
    "first": null,
    "last": null,
    "prev": null,
    "next": null,
    "current_page": 1,
    "from": 1,
    "last_page": null,
    "path": "",
    "per_page": 10,
    "to": null,
    "total": null
  },
  listFranchiseCompare: [],
  paginationLike: {
    "first": null,
    "last": null,
    "prev": null,
    "next": null,
    "current_page": 1,
    "from": 1,
    "last_page": null,
    "path": "",
    "per_page": 12,
    "to": null,
    "total": null
  },
  listFranchiseLike: [],
  paginationProgress: {
    "first": null,
    "last": null,
    "prev": null,
    "next": null,
    "current_page": 1,
    "from": 1,
    "last_page": null,
    "path": "",
    "per_page": 6,
    "to": null,
    "total": null
  },
  listFranchiseProgress: [],
  busyFetchListFranchise: false,
  busyUpdateStatusComparisonFranchise: null,
  busyUpdateStatusProgressFranchise: null,
  busyUpdateStatusLikeFranchise: null,
  franchiseeViewId: null,
  userClick: false,
  userObj: null,
  pendingListArr: [],
})

// getters
export const getters = {
  preferencesIndustries: state => state.preferencesIndustries,
  preferencesStates: state => state.preferencesStates,
  preferencesInvestment: state => state.preferencesInvestment,
  franchiseTotalCounter: state => state.franchiseTotalCounter,
  user_edit_modal: state => state.user_edit_modal,
  show_preloader: state => state.show_preloader,
  selectedFranchise: state => state.selectedFranchise,
  showFdd: state => state.showFdd,
  focusFilter: state => state.focusFilter,
  mobile_step_investment: state => state.mobile_step_investment,
  pagination: state => state.pagination,
  listFranchise: state => state.listFranchise,
  paginationCompare: state => state.paginationCompare,
  listFranchiseCompare: state => state.listFranchiseCompare,
  paginationLike: state => state.paginationLike,
  listFranchiseLike: state => state.listFranchiseLike,
  paginationProgress: state => state.paginationProgress,
  listFranchiseProgress: state => state.listFranchiseProgress,
  busyFetchListFranchise: state => state.busyFetchListFranchise,
  busyUpdateStatusComparisonFranchise: state => state.busyUpdateStatusComparisonFranchise,
  busyUpdateStatusProgressFranchise: state => state.busyUpdateStatusProgressFranchise,
  busyUpdateStatusLikeFranchise: state => state.busyUpdateStatusLikeFranchise,
  franchiseeViewId: state => state.franchiseeViewId,
  sort: state => state.sort,
  userClick: state => state.userClick,
  userObj: state => state.userObj,
  pendingListArr: state => state.pendingListArr,
}

// mutations
export const mutations = {
  [types.UPDATE_PREFERENCES_INDUSTRIES](state, preferencesIndustry) {
    state.preferencesIndustries = preferencesIndustry
  },
  [types.UPDATE_PREFERENCES_STATES](state, preferencesState) {
    state.preferencesStates = preferencesState
  },
  [types.UPDATE_PREFERENCES_INVESTMENT](state, preferencesInvestment) {
    state.preferencesInvestment = preferencesInvestment
  },
  [types.SET_SHOW_PRELOADER](state, payload) {
    state.show_preloader = payload
  },
  [types.SET_SELECTED_FRANCHISE](state, item) {
    state.selectedFranchise = item
  },
  [types.SET_SHOW_FDD](state, showFdd) {
    state.showFdd = showFdd
  },
  [types.SET_FOCUS_FILTER](state, toggle) {
    state.focusFilter = toggle
  },
  [types.SET_FRANCHISE_TOTAL_COUNTER](state, counter) {
    state.franchiseTotalCounter = counter
  },
  [types.UPDATE_ACCOUNT_PAGINATION](state, pagination) {
    state.pagination = pagination
  },
  [types.UPDATE_ACCOUNT_LIST_FRANCHISE](state, listFranchise) {
    state.listFranchise = listFranchise
  },
  [types.UPDATE_ACCOUNT_PAGINATION_COMPARE](state, paginationCompare) {
    state.paginationCompare = paginationCompare
  },
  [types.UPDATE_ACCOUNT_LIST_FRANCHISE_COMPARE](state, listFranchiseCompare) {
    state.listFranchiseCompare = listFranchiseCompare
  },
  [types.UPDATE_ACCOUNT_PAGINATION_LIKE](state, paginationLike) {
    state.paginationLike = paginationLike
  },
  [types.UPDATE_ACCOUNT_LIST_FRANCHISE_LIKE](state, listFranchiseLike) {
    state.listFranchiseLike = []

    if (listFranchiseLike.length === 0)
      return

    listFranchiseLike.forEach((item, itemIndex) => {
      Vue.set(state.listFranchiseLike, itemIndex, item)
    })
  },
  [types.UPDATE_ACCOUNT_PAGINATION_PROGRESS](state, paginationProgress) {
    state.paginationProgress = paginationProgress
  },
  [types.UPDATE_ACCOUNT_LIST_FRANCHISE_PROGRESS](state, listFranchiseProgress) {
    state.listFranchiseProgress = listFranchiseProgress
  },
  [types.UPDATE_STATUS_BUSY_FETCH_LIST](state, status) {
    state.busyFetchListFranchise = status
  },
  [types.UPDATE_STATUS_BUSY_COMPARISON_FRANCHISE](state, status) {
    state.busyUpdateStatusComparisonFranchise = status
  },
  [types.UPDATE_STATUS_BUSY_PROGRESS_FRANCHISE](state, status) {
    state.busyUpdateStatusProgressFranchise = status
  },
  [types.UPDATE_STATUS_BUSY_LIKE_FRANCHISE](state, status) {
    state.busyUpdateStatusLikeFranchise = status
  },
  [types.UPDATE_FRANCHISE_VIEW_ID](state, franchiseeViewId) {
    state.franchiseeViewId = franchiseeViewId
  },
  [types.UPDATE_SORT](state, {field, direction}) {
    state.sort.field = field
    state.sort.direction = direction
  },
  [types.UPDATE_ACCOUNT_USER_CLICK](state, click) {
    state.userClick = click
  },
  [types.UPDATE_ACCOUNT_USER_OBJECT](state, user) {
    state.userObj = user
  },
  [types.UPDATE_ACCOUNT_PENDING_LIST_ARR](state, listArr) {
    state.pendingListArr = listArr
  },
}

// actions
export const actions = {
  updatePreferencesIndustries({commit}, preferencesIndustries) {
    commit(types.UPDATE_PREFERENCES_INDUSTRIES, preferencesIndustries)
  },
  updatePreferencesStates({commit}, preferencesStates) {
    commit(types.UPDATE_PREFERENCES_STATES, preferencesStates)
  },
  updatePreferencesInvestment({commit}, preferencesInvestment) {
    commit(types.UPDATE_PREFERENCES_INVESTMENT, preferencesInvestment)
  },
  setShowPreloader({commit}, payload) {
    commit(types.SET_SHOW_PRELOADER, payload)
  },
  updateFocusFilterToggleStatus({commit}, status) {
    commit(types.SET_FOCUS_FILTER, status)
  },
  updateSort({commit}, {field, direction}) {
    commit(types.UPDATE_SORT, {field, direction})
  },
  async updateCurrentPage({state, commit, dispatch}, page) {
    const pagination = cloneDeep(state.pagination)
    pagination.current_page = page
    await commit(types.UPDATE_ACCOUNT_PAGINATION, pagination)
  },
  async updateCompareCurrentPage({state, commit, dispatch}, page) {
    const pagination = cloneDeep(state.paginationCompare)
    pagination.current_page = page
    await commit(types.UPDATE_ACCOUNT_PAGINATION_COMPARE, pagination)
  },
  async updateLikeCurrentPage({state, commit, dispatch}, page) {
    const pagination = cloneDeep(state.paginationLike)
    pagination.current_page = page
    await commit(types.UPDATE_ACCOUNT_PAGINATION_LIKE, pagination)
  },
  async fetchPreferences({commit, state, rootState}) {
    const userId = state.franchiseeViewId ?? rootState.auth.user.id
    try {
      const {data} = await this.$axios.get(`api/v2/users/${userId}/preferences`)

      if (data.status !== 'success')
        return

      commit(types.UPDATE_PREFERENCES_INDUSTRIES, data.result.industry)
      commit(types.UPDATE_PREFERENCES_STATES, data.result.state_id)
      commit(types.UPDATE_PREFERENCES_INVESTMENT, data.result.total_amount_invest)
    } catch (e) {
      console.log(e);
    }
  },
  async savePreferences({state}) {
    try {
      await this.$axios.put('/api/v1/user/preferences',
        {
          industries: state.preferencesIndustries,
          state_ids: state.preferencesStates,
          total_amount_invest: state.preferencesInvestment >= 0 ? state.preferencesInvestment : null
        }
      );
    } catch (e) {

    }
  },
  async fetchFranchises({state, commit}, setCounter = false) {
    if (!setCounter)
      commit(types.UPDATE_STATUS_BUSY_FETCH_LIST, true)

    try {
      const params = {
        page: state.pagination.current_page,
        per_page: state.pagination.per_page,
      }
      if (state.preferencesIndustries.length > 0)
        params.industry = state.preferencesIndustries

      if (state.preferencesStates.length > 0)
        params.states_id = state.preferencesStates

      if (USER_PREFERENCES_TOTAL_AMOUNT_INVEST.filter(item => item.value === state.preferencesInvestment).length > 0) {
        const investObj = USER_PREFERENCES_TOTAL_AMOUNT_INVEST.find(item => item.value === state.preferencesInvestment)

        if (investObj.min !== null || investObj.max !== null)
          params.investment = [investObj.min, investObj.max]
      }

      const {data} = await this.$axios.get(`api/v2/businesses?sort[top_order]=desc&sort[membership]=desc&sort[${state.sort.field}]=${state.sort.direction}`, {params})

      commit(types.SET_FRANCHISE_TOTAL_COUNTER, data.result.total ?? 0)

      if (setCounter)
        return

      const pagination = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.data)

      delete (pagination.data)

      commit(types.UPDATE_ACCOUNT_PAGINATION, pagination)
      commit(types.UPDATE_ACCOUNT_LIST_FRANCHISE, franchiseList)

    } catch (e) {
      console.log(e);
    }

    commit(types.UPDATE_STATUS_BUSY_FETCH_LIST, false)
  },
  async fetchComparisonsList({state, commit, rootState}) {
    const userId = state.franchiseeViewId ?? rootState.auth.user.id

    try {
      const params = {
        page: state.paginationCompare.current_page,
        per_page: state.paginationCompare.per_page,
      }

      const {data} = await this.$axios.get(`api/v2/businesses/action/compare/${userId}`, {params})

      const pagination = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.data)

      delete (pagination.data)

      commit(types.UPDATE_ACCOUNT_PAGINATION_COMPARE, pagination)
      commit(types.UPDATE_ACCOUNT_LIST_FRANCHISE_COMPARE, franchiseList)
    } catch (e) {
      console.log(e);
    }

  },
  async fetchLikeList({state, commit, rootState}) {
    const userId = state.franchiseeViewId ?? rootState.auth.user.id
    try {
      const params = {
        page: state.paginationLike.current_page,
        per_page: state.paginationLike.per_page,
      }
      const {data} = await this.$axios.get(`api/v2/businesses/action/like/${userId}`, {params})

      const pagination = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.data)

      delete (pagination.data)

      commit(types.UPDATE_ACCOUNT_PAGINATION_LIKE, pagination)
      commit(types.UPDATE_ACCOUNT_LIST_FRANCHISE_LIKE, franchiseList)
    } catch (e) {
      console.log(e);
    }
  },
  async fetchProgressList({state, commit, rootState}) {
    const userId = state.franchiseeViewId ?? rootState.auth.user.id
    try {
      const {data} = await this.$axios.get(`api/v2/business-client-steps/${userId}/franchisee-all-steps`)
      if (data.status !== 'success')
        return
      const pagination = cloneDeep(data.result)
      const franchiseList = cloneDeep(data.result.data)

      delete (pagination.data)

      commit(types.UPDATE_ACCOUNT_PAGINATION_PROGRESS, pagination)
      commit(types.UPDATE_ACCOUNT_LIST_FRANCHISE_PROGRESS, franchiseList)
    } catch (e) {
      console.log(e);
    }
  },
  async updateComparisonStatusFranchise({commit, dispatch}, franchiseId) {
    commit(types.UPDATE_STATUS_BUSY_COMPARISON_FRANCHISE, franchiseId)
    try {
      const {data} = await this.$axios.get(`/api/v1/action/${franchiseId}/compare`)

      if (data.status === 'success') {
        await dispatch('fetchComparisonsList')
        await dispatch('fetchFranchises')
      }
    } catch (e) {
      console.log(e);
    }
    commit(types.UPDATE_STATUS_BUSY_COMPARISON_FRANCHISE, null)
  },
  async updateProgressStatusFranchise({commit, dispatch}, franchise) {
    commit(types.UPDATE_STATUS_BUSY_PROGRESS_FRANCHISE, franchise.franchise_id)
    try {
      await this.$axios.get(`/api/v1/business-client-steps/${franchise.slug}/franchisee-steps`)
      await dispatch('fetchProgressList')
      await dispatch('fetchFranchises')

    } catch (e) {
      console.log(e);
    }
    commit(types.UPDATE_STATUS_BUSY_PROGRESS_FRANCHISE, null)
  },
  async updateLikeStatusFranchise({commit, dispatch}, franchiseId) {
    commit(types.UPDATE_STATUS_BUSY_LIKE_FRANCHISE, franchiseId)
    try {
      const {data} = await this.$axios.get(`/api/v1/action/${franchiseId}/like/`)

      if (data.status === 'success') {
        await dispatch('fetchLikeList')
        await dispatch('fetchFranchises')
      }
    } catch (e) {
      console.log(e);
    }
    commit(types.UPDATE_STATUS_BUSY_LIKE_FRANCHISE, null)
  },
  setFranchiseViewId({commit}, franchiseeId) {
    commit(types.UPDATE_FRANCHISE_VIEW_ID, franchiseeId)
  },
  setUserClick({commit}, click) {
    commit(types.UPDATE_ACCOUNT_USER_CLICK, click)
  },
  async fetchUser({state, commit, rootState}) {
    const userId = state.franchiseeViewId ?? rootState.auth.user.id
    try {
      const {data} = await this.$axios.get(`api/v2/users/${userId}`)

      commit(types.UPDATE_ACCOUNT_USER_OBJECT, data.result)
    } catch (e) {
      commit(types.UPDATE_ACCOUNT_USER_OBJECT, null)
      console.log(e);
    }
  },
  async updateUser({commit}, user) {
    commit(types.UPDATE_ACCOUNT_USER_OBJECT, user)
  },
  updatePendingList({commit}, listArr){
    commit(types.UPDATE_ACCOUNT_PENDING_LIST_ARR, listArr)
  },
}
