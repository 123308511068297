import * as types from './mutation-types'

// state
export const state = () => ({
  show: false,
  sessionExpired: false
})

// getters
export const getters = {
  show: state => state.show,
  sessionExpired: state => state.sessionExpired
}

// mutations
export const mutations = {
  [types.SET_SHOW_LOGIN_MODAL] (state, show) {
    state.show = show
  },
  [types.SET_SESSION_EXPIRED] (state, status) {
    state.sessionExpired = status
  }
}

// actions
export const actions = {
  openModal ({ commit }) {
    commit(types.SET_SHOW_LOGIN_MODAL, true)
  },
  openModalSessionExpired({commit}){
    commit(types.SET_SESSION_EXPIRED, true)
    commit(types.SET_SHOW_LOGIN_MODAL, true)
  },
  closeModal ({ commit }) {
    commit(types.SET_SHOW_LOGIN_MODAL, false)
  }
}
