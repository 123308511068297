/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {cloneDeep} from 'lodash/fp'
import {
  SET_CHAT_CONTACT_LIST,
  SET_CHAT_CONTACT_LIST_LOADING,
  SET_CHOSEN_CHAT_CONTACT,
  SET_CHOSEN_CHAT_CONTACT_PAYLOAD,
  SET_CHAT_CONTACT_LIST_PAGINATION,
  SET_CHAT_CONTACT_MESSAGES,
  APPEND_CHAT_CONTACT_MESSAGES,
  APPEND_CHAT_CONTACT_MESSAGE,
  SET_LOADING_CONTACT_MESSAGES,
  SET_CHAT_CONTACT_MESSAGES_PAGINATION,
} from '@/store/mutation-types';

// state
export const state = () => ({
  // business client steps table
  contactList: [],
  contactListLoading: false,
  chosenContactIndex: -1,
  chosenContact: null,
  contactListPagination: {
    current_page: 1,
  },
  // business client messages table
  contactMessages: [],
  contactMessagesLoading: false,
  contactMessagesPagination: {
    current_page: 1,
  },
});

// getters
export const getters = {
  contactList: (state) => state.contactList,
  chosenContactIndex: (state) => state.chosenContactIndex,
  chosenContact: (state) => state.chosenContact,
  contactListLoading: (state) => state.contactListLoading,
  contactListPagination: (state) => state.contactListPagination,
  contactMessages: (state) => state.contactMessages,
  contactMessagesLoading: (state) => state.contactMessagesLoading,
  contactMessagesPagination: (state) => state.contactMessagesPagination,
};

// mutations
export const mutations = {
  /**
   * @param state
   * @param {Array} data response laravel
   */
  [SET_CHAT_CONTACT_LIST](state, data) {
    state.contactList = data;
  },
  /**
   * @param state
   * @param {boolean} loading
   */
  [SET_CHAT_CONTACT_LIST_LOADING](state, loading) {
    state.contactListLoading = loading;
  },
  /**
   * @param state
   * @param {Number} contactIndex
   */
  [SET_CHOSEN_CHAT_CONTACT](state, contactIndex) {
    state.chosenContactIndex = contactIndex;
    state.chosenContact = state.contactList[contactIndex];
  },
  /**
   * @param state
   * @param {Object} payload
   */
  [SET_CHOSEN_CHAT_CONTACT_PAYLOAD](state, payload) {
    state.chosenContact = payload;
  },
  /**
   * @param state
   * @param {Object} pagination pagination object
   */
  [SET_CHAT_CONTACT_LIST_PAGINATION](state, pagination) {
    Object.assign(state.contactListPagination, pagination);
  },
  /**
   * @param state
   * @param {Array} messages
   */
  [SET_CHAT_CONTACT_MESSAGES](state, messages) {
    state.contactMessages = messages;
  },
  /**
   * @param state
   * @param {Array} messages
   */
  [APPEND_CHAT_CONTACT_MESSAGES](state, messages) {
    messages.reverse().forEach((el) => state.contactMessages.unshift(el));
  },
  /**
   * @param state
   * @param {Object} message
   */
  [APPEND_CHAT_CONTACT_MESSAGE](state, message) {
    state.contactMessages.push(message);
  },
  /**
   * @param state
   * @param {Boolean} loading
   */
  [SET_LOADING_CONTACT_MESSAGES](state, loading) {
    state.contactMessagesLoading = loading;
  },
  /**
   * @param state
   * @param {Object} pagination
   */
  [SET_CHAT_CONTACT_MESSAGES_PAGINATION](state, pagination) {
    Object.assign(state.contactMessagesPagination, pagination);
  },
};

// actions
export const actions = {
  /**
   * @param commit
   * @param state
   * @param {Number} businessStepId
   * @param {String} fullName
   */
  async fetchContactList({ commit, state }, {businessStepId, fullName}) {
    const currentPage = state.contactListPagination.current_page;

    commit(SET_CHAT_CONTACT_LIST_LOADING, true);
    try {

      const { data } = await this.$axios.get(
        `api/v2/business-client-steps/${businessStepId}/franchisor-steps?page=${currentPage}&full_name=${fullName}`
      );

      const pagination = cloneDeep(data.result)
      const franchisorSteps = cloneDeep(data.result.data)

      delete (pagination.data)

      commit(SET_CHAT_CONTACT_LIST, franchisorSteps)
      commit(SET_CHAT_CONTACT_LIST_PAGINATION, pagination)
    } catch (e) {
      console.log(e);
    }
    commit(SET_CHAT_CONTACT_LIST_LOADING, false);
  },
  /**
   * @param commit
   * @param state
   * @param {String} businessClientSlug
   */
  async fetchContactMessages({ commit, state }, businessClientSlug) {
    const currentPage = state.contactMessagesPagination.current_page;
    commit(SET_LOADING_CONTACT_MESSAGES, true);
    try {
      const url = `/api/v2/business-client-steps/${businessClientSlug}/messages?page=${currentPage}`;
      const { data } = await this.$axios.get(url);
      commit(APPEND_CHAT_CONTACT_MESSAGES, data.result.data);
      commit(SET_CHAT_CONTACT_MESSAGES_PAGINATION, data.result);
    } catch (e) {
      console.log(e);
    }
    commit(SET_LOADING_CONTACT_MESSAGES, false);
  },
  /**
   * @param commit
   * @param state
   */
  async fetchContact({ commit, state }, businessClientSlug) {
    try {
      const { data } = await this.$axios.get(
        `/api/v2/business-client-steps/${businessClientSlug}`
      );
      commit(SET_CHOSEN_CHAT_CONTACT_PAYLOAD, data.result);
    } catch (e) {
      console.log(e);
    }
  },
};
