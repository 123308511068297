import * as types from './mutation-types'

// helpers
export const mapFranchise = (item) => {
  return {
    id: item.franchise_id,
    version_id: item.version_id,
    slug: item.slug,
    slug_industry: item.slug_industry,
    total_like: item.total_like,
    total_in_comparison: item.total_in_comparison,
    like: item.like,
    in_comparison: item.in_comparison,
    flow_status: item.flow_status,
    states: item.states,
    founders: item.founders,
    tags: item.tags,
    self_ratings: item.self_ratings,
    survey_ratings: item.survey_ratings,
    industry: item.industry,
    other_industry: item.other_industry,
    number_of_locations: item.number_of_locations,
    investment: item.investment,
    franchise_fee_title: item.franchise_fee_title,
    franchise_fee: item.franchise_fee,
    main_royalty_fee: item.main_royalty_fee,
    main_royalty_fee_percent: item.main_royalty_fee_percent,
    main_royalty_fee_title: item.main_royalty_fee_title,
    average_revenue: item.average_revenue,
    average_revenue_title: item.average_revenue_title,
    gross_profit: item.gross_profit,
    gross_profit_title: item.gross_profit_title,
    business_name: item.business_name,
    public_website_business_permalink: item.public_website_business_permalink,
    public_website_business_permalink_title: item.public_website_business_permalink_title,
    franchising_since: item.franchising_since,
    accepting_new_franchisees: item.accepting_new_franchisees,
    short_descr: item.short_descr,
    video: item.video,
    video_cover: item.video_cover,
    video_embed: item.video_embed,
    naics: item.NAICS,
    seo_meta_title: item.seo_meta_title,
    seo_meta_description: item.seo_meta_description,
    seo_meta_keywords: item.seo_meta_keywords,
    established: item.established,
    franchisee_satisfaction: item.franchisee_satisfaction,
    available_locations_coverage: item.available_locations_coverage,
    membership: item.membership,
    minimum_credit_score: item.minimum_credit_score,
    minimum_liquid_assets: item.minimum_liquid_assets,
    total_net_worth: item.total_net_worth,
    landing_phone: item.landing_phone,
    landing_navbar_font: item.landing_navbar_font,
    landing_navbar_font_style: item.landing_navbar_font_style,
    landing_navbar_font_weight: item.landing_navbar_font_weight,
    schedule_call_link: item.schedule_call_link,
    company_stage: item.company_stage,
    certified_by_fa: item.certified_by_fa,
    company_page_published: item.company_page_published,
    company_twitter_username:
      item.company_twitter_username
        ? item.company_twitter_username
        : '@Franchise1_2_3',
    company_description: item.company_description,
    location: item.location,
    location_place_id: item.location_place_id,
    company_short_description: item.company_short_description,
    style_color_light: item.style_color_light,
    style_color_dark: item.style_color_dark,
    style_color_vibrant: item.style_color_vibrant,
    style_font_title: item.style_font_title,
    style_font_paragraph: item.style_font_paragraph,
    logo: item.logo,
    logo_landing: item.logo_landing,
    files: item.files
  }
}

// state
export const state = () => ({
  franchise: null,
  franchises: [],
  newFranchises: [],
  topFranchises: [],
})

// getters
export const getters = {
  franchise: state => state.franchise,
  franchises: state => state.franchises,
  newFranchises: state => state.newFranchises,
  topFranchises: state => state.topFranchises,
}

export const mutations = {
  [types.FETCH_BUSINESS_V2_SUCCESS](state, franchise) {
    state.franchise = franchise
  },
  [types.FETCH_BUSINESS_V2_FAILURE](state) {
    state.franchise = null
  },
  [types.FETCH_BUSINESSES_V2_SUCCESS](state, franchises) {
    state.franchises = franchises
  },
  [types.FETCH_BUSINESSES_V2_FAILURE](state) {
    state.franchises = []
  },
  [types.FETCH_NEW_BUSINESSES_V2](state, franchises) {
    state.newFranchises = franchises
  },
  [types.FETCH_TOP_BUSINESSES_V2](state, franchises) {
    state.topFranchises = franchises
  },
}

export const actions = {
  async fetchFranchise({commit, state}, payload) {
    try {
      if (!payload || !payload.franchiseId) {
        commit(types.FETCH_BUSINESS_V2_FAILURE)
        return
      }

      const franchiseId = payload.franchiseId
      const versionId = payload.versionId ?? 'latest'

      const {data} = await this.$axios.get(`/api/v2/businesses/${franchiseId}/${versionId}`)

      commit(types.FETCH_BUSINESS_V2_SUCCESS, mapFranchise(data.result))
    } catch (e) {
      commit(types.FETCH_BUSINESS_V2_FAILURE)
    }
  },
  async fetchPaginatedFranchises({commit}) {
    try {
      const params = {}
      const {data} = await this.$axios.get(`/api/v2/businesses`, {params})

      commit(types.FETCH_BUSINESSES_V2_SUCCESS, data.result.data)
    } catch (e) {
      commit(types.FETCH_BUSINESSES_V2_FAILURE)
    }
  },
  async fetchNewFranchises({commit}) {
    try {
      const {data} = await this.$axios.get(`api/v2/businesses/banner`)
      commit(types.FETCH_NEW_BUSINESSES_V2, data.result.data)
    } catch (e) {
      console.log(e);
    }
  },
  async fetchTopFranchises({commit}, topName) {
    try {
      const params = {
        top_name: topName,
      }
      const {data} = await this.$axios.get(`api/v2/businesses/top`, {params})
      commit(types.FETCH_TOP_BUSINESSES_V2, data.result.data)
    } catch (e) {
      console.log(e);
    }
  },
}

