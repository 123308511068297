export default ({store, redirect}) => {
  const user = store.getters['auth/user']

  if (user && user.isFranchisee) {
    store.dispatch('auth/setPagePrivateStatus', true)
    return
  }

  redirect('/')
}
