/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import * as types from './mutation-types'
import {
  STATUS_DONE,
} from '@/store/constants'

// state
export const state = () => ({
  businessStep: null,
});

// getters
export const getters = {
  businessStep: (state) => state.businessStep,
};

// mutations
export const mutations = {
  /**
   *
   * @param state
   * @param {Object} contact chosen contact
   */
  [types.APPROVE_CONTACT](state, contact) {
    contact.status = STATUS_DONE
  },
  /**
   *
   * @param state
   * @param {Object} businessStep business step entity
   */
  [types.SET_BUSINESS_STEP](state, businessStep) {
    state.businessStep = businessStep
  },
};

// actions
export const actions = {
  async approveFranchisee({ commit }, contact) {
    try {
      await this.$axios.get(
        `api/v2/business-client-steps/${contact.business_client_step_id}/approve`
      )
      commit(types.APPROVE_CONTACT, contact)
    } catch (e) {
      console.log(e)
    }
  },
  async fetchBusinessStep({ commit }, {slug,franchiseId} ) {
    try {
      const { data } = await this.$axios.get(`/api/v2/business-steps/${slug}/${franchiseId}`)
      commit(types.SET_BUSINESS_STEP, data.result)
    } catch (e) {
      console.log(e)
    }
  },
};
