import * as types from './mutation-types'

// state
export const state = () => ({
  pagesIndustryReportArr: [],
  busy: false,
})

// getters
export const getters = {
  pagesIndustryReportArr: (state) => state.pagesIndustryReportArr,
  busy: (state) => state.busy,
}

// mutations
export const mutations = {
  [types.UPDATE_PAGES_REPORTS_ARR](state, pagesArr) {
    state.pagesIndustryReportArr = pagesArr
  },
  [types.UPDATE_PAGES_BUSY_STATUS](state, status) {
    state.busy = status
  },
}

// actions
export const actions = {
  async fetchPagesIndustryReport({commit}) {
    commit(types.UPDATE_PAGES_BUSY_STATUS, true)
    try {
      const url = 'api/v2/page/get-pages-industry-report'
      const {data} = await this.$axios.get(url)

      commit(types.UPDATE_PAGES_REPORTS_ARR, data.result)
    } catch (e) {
      commit(types.UPDATE_PAGES_REPORTS_ARR, [])
    }
    commit(types.UPDATE_PAGES_BUSY_STATUS, false)
  }
}
