// auth.js
export const LOGOUT = "LOGOUT";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESHING_STATE = "SET_REFRESHING_STATE";
export const SET_REFRESHING_CALL = "SET_REFRESHING_CALL";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FRANCHISE = "UPDATE_USER_FRANCHISE";
export const UPDATE_REDIRECT_TO_COMPARISONS = "UPDATE_REDIRECT_TO_COMPARISONS";
export const UPDATE_VERIFIED_STATUS_USER = "UPDATE_VERIFIED_STATUS_USER";
export const UPDATE_STATUS_MOBILE = "UPDATE_STATUS_MOBILE";
export const SET_FRANCHISEE_RATING_TOKEN = "SET_FRANCHISEE_RATING_TOKEN";
export const SET_FRANCHISEE_RATING_CHANNEL = "SET_FRANCHISEE_RATING_CHANNEL";
export const SET_FRANCHISEE_RATING_QUESTIONS = "SET_FRANCHISEE_RATING_QUESTIONS";
export const UPDATE_FRANCHISEE_RATING_STATUS = "UPDATE_FRANCHISEE_RATING_STATUS";
export const UPDATE_RELATED_FRANCHISE_ID = "UPDATE_RELATED_FRANCHISE_ID";
export const UPDATE_RELATED_FRANCHISE_NAME = "UPDATE_RELATED_FRANCHISE_NAME";
export const UPDATE_STATUS_PRIVATE_PAGE = "UPDATE_STATUS_PRIVATE_PAGE";

// lang.js
export const SET_LOCALE = "SET_LOCALE";

// business
export const FETCH_LEADS_SUCCESS = "FETCH_LEADS_SUCCESS"
export const FETCH_LEADS_FAILURE = "FETCH_LEADS_FAILURE"
export const FETCH_BUSINESS_SUCCESS = "FETCH_BUSINESS_SUCCESS";
export const FETCH_BUSINESS_FAILURE = "FETCH_BUSINESS_FAILURE";
export const SET_FILTER = "SET_FILTER";
export const LIKE_ITEM = "LIKE_ITEM"; // like
export const DISLIKE_ITEM = "DISLIKE_ITEM"; // dislike
export const COMPARISON = "COMPARISON"; // comparison card
export const SET_MAIN_FILTER_PAGINATION = "SET_MAIN_FILTER_PAGINATION"; // set pagination
export const SET_ITEMS_STATE = "SET_ITEMS_STATE"; // set items state in franchising directory
export const SET_SORTING = "SET_SORTING";
export const FETCH_LIKES_SUCCESS = "FETCH_LIKES_SUCCESS"; // fetch all liked franchises
export const FETCH_LIKES_FAILURE = "FETCH_LIKES_FAILURE"; // if failed request
export const FETCH_COMPARISONS_SUCCESS = "FETCH_COMPARISONS_SUCCESS"; // fetch all liked franchises
export const FETCH_COMPARISONS_FAILURE = "FETCH_COMPARISONS_FAILURE"; // if failed request
export const TOGGLE_COMPARISONS_SUCCESS = "TOGGLE_COMPARISONS_SUCCESS"; // fetch all liked franchises
export const TOGGLE_COMPARISONS_FAILURE = "TOGGLE_COMPARISONS_FAILURE"; // if failed request
export const ADD_COMPARISON_SUCCESS = "ADD_COMPARISON_SUCCESS"; // ADD_COMPARISON_SUCCESS event
export const REMOVE_COMPARISON_SUCCESS = "REMOVE_COMPARISON_SUCCESS"; // REMOVE_COMPARISON_SUCCESS event
export const SET_IGNORE_PUBLICATION_STATUS = "SET_IGNORE_PUBLICATION_STATUS"; // SET_IGNORE_PUBLICATION_STATUS ignore company publication status
export const SET_FETCH_DATA = "SET_FETCH_DATA"; // SET_FETCH_DATA change busy toggle
export const CLEAR_DATA_ARR = "CLEAR_DATA_ARR"; // CLEAR_DATA_ARR clear dataArr in store
export const SET_FILTER_ALPHABET_DATA = "SET_FILTER_ALPHABET_DATA"; // SET_FILTER_ALPHABET_DATA set alphabet data array
export const CHANGE_FILTER_ALPHABET_ITEM = "CHANGE_FILTER_ALPHABET_ITEM"; // CHANGE_FILTER_ALPHABET_ITEM change active item in alphabet filter
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"; // SET_SEARCH_VALUE set search value
export const SET_BUSY_STATUS = "SET_BUSY_STATUS"; // SET_BUSY_STATUS set busy status
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS"; // FETCH_COMPANY_SUCCESS fetch company
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE"; // FETCH_COMPANY_FAILURE if failed requestexport const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS"; // FETCH_COMPANY_SUCCESS fetch company
export const FETCH_PROGRESS_SUCCESS = "FETCH_PROGRESS_SUCCESS"; // FETCH_PROGRESS_SUCCESS fetch progress
export const FETCH_PROGRESS_FAILURE = "FETCH_PROGRESS_FAILURE"; // FETCH_PROGRESS_FAILURE if failed request
export const FETCH_BUTTON_CLICK_SUCCESS = "FETCH_BUTTON_CLICK_SUCCESS"; // FETCH_BUTTON_CLICK_SUCCESS if clicked
export const FETCH_FETCH_BUTTON_CLICK_FAILURE = "FETCH_FETCH_BUTTON_CLICK_FAILURE"; // FETCH_FETCH_BUTTON_CLICK_FAILURE if not clicked
export const SET_CLOSE_FRANCHISE_NAV_MODAL = "SET_CLOSE_FRANCHISE_NAV_MODAL" // close franchise-nav-search-bar
export const SET_BUSINESS_COMPETITORS = "SET_BUSINESS_COMPETITORS";
export const SET_FILTER_COMPETITORS = "SET_FILTER_COMPETITORS";
export const FETCH_DIRECTORY_FRANCHISE_BUSY = "FETCH_DIRECTORY_FRANCHISE_BUSY";
export const SET_DEFAULT_STATE_FILTER = "SET_DEFAULT_STATE_FILTER";

// business v2
export const FETCH_BUSINESS_V2_SUCCESS = "FETCH_BUSINESS_V2_SUCCESS"
export const FETCH_BUSINESS_V2_FAILURE = "FETCH_BUSINESS_V2_FAILURE"
export const FETCH_BUSINESSES_V2_SUCCESS = "FETCH_BUSINESSES_V2_SUCCESS"
export const FETCH_BUSINESSES_V2_FAILURE = "FETCH_BUSINESSES_V2_FAILURE"
export const FETCH_NEW_BUSINESSES_V2 = "FETCH_NEW_BUSINESSES_V2"
export const FETCH_TOP_BUSINESSES_V2 = "FETCH_TOP_BUSINESSES_V2"

// business steps v2
export const FETCH_BUSINESS_STEPS = `FETCH_BUSINESS_STEPS`
export const FETCH_BUSINESS_STEPS_FAILURE = `FETCH_BUSINESS_STEPS_FAILURE`

// mobile my matches
export const FETCH_MY_MATCHES_SUCCESS = "FETCH_MY_MATCHES_SUCCESS"
export const FETCH_MY_MATCHES_FAILURE = "FETCH_MY_MATCHES_FAILURE"
export const UPDATE_PAGINATION = "UPDATE_PAGINATION"
export const CHANGE_PAGE = "CHANGE_PAGE"
export const FETCH_SELECTED_SUCCESS = "FETCH_SELECTED_SUCCESS"
export const FETCH_SELECTED_FAILURE = "FETCH_SELECTED_FAILURE"
export const UPDATE_SELECTED_PAGINATION = "UPDATE_SELECTED_PAGINATION"
export const CHANGE_SELECTED_PAGE = "CHANGE_SELECTED_PAGE"
export const CHANGE_MOBILE_PREFERENCES_STEP = 'CHANGE_MOBILE_PREFERENCES_STEP';
export const UPDATE_MOBILE_PREFERENCES_INDUSTRY = 'UPDATE_PREFERENCES_INDUSTRY';
export const UPDATE_MOBILE_PREFERENCES_STATES = 'UPDATE_MOBILE_PREFERENCES_STATES';
export const UPDATE_MOBILE_PREFERENCES_INVEST = 'UPDATE_MOBILE_PREFERENCES_INVEST';
export const UPDATE_MOBILE_PREFERENCES_SAVED = 'UPDATE_MOBILE_PREFERENCES_SAVED';
export const UPDATE_STATUS_FETCH = 'UPDATE_STATUS_FETCH';
export const CHANGE_HELPER_VISIBILITY = 'CHANGE_HELPER_VISIBILITY';
export const ADDED_DELAYED_ACTIONS_SELECT = 'ADDED_DELAYED_ACTIONS_SELECT';
export const ADDED_DELAYED_ACTIONS_SKIP = 'ADDED_DELAYED_ACTIONS_SKIP';
export const CLEAR_DELAYED_ACTIONS = 'CLEAR_DELAYED_ACTIONS';

// account franchisor
export const FETCH_FRANCHISE_INFO_SUCCESS = "FETCH_FRANCHISE_INFO_SUCCESS"
export const FETCH_FRANCHISE_INFO_FAILURE = "FETCH_FRANCHISE_INFO_FAILURE"
export const APPROVE_VERSION_SUCCESS = "APPROVE_VERSION_SUCCESS"
export const UPDATE_FRANCHISE_VIEWED = "UPDATE_FRANCHISE_VIEWED"

// account franchisor contacts
export const FETCH_CONTACTS_LIST_SUCCESS = "FETCH_CONTACTS_LIST_SUCCESS"
export const FETCH_CONTACTS_LIST_FAILURE = "FETCH_CONTACTS_LIST_FAILURE"
export const FETCH_CONTACTS_SELECTED_SUCCESS = "FETCH_CONTACTS_SELECTED_SUCCESS"
export const FETCH_CONTACTS_SELECTED_FAILURE = "FETCH_CONTACTS_SELECTED_FAILURE"
export const SET_CONTACTS_BUSY = "SET_CONTACTS_BUSY"
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO"
export const UPDATE_CONTACT_LIST_ALL = "UPDATE_CONTACT_LIST_ALL"

// account franchisor forms
export const FETCH_FORMS_LIST_SUCCESS = "FETCH_FORMS_LIST_SUCCESS"
export const FETCH_FORMS_LIST_FAILURE = "FETCH_FORMS_LIST_FAILURE"
export const SET_FORMS_BUSY = "SET_FORMS_BUSY"

// account franchisor contacts notes
export const FETCH_NOTE_LIST_SUCCESS = "FETCH_NOTE_LIST_SUCCESS"
export const FETCH_NOTE_LIST_FAILURE = "FETCH_NOTE_LIST_FAILURE"
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS"
export const FETCH_NOTE_FAILURE = "FETCH_NOTE_FAILURE"
export const SET_NOTE_BUSY = "SET_NOTE_BUSY"

// account franchisor contacts tasks
export const UPDATE_CONTACT_TASK_LIST_ARR = "UPDATE_CONTACT_TASK_LIST_ARR"
export const UPDATE_CONTACT_TASK_PAGINTAION = "UPDATE_CONTACT_TASK_PAGINTAION"
export const UPDATE_CONTACT_TASK_FILTER = "UPDATE_CONTACT_TASK_FILTER"
export const UPDATE_CONTACT_TASK_STATUS_BUSY = "UPDATE_CONTACT_TASK_STATUS_BUSY"

// account franchisor contacts all-tasks
export const UPDATE_CONTACT_ALL_TASK_LIST_ARR = "UPDATE_CONTACT_ALL_TASK_LIST_ARR"
export const UPDATE_CONTACT_ALL_TASK_PAGINTAION = "UPDATE_CONTACT_ALL_TASK_PAGINTAION"
export const UPDATE_CONTACT_ALL_TASK_FILTER = "UPDATE_CONTACT_ALL_TASK_FILTER"
export const UPDATE_CONTACT_ALL_TASK_STATUS_FILTER = "UPDATE_CONTACT_ALL_TASK_STATUS_FILTER"
export const UPDATE_CONTACT_ALL_TASK_STATUS_BUSY = "UPDATE_CONTACT_ALL_TASK_STATUS_BUSY"

// account franchisor contacts history
export const FETCH_HISTORY_LIST_SUCCESS = "FETCH_HISTORY_LIST_SUCCESS"
export const FETCH_HISTORY_LIST_FAILURE = "FETCH_HISTORY_LIST_FAILURE"
export const SET_HISTORY_BUSY = "SET_HISTORY_BUSY"

// modal message
export const SET_MODAL_MESSAGE_DATA = "SET_MODAL_MESSAGE_DATA"
export const CLEAR_MODAL_MESSAGE_PAYLOAD = "CLEAR_MODAL_MESSAGE_PAYLOAD"

// filters
export const SET_FILTER_ITEM = "SET_FILTER_ITEM";

// registration
export const SET_REGISTRATION_STAGE = "SET_STAGE";
export const SET_REGISTRATION_FRANCHISEE_STAGE = "SET_REGISTRATION_FRANCHISEE_STAGE";
export const SET_SHOW_REGISTRATION_MODAL = "SET_SHOW_REGISTRATION_MODAL";
export const SET_REGISTRATION_USER_TYPE = "SET_REGISTRATION_USER_TYPE";
export const SET_FRANCHISE_ID = "SET_FRANCHISE_ID";
export const SET_OPTION = "SET_OPTION";
export const SET_COMPANY_PAGE_URL = "SET_COMPANY_PAGE_URL";
export const SET_ALLOW_RECLAIM = "SET_ALLOW_RECLAIM";
export const TOGGLE_FAST_SIGNUP_FRANCHISOR = "TOGGLE_FAST_SIGNUP_FRANCHISOR";
export const TOGGLE_ENABLE_FLOW_BEGIN_TODAY = "TOGGLE_ENABLE_FLOW_BEGIN_TODAY";
export const TOGGLE_ENABLE_FLOW_PENDING_LIST = "TOGGLE_ENABLE_FLOW_PENDING_LIST";
export const UPDATE_PENDING_LIST_EMAIL = "UPDATE_PENDING_LIST_EMAIL";
export const UPDATE_PENDING_LIST_TOGGLE_SAVE = "UPDATE_PENDING_LIST_TOGGLE_SAVE";


// login
export const SET_SHOW_LOGIN_MODAL = "SET_SHOW_LOGIN_MODAL";
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED";

// footer
export const SET_SHOW_FOOTER = "SET_SHOW_FOOTER";

// account
export const UPDATE_PREFERENCES_INDUSTRIES = "UPDATE_PREFERENCES_INDUSTRIES";
export const UPDATE_PREFERENCES_STATES = "UPDATE_PREFERENCES_STATES";
export const UPDATE_PREFERENCES_INVESTMENT = "UPDATE_PREFERENCES_INVESTMENT";
export const SET_SHOW_PRELOADER = "SET_SHOW_PRELOADER";
export const SET_SELECTED_FRANCHISE = "SET_SELECTED_FRANCHISE";
export const SET_SHOW_FDD = "SET_SHOW_FDD";
export const SET_FOCUS_FILTER = "SET_FOCUS_FILTER";
export const SET_FRANCHISE_TOTAL_COUNTER = "SET_FRANCHISE_TOTAL_COUNTER";
export const UPDATE_ACCOUNT_PAGINATION = "UPDATE_ACCOUNT_PAGINATION";
export const UPDATE_ACCOUNT_LIST_FRANCHISE = "UPDATE_ACCOUNT_LIST_FRANCHISE";
export const UPDATE_ACCOUNT_PAGINATION_COMPARE = "UPDATE_ACCOUNT_PAGINATION_COMPARE";
export const UPDATE_ACCOUNT_LIST_FRANCHISE_COMPARE = "UPDATE_ACCOUNT_LIST_FRANCHISE_COMPARE";
export const UPDATE_ACCOUNT_PAGINATION_LIKE = "UPDATE_ACCOUNT_PAGINATION_LIKE";
export const UPDATE_ACCOUNT_LIST_FRANCHISE_LIKE = "UPDATE_ACCOUNT_LIST_FRANCHISE_LIKE";
export const UPDATE_ACCOUNT_PAGINATION_PROGRESS = "UPDATE_ACCOUNT_PAGINATION_PROGRESS";
export const UPDATE_ACCOUNT_LIST_FRANCHISE_PROGRESS = "UPDATE_ACCOUNT_LIST_FRANCHISE_PROGRESS";
export const UPDATE_STATUS_BUSY_FETCH_LIST = "UPDATE_STATUS_BUSY_FETCH_LIST";
export const UPDATE_STATUS_BUSY_COMPARISON_FRANCHISE = "UPDATE_STATUS_BUSY_COMPARISON_FRANCHISE";
export const UPDATE_STATUS_BUSY_PROGRESS_FRANCHISE = "UPDATE_STATUS_BUSY_PROGRESS_FRANCHISE";
export const UPDATE_STATUS_BUSY_LIKE_FRANCHISE = "UPDATE_STATUS_BUSY_LIKE_FRANCHISE";
export const UPDATE_FRANCHISE_VIEW_ID = "UPDATE_FRANCHISE_VIEW_ID";
export const UPDATE_SORT = "UPDATE_SORT";
export const UPDATE_ACCOUNT_USER_CLICK = "UPDATE_ACCOUNT_USER_CLICK";
export const UPDATE_ACCOUNT_USER_OBJECT = "UPDATE_ACCOUNT_USER_OBJECT";
export const UPDATE_ACCOUNT_PENDING_LIST_ARR = "UPDATE_ACCOUNT_PENDING_LIST_ARR";

// contact
export const SET_VISIBILITY_CONTACT_MODAL = "SET_VISIBILITY_CONTACT_MODAL";
export const SET_IS_FOOTER_CONTACT_FORM = "SET_IS_FOOTER_CONTACT_FORM";

// iframe
export const SET_SHOW_IFRAME_MODAL = "SET_SHOW_IFRAME_MODAL";
export const SET_SRC_IFRAME_MODAL = "SET_SRC_IFRAME_MODAL";
export const SET_SRC_IFRAME_MODAL_TAG = "SET_SRC_IFRAME_MODAL_TAG";

// question
export const SET_SHOW_QUESTION_MODAL = "SET_SHOW_QUESTION_MODAL";
export const SET_FINISHED = "SET_FINISHED";
export const SET_EDITABLE = "SET_EDITABLE";
export const ADD_QUESTION = "ADD_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";

// global-directories
export const FETCH_DIRECTORY_STATES_SUCCESS = "FETCH_DIRECTORY_STATES_SUCCESS";
export const FETCH_DIRECTORY_STATES_FAILURE = "FETCH_DIRECTORY_STATES_FAILURE";
export const FETCH_DIRECTORY_INDUSTRIES_SUCCESS = "FETCH_DIRECTORY_INDUSTRIES_SUCCESS";
export const FETCH_DIRECTORY_INDUSTRIES_FAILURE = "FETCH_DIRECTORY_INDUSTRIES_FAILURE";
export const FETCH_DIRECTORY_TAGS_SUCCESS = "FETCH_DIRECTORY_TAGS_SUCCESS";
export const FETCH_DIRECTORY_TAGS_FAILURE = "FETCH_DIRECTORY_TAGS_FAILURE";
export const CHANGE_STATUS_FETCH_INDUSTRY = "CHANGE_STATUS_FETCH_INDUSTRY";
export const CHANGE_STATUS_FETCH_STATES = "CHANGE_STATUS_FETCH_STATES";
export const CHANGE_STATUS_FETCH_TAGS = "CHANGE_STATUS_FETCH_TAGS";
export const SET_RESTORE_INDUSTRY_FILTER = "SET_RESTORE_INDUSTRY_FILTER";

// answers
export const FETCH_ANSWERS_SUCCESS = "FETCH_ANSWERS_SUCCESS";
export const FETCH_ANSWERS_FAILURE = "FETCH_ANSWERS_FAILURE";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";
export const CHANGE_STATUS_BUSY = "CHANGE_STATUS_BUSY";
export const ADD_ANSWER = "ADD_ANSWER";

// franchisor steps
export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_CONTACT_LIST_PAGINATION = "SET_CONTACT_LIST_PAGINATION";
export const SET_FRANCHISOR_CONTACT_MESSAGES = "SET_FRANCHISOR_CONTACT_MESSAGES";
export const SET_FRANCHISOR_CONTACT_MESSAGES_PAGINATION =
  "SET_FRANCHISOR_CONTACT_MESSAGES_PAGINATION";
export const SET_MESSAGES_LOAD_COMPLETE = "SET_MESSAGES_LOAD_COMPLETE";
export const APPEND_FRANCHISOR_CONTACT_MESSAGE = "PUSH_FRANCHISOR_CONTACT_MESSAGE";
export const APPROVE_CONTACT = "APPROVE_CONTACT";
export const SET_BUSINESS_STEP = "SET_BUSINESS_STEP";

// franchisee steps
export const SET_BUSINESS_CLIENT_STEPS = "SET_BUSINESS_CLIENT_STEPS";
export const SET_IN_PROGRESS_BUSINESS_CLIENT_STEP = "SET_IN_PROGRESS_BUSINESS_CLIENT_STEP";
export const SET_CONTACT_MESSAGES = "SET_CONTACT_MESSAGES";
export const SET_CONTACT_MESSAGES_PAGINATION = "SET_CONTACT_MESSAGES_PAGINATION";
export const SET_FRANCHISEE_STEP_DOCUMENTS = "SET_FRANCHISEE_STEP_DOCUMENTS";
export const SET_FRANCHISEE_STEP_DOCUMENTS_LOADING = "SET_FRANCHISEE_STEP_DOCUMENTS_LOADING";
export const SET_AS_DOCUMENT_DOWNLOADED = "SET_AS_DOCUMENT_DOWNLOADED";

// new chat abstract
export const SET_CHAT_CONTACT_LIST = "SET_CHAT_CONTACT_LIST";
export const SET_CHAT_CONTACT_LIST_LOADING = "SET_CHAT_CONTACT_LIST_LOADING";
export const SET_CHOSEN_CHAT_CONTACT = "SET_CHOSEN_CHAT_CONTACT";
export const SET_CHOSEN_CHAT_CONTACT_PAYLOAD = "SET_CHOSEN_CHAT_CONTACT_PAYLOAD";
export const SET_CHAT_CONTACT_LIST_PAGINATION = "SET_CHAT_CONTACT_LIST_PAGINATION";

export const SET_CHAT_CONTACT_MESSAGES = "SET_CHAT_CONTACT_MESSAGES";
export const APPEND_CHAT_CONTACT_MESSAGES = "APPEND_CHAT_CONTACT_MESSAGES";
export const APPEND_CHAT_CONTACT_MESSAGE = "APPEND_CHAT_CONTACT_MESSAGE";
export const SET_LOADING_CONTACT_MESSAGES = "SET_LOADING_CONTACT_MESSAGES";
export const SET_CHAT_CONTACT_MESSAGES_PAGINATION = "SET_CHAT_CONTACT_MESSAGES_PAGINATION";

// notifications
export const SET_OR_APPEND_NOTIFICATIONS = "SET_OR_APPEND_NOTIFICATIONS";
export const SET_NOTIFICATIONS_UNREAD_COUNT = "SET_NOTIFICATIONS_UNREAD_COUNT";
export const SET_READ_NOTIFICATION = "SET_READ_NOTIFICATION";
export const SET_LOADING_NOTIFICATIONS = "SET_LOADING_NOTIFICATIONS";
export const SET_NOTIFICATIONS_PAGINATION = "SET_NOTIFICATIONS_PAGINATION";
export const APPEND_NOTIFICATION = "APPEND_NOTIFICATION";

// payment
export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const FETCH_PAYMENT_PERMISSIONS = "FETCH_PAYMENT_PERMISSIONS";

// search
export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";

// nav
export const SET_NAV_OVERLAP = "SET_NAV_OVERLAP";

// documents
export const SET_DOCUMENT_SUBTYPES = "SET_DOCUMENT_SUBTYPES";
export const SET_DOCUMENT_FETCH_SUBTYPES_ERROR = "SET_DOCUMENT_FETCH_SUBTYPES_ERROR";

// blog
export const BLOG_ARTICLE_LIST_FETCH_SUCCESS = "BLOG_ARTICLE_LIST_FETCH_SUCCESS";// success fetch articles list
export const BLOG_ARTICLE_LIST_FETCH_FAILURE = "BLOG_ARTICLE_LIST_FETCH_FAILURE";// failure fetch articles list
export const BLOG_ARTICLE_FETCH_SUCCESS = "BLOG_ARTICLE_FETCH_SUCCESS";// success fetch article
export const BLOG_ARTICLE_FETCH_FAILURE = "BLOG_ARTICLE_FETCH_FAILURE";// failure fetch article
export const BLOG_ARTICLE_RECENT_FETCH_SUCCESS = "ARTICLE_RECENT_FETCH_SUCCESS";// success fetch articles recent
export const BLOG_ARTICLE_RECENT_FETCH_FAILURE = "ARTICLE_RECENT_FETCH_FAILURE";// failure fetch articles recent
export const BLOG_CATEGORY_LIST_FETCH_SUCCESS = "BLOG_CATEGORY_LIST_FETCH_SUCCESS";// success fetch category list
export const BLOG_CATEGORY_LIST_FETCH_FAILURE = "BLOG_CATEGORY_LIST_FETCH_FAILURE";// failure fetch category list
export const SET_CATEGORY = "SET_CATEGORY";// set category
export const SET_SEO_ATTRIBUTES = "SET_SEO_ATTRIBUTES";// set seo attributes
export const BLOG_SET_SEARCH = "BLOG_SET_SEARCH";// set search word
export const BLOG_SET_CATEGORY = "BLOG_SET_CATEGORY";// set current category
export const BLOG_SET_PAGINATION = "BLOG_SET_PAGINATION";// set pagination
export const SET_STATUS_FETCH_DATA = "SET_STATUS_FETCH_DATA";// set status fetch data

// hubspot
export const SET_HUBSPOT_FORM_FIELD = "SET_HUBSPOT_FORM_FIELD";// set hubspot form field

// landing
export const SET_LANDING_MODAL_STATE = "SET_LANDING_MODAL_STATE"; // landing modal
export const FETCH_FRANCHISE_SUCCESS = "FETCH_FRANCHISE_SUCCESS"; // fetch franchise
export const FETCH_FRANCHISE_FAILURE = "FETCH_FRANCHISE_FAILURE"; // if failed request
export const ADD_ROUTE_PARAMS = "ADD_ROUTE_PARAMS"; // add route params
export const CHANGE_MAIN_STYLES = "CHANGE_MAIN_STYLES"; // change main styles
export const CHANGE_MENU_ARR = "CHANGE_MENU_ARR"; // change menu array
export const CHANGE_LOGO = "CHANGE_LOGO"; // change logo obj
export const CHANGE_MAX_INDEX = "CHANGE_MAX_INDEX"; // change count block
export const SET_MODAL_FILE_URL = "SET_MODAL_FILE_URL"; // report url
export const CHANGE_INDEX_SECTION = "CHANGE_INDEX_SECTION"; // change index section
export const CHANGE_VISIBILITY_SECTION = "CHANGE_VISIBILITY_SECTION"; // change visibility section
export const CLEAR_BACKGROUND_IMAGE_SECTION = "CLEAR_BACKGROUND_IMAGE_SECTION"; // clear background image section

// route
export const SET_FROM_ROUTE = "SET_FROM_ROUTE";

// mobile signup flow
export const CHANGE_MOBILE_SIGNUP_FLOW_STEP = 'CHANGE_MOBILE_SIGNUP_FLOW_STEP';
export const CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP = 'CHANGE_MOBILE_SIGNUP_FLOW_PREVIEW_STEP';
export const CHANGE_MOBILE_STATUS_FLOW = 'CHANGE_MOBILE_STATUS_FLOW';
export const CHANGE_MOBILE_STATUS_FLOW_PREVIEW = 'CHANGE_MOBILE_STATUS_FLOW_PREVIEW';


// preferences

// workbook
export const SET_CURRENT_WORKBOOK_INDEX = 'SET_CURRENT_WORKBOOK_INDEX';
export const SET_CURRENT_WORKBOOK_SECTION_INDEX = 'SET_CURRENT_WORKBOOK_SECTION_INDEX';
export const SET_CURRENT_WORKBOOK_CHAPTER_INDEX = 'SET_CURRENT_WORKBOOK_CHAPTER_INDEX';
export const SET_CURRENT_CHAPTER = 'SET_CURRENT_CHAPTER';
export const UPDATE_WORKBOOKS = 'UPDATE_WORKBOOKS';
export const DELETE_WORKBOOK = 'DELETE_WORKBOOK';
export const UPDATE_WORKBOOK_IDS_LIST = 'UPDATE_WORKBOOK_IDS_LIST';
export const CREATE_ANSWER = 'CREATE_ANSWER';
export const DELETE_ANSWER = 'DELETE_ANSWER';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_BUSY_STATUS = 'UPDATE_BUSY_STATUS';
export const ADD_TO_TOP_LIST = 'ADD_TO_TOP_LIST';
export const UPDATE_TOP_LIST = 'UPDATE_TOP_LIST';
export const ADD_TO_LIST_FRANCHISE = 'ADD_TO_LIST_FRANCHISE';
export const UPDATE_LIST_FRANCHISE = 'UPDATE_LIST_FRANCHISE';
export const ADD_TO_LIST_FRANCHISE_CUSTOMERS = 'ADD_TO_LIST_FRANCHISE_CUSTOMERS';
export const UPDATE_LIST_FRANCHISE_CUSTOMERS = 'UPDATE_LIST_FRANCHISE_CUSTOMERS';
export const SET_WORKBOOK_STACK = 'SET_WORKBOOK_STACK';
export const RESET_WORKBOOK_STACK = 'RESET_WORKBOOK_STACK';
export const UPDATE_INDEX_EDIT_TITLE = 'UPDATE_INDEX_EDIT_TITLE';
export const UPDATE_NAME_WORKBOOK = 'UPDATE_NAME_WORKBOOK';
export const UPDATE_FULL_NAME_CANDIDATES = 'UPDATE_FULL_NAME_CANDIDATES';
export const UPDATE_SAVE_STATUS = 'UPDATE_SAVE_STATUS';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const UPDATE_PROGRESS_LAST = 'UPDATE_PROGRESS_LAST';
export const UPDATE_STATUS_SHOW_MESSAGE = 'UPDATE_STATUS_SHOW_MESSAGE';
export const UPDATE_STATUS_COMPLETE = 'UPDATE_STATUS_COMPLETE';

// pages
export const UPDATE_PAGES_REPORTS_ARR = 'UPDATE_PAGES_REPORTS_ARR'
export const UPDATE_PAGES_BUSY_STATUS = 'UPDATE_PAGES_BUSY_STATUS'

export const UPDATE_MOBILE_DIRECTORY_FRANCHISES_ARR = 'UPDATE_MOBILE_DIRECTORY_FRANCHISES_ARR'
export const UPDATE_MOBILE_DIRECTORY_PAGINATION_OBJ = 'UPDATE_MOBILE_DIRECTORY_PAGINATION_OBJ'
export const UPDATE_MOBILE_DIRECTORY_FILTER_OBJ = 'UPDATE_MOBILE_DIRECTORY_FILTER_OBJ'
export const UPDATE_MOBILE_DIRECTORY_BUSY_STATUS = 'UPDATE_MOBILE_DIRECTORY_BUSY_STATUS'
export const UPDATE_MOBILE_DIRECTORY_CURRENT_PAGE = 'UPDATE_MOBILE_DIRECTORY_CURRENT_PAGE'
export const UPDATE_MOBILE_DIRECTORY_ADD_MODE_STATUS = 'UPDATE_MOBILE_DIRECTORY_ADD_MODE_STATUS'
export const UPDATE_MOBILE_DIRECTORY_FRANCHISE_INDEX = 'UPDATE_MOBILE_DIRECTORY_FRANCHISE_INDEX'
export const UPDATE_MOBILE_DIRECTORY_SCROLL_TOP = 'UPDATE_MOBILE_DIRECTORY_SCROLL_TOP'
export const UPDATE_MOBILE_DIRECTORY_FRANCHISE_ACTION = 'UPDATE_MOBILE_DIRECTORY_FRANCHISE_ACTION'

// pages top franchise
export const UPDATE_TOP_LIST_ARR = 'UPDATE_TOP_LIST_ARR'
export const UPDATE_TOP_LIST_NOT_PINNED_ARR = 'UPDATE_TOP_LIST_NOT_PINNED_ARR'
export const UPDATE_TOP_LIST_CURRENT_SLUG = 'UPDATE_TOP_LIST_CURRENT_SLUG'
export const UPDATE_TOP_LIST_CURRENT_TOP = 'UPDATE_TOP_LIST_CURRENT_TOP'
export const UPDATE_TOP_FRANCHISE_LIST_ARR = 'UPDATE_TOP_FRANCHISE_LIST_ARR'
export const UPDATE_TOP_FRANCHISE_LIST_PAGINATION = 'UPDATE_TOP_FRANCHISE_LIST_PAGINATION'
export const UPDATE_TOP_LIST_STATUS_BUSY = 'UPDATE_TOP_LIST_STATUS_BUSY'
export const UPDATE_TOP_FRANCHISE_LIST_STATUS_BUSY = 'UPDATE_TOP_FRANCHISE_LIST_STATUS_BUSY'



