import * as types from './../../mutation-types'

const convertToBinary = (stringHTML) => {
  return stringHTML.split('').map((char) => char.charCodeAt(0).toString(2)).join(' ')
}

export const state = () => ({
  noteList: {},
  note: {},
  route: `/api/v2/franchisor-contacts/notes/`,
  busy: true,
})

export const getters = {
  noteListPaginated: state => state.noteList,
  noteList: state => state.noteList.data,
  note: state => state.note,
  busy: state => state.busy,
}

export const mutations = {
  [types.FETCH_NOTE_LIST_SUCCESS] (state, data) {
    state.noteList = data
  },
  [types.FETCH_NOTE_LIST_FAILURE] (state) {
    state.noteList = {}
  },
  [types.FETCH_NOTE_SUCCESS] (state, data) {
    state.note = data
  },
  [types.FETCH_NOTE_FAILURE] (state) {
    state.note = {}
  },
  [types.SET_NOTE_BUSY] (state, status) {
    state.busy = status
  },
}

export const actions = {
  async fetchNoteList({ commit, rootState }, params) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const paramsUpdated = params
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null

      paramsUpdated.per_page = 4
      if (franchisorId) paramsUpdated.franchisor_id = franchisorId

      const { data } = await this.$axios.get(`/api/v2/franchisor-contacts/notes/`, { params: paramsUpdated })
      commit(types.FETCH_NOTE_LIST_SUCCESS, data.result)
    } catch (e) {
      commit(types.FETCH_NOTE_LIST_FAILURE)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
  async addNote({ commit, rootState }, payload) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const params = payload
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null

      if (params.note) params.note = convertToBinary(params.note)
      if (franchisorId) params.franchisor_id = franchisorId

      await this.$axios.post(`/api/v2/franchisor-contacts/notes/`, params)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
  async updateNote({ commit, rootState }, payload) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const params = payload
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null

      if (params.note) params.note = convertToBinary(params.note)
      if (franchisorId) params.franchisor_id = franchisorId

      await this.$axios.post(`/api/v2/franchisor-contacts/notes/${params.id}`, params)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
  async pinNote({ commit, rootState }, id) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null
      const params = {
        pin: true,
        franchisor_id: franchisorId,
      }
      await this.$axios.post(`/api/v2/franchisor-contacts/notes/${id}`, params)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
  async unpinNote({ commit, rootState }, id) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null
      const params = {
        pin: ``,
        franchisor_id: franchisorId,
      }
      await this.$axios.post(`/api/v2/franchisor-contacts/notes/${id}`, params)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
  async deleteNote({ commit, rootState }, id) {
    commit(types.SET_NOTE_BUSY, true)
    try {
      const franchisorId = rootState['account-franchisor'].ownerData?.id ?? null
      await this.$axios.delete(`/api/v2/franchisor-contacts/notes/${id}?franchisor_id=${franchisorId}`)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_NOTE_BUSY, false)
  },
}
