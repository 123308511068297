import * as types from './../mutation-types'

export const state = () => ({
  franchiseId: null,
  versionId: null,
  versionApproved: false,
  ownerApproved: false,
  ownerData: null,
  franchiseeViewed: {},
})

export const getters = {
  franchiseId: state => state.franchiseId,
  versionId: state => state.versionId,
  versionApproved: state => state.versionApproved,
  ownerApproved: state => state.ownerApproved,
  ownerData: state => state.ownerData,
  franchiseeViewed: state => state.franchiseeViewed,
}

export const mutations = {
  [types.FETCH_FRANCHISE_INFO_SUCCESS] (state, info) {
    state.franchiseId = info.franchise_id
    state.versionId = info.version_id
    state.versionApproved = info.version_approved
    state.ownerApproved = info.owner_approved
    state.ownerData = info.owner_data
  },
  [types.FETCH_FRANCHISE_INFO_FAILURE] (state) {
    state.franchiseId = null
    state.versionId = null
    state.versionApproved = null
    state.ownerApproved = null
    state.ownerData = null
  },
  [types.APPROVE_VERSION_SUCCESS] (state) {
    state.versionApproved = true
    state.ownerApproved = !!state.ownerData
  },
  [types.UPDATE_FRANCHISE_VIEWED] (state, contact) {
    state.franchiseeViewed = contact
  },
}

export const actions = {
  async fetchVersionInfo({ commit }, payload) {
    try {
      const params = {}
      if (payload && payload.versionId) {
        params.version_id = payload.versionId
      }
      const { data } = await this.$axios.get(`/api/v2/business-versions/info`, {
        params
      })

      commit(types.FETCH_FRANCHISE_INFO_SUCCESS, data.result)
    } catch (e) {
      commit(types.FETCH_FRANCHISE_INFO_FAILURE)
    }
  },
  async approveVersion({ commit, state }) {
    try {
      await this.$axios.get(`/api/v2/businesses/${state.versionId}/approve`)

      commit(types.APPROVE_VERSION_SUCCESS)
    } catch (e) {
      console.log(e)
    }
  },
  updateFranchiseeViewedId({commit},contact){
    commit(types.UPDATE_FRANCHISE_VIEWED, contact)
  },
}
