import * as types from '../mutation-types'

// state
export const state = () => ({
  show: false,
  editable: false,
  finished: false
})

// getters
export const getters = {
  stage: state => state.stage,
  finished: state => state.finished
}

// mutations
export const mutations = {
  [types.SET_SHOW_QUESTION_MODAL] (state, show) {
    state.show = show
  },
  [types.SET_FINISHED] (state, finished) {
    state.finished = finished
  },
  [types.SET_EDITABLE] (state, editable) {
    state.editable = editable
  }
}

// actions
export const actions = {
  setShow ({ commit }, show) {
    commit(types.SET_SHOW_QUESTION_MODAL, show)
  },
  setEdit ({ commit }, editable) {
    commit(types.SET_EDITABLE, editable)
  },
  removeQuestion ({ commit }, question) {
    commit(types.REMOVE_QUESTION, question)
  },
  addQuestion ({ commit }, question) {
    commit(types.ADD_QUESTION, question)
  },
  setFinished ({ commit }, finished) {
    commit(types.SET_FINISHED, finished)
  }
}
