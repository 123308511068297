import * as types from '../mutation-types'

// state
export const state = () => ({
  busy: false,
  show: false,
  answers: [],
  questions: [],
})

// getters
export const getters = {
  busy: state => state.busy,
  stage: state => state.stage,
  show: state => state.show,
  answers: state => state.answers,
  questions: state => state.questions,
}

// mutations
export const mutations = {
  [types.FETCH_ANSWERS_SUCCESS] (state, answers) {
    state.answers = answers
  },
  [types.FETCH_ANSWERS_FAILURE] (state) {
    state.answers = []
  },
  [types.FETCH_QUESTIONS_SUCCESS] (state, questions) {
    state.questions = questions
  },
  [types.FETCH_QUESTIONS_FAILURE] (state) {
    state.questions = []
  },
  [types.CHANGE_STATUS_BUSY] (state, status) {
    state.busy = status
  },
}

// actions
export const actions = {
  async fetchAnswers ({ commit, rootState }) {
    commit(types.CHANGE_STATUS_BUSY, true)
    try {
      const userId = rootState.account.franchiseeViewId ?? rootState.auth.user.id
      const { data } = await this.$axios.get(`api/v2/questions/${userId}/user-answers`)
      commit(types.FETCH_ANSWERS_SUCCESS, data.result)
    } catch (e) {
      commit(types.FETCH_ANSWERS_FAILURE)
    }
    commit(types.CHANGE_STATUS_BUSY, false)
  },
  async fetchQuestions ({ commit }) {
    commit(types.CHANGE_STATUS_BUSY, true)
    try {
      const { data } = await this.$axios.get('api/v2/questions')
      commit(types.FETCH_QUESTIONS_SUCCESS, data.result)
    } catch (e) {
      commit(types.FETCH_QUESTIONS_FAILURE)
    }
    commit(types.CHANGE_STATUS_BUSY, false)
  },
}
