import {cloneDeep} from 'lodash/fp'
import {
  WORK_BOOK, WORK_BOOK_CHAPTER_RULE_TYPES,
  WORK_BOOK_CHAPTER_RULES, WORK_BOOK_FIELD_TYPES,
  WORK_BOOK_QUESTION_TYPES,
  WORK_BOOK_TOP_LIST_KEYS
} from '@/store/constants'
import Vue from 'vue'
import * as types from '../mutation-types'

// helpers

export const createDefaultObjectAnswers = function (block) {
  let defaultValue = ''

  if (block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM) {
    defaultValue = []

    for (let i = 0; i < 10; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.TOP_TWO_ENTRY_CUSTOM) {

    defaultValue = []
    for (let i = 0; i < 2; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }

  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM) {
    defaultValue = []

    const defaultObject = {}
    block.fields.forEach(field => {
      defaultObject[field.slug] = ''
    })
    defaultValue.push(defaultObject)
  }

  if (
    block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_NINETY_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TWELVE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_FIVE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_ONE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_TEN ||
    block.type === WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_THREE
  ) {
    defaultValue = {}
    block.fields.forEach(field => {
      defaultValue[field.slug] = ''
    })
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN) {
    defaultValue = []
    for (let i = 0; i < 10; i += 1) {
      defaultValue.push('')
    }
  }
  if (block.type === WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_CANDIDATES) {
    defaultValue = []
    for (let i = 0; i < 5; i += 1) {
      defaultValue.push('')
    }
  }
  if (block.type === WORK_BOOK_QUESTION_TYPES.PROS_AND_CONS_TOP_THREE_ENTRY) {
    defaultValue = []
    for (let i = 0; i < 3; i += 1) {
      const defaultObject = {
        answers: [],
        summary: ''
      }
      for (let o = 0; o < 8; o += 1) {
        defaultObject.answers.push('')
      }

      defaultValue.push(defaultObject)
    }
  }

  if (
    block.type === WORK_BOOK_QUESTION_TYPES.COMPETITIVE_ANALYSIS_TOP_THREE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.BUSINESS_ATTRIBUTES
  ) {
    defaultValue = []

    for (let i = 0; i < 3; i += 1) {
      defaultValue.push('')
    }
  }

  if (
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_TEN_ENTRY
  ) {
    defaultValue = []

    for (let i = 0; i < 10; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_FIVE_ENTRY) {
    defaultValue = []

    for (let i = 0; i < 5; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ADDITIONAL) {
    defaultValue = []

    for (let i = 0; i < 5; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_THREE_ENTRY) {
    defaultValue = []

    for (let i = 0; i < 3; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_ONE_ENTRY ||
    block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_ONE_ENTRY_CENTERED
  ) {

    defaultValue = []

    for (let i = 0; i < 1; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.LOCAL_MARKETING_TOOLKIT) {
    defaultValue = []
    for (let i = 0; i < 1; i += 1) {
      const defaultObject = {}
      block.fields.forEach(field => {
        defaultObject[field.slug] = ''
      })
      defaultValue.push(defaultObject)
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.ADDITIONAL_QUESTIONS_TOP_FIVE) {
    defaultValue = {
      question: '',
      answers: []
    }
    for (let i = 0; i < 5; i += 1) {
      defaultValue.answers.push('')
    }
  }

  if (block.type === WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_WITH_CHOICE) {
    defaultValue = {
      text: '',
      selected: false
    }
  }

  return defaultValue
}

// state
export const state = () => ({
  show: false,
  topList: {},
  candidates: [],
  franchise: [],
  workBooks: [],
  workBooksIdsList: [],
  workBooksStack: {},
  workBooksStackCounter: 0,
  currentChapterAnswers: [],
  currentWorkBookIndex: null,
  currentSectionIndex: null,
  currentChapterIndex: null,
  editTitleWorkBookIndex: null,
  busy: false,
  needToSave: [],
  showMessage: true,
})

// getters
export const getters = {
  workBooks: state => state.workBooks,
  workBooksIdsList: state => state.workBooksIdsList,
  workBooksStack: state => state.workBooksStack,
  workBooksStackCounter: state => state.workBooksStackCounter,
  currentChapterAnswers: state => state.currentChapterAnswers,
  currentWorkBookIndex: state => state.currentWorkBookIndex,
  currentSectionIndex: state => state.currentSectionIndex,
  currentChapterIndex: state => state.currentChapterIndex,
  editTitleWorkBookIndex: state => state.editTitleWorkBookIndex,
  busy: state => state.busy,
  needToSave: state => workBookIndex => {

    if (Number(state.workBooksIdsList[workBookIndex]) === 0)
      return true

    return state.needToSave[workBookIndex] ?? false
  },
  topList: state => {
    if (state.currentWorkBookIndex === null)
      return {}

    return state.workBooks[state.currentWorkBookIndex].topList
  },
  franchise: state => {
    if (state.currentWorkBookIndex === null)
      return []

    return state.workBooks[state.currentWorkBookIndex].franchise
  },
  franchiseCustomers: state => {
    if (state.currentWorkBookIndex === null)
      return []

    return state.workBooks[state.currentWorkBookIndex].franchiseCustomers
  },
  candidates: state => {
    if (state.currentWorkBookIndex === null)
      return []

    return state.workBooks[state.currentWorkBookIndex].candidates
  },
  progress: state => workBookIndex => {
    return state.workBooks[workBookIndex].progress
  },
  isComplete: state => workBookIndex => {
    return state.workBooks[workBookIndex].isComplete
  },
  franchiseeNameList: state => franchiseID => {
    const franchiseeListChapterId = 2
    const franchiseeListSectionId = 1

    const {questionsBlocks} = state.workBooks[state.currentWorkBookIndex].answers[franchiseeListChapterId].chapters[franchiseeListSectionId];
    const questionBlock = questionsBlocks[franchiseID]
    const answers = questionBlock ? questionBlock.answers : []
    const franchiseList = []

    answers.forEach((answer, answerId) => {
      franchiseList[answerId] = answer.value.franchisee
    })
    return franchiseList
  },
  franchiseeTotalList: state => franchiseID => {
    const franchiseeListChapterId = 2
    const franchiseeListSectionId = 2

    const {questionsBlocks} = state.workBooks[state.currentWorkBookIndex].answers[franchiseeListChapterId].chapters[franchiseeListSectionId];
    const questionBlock = questionsBlocks[franchiseID]
    const answers = questionBlock ? questionBlock.answers : []
    const franchiseList = []

    answers.forEach(answer => {
      answer.value.forEach((value, valueId) => {
        franchiseList[valueId] = franchiseList[valueId] ?? 0
        franchiseList[valueId] += Number(value.initalScore) ?? 0
        franchiseList[valueId] += Number(value.impressionScore) ?? 0
      })
    })
    return franchiseList
  },
  showMessage: state => state.showMessage,
}

// mutations
export const mutations = {
  [types.SET_CURRENT_WORKBOOK_INDEX](state, index) {
    state.currentWorkBookIndex = index
    state.topList = index !== null ? state.workBooks[index].topList : {}
  },
  [types.SET_CURRENT_WORKBOOK_SECTION_INDEX](state, index) {
    state.currentSectionIndex = index
  },
  [types.SET_CURRENT_WORKBOOK_CHAPTER_INDEX](state, index) {
    state.currentChapterIndex = index
  },
  [types.SET_CURRENT_CHAPTER](state, data) {
    state.currentChapterAnswers = data
  },
  [types.UPDATE_WORKBOOKS](state, data) {
    state.workBooks = data
  },
  [types.DELETE_WORKBOOK](state, index) {
    state.workBooks.splice(index, 1)
    state.workBooksIdsList.splice(index, 1)
    state.needToSave.splice(index, 1)
  },
  [types.UPDATE_WORKBOOK_IDS_LIST](state, {id, index}) {
    if (index === null)
      state.workBooksIdsList.push(0)
    else
      state.workBooksIdsList[index] = id
  },
  [types.CREATE_ANSWER](state, {blockIndex, value}) {
    const wbIndex = state.currentWorkBookIndex
    const sectionIndex = state.currentSectionIndex
    const chapterIndex = state.currentChapterIndex
    state.workBooks[wbIndex].answers[sectionIndex].chapters[chapterIndex].questionsBlocks[blockIndex].answers.push(value)
  },
  [types.DELETE_ANSWER](state, {blockIndex, answerIndex}) {
    const wbIndex = state.currentWorkBookIndex
    const sectionIndex = state.currentSectionIndex
    const chapterIndex = state.currentChapterIndex
    state.workBooks[wbIndex].answers[sectionIndex].chapters[chapterIndex].questionsBlocks[blockIndex].answers.splice(answerIndex, 1)
  },
  [types.UPDATE_ANSWER](state, {blockIndex, questionIndex, value}) {
    const wbIndex = state.currentWorkBookIndex
    const sectionIndex = state.currentSectionIndex
    const chapterIndex = state.currentChapterIndex
    state.workBooks[wbIndex].answers[sectionIndex].chapters[chapterIndex].questionsBlocks[blockIndex].answers[questionIndex].value = value
  },
  [types.UPDATE_BUSY_STATUS](state, status) {
    state.busy = status
  },
  [types.ADD_TO_TOP_LIST](state, {listIndex, franchiseId}) {
    state.workBooks[state.currentWorkBookIndex].topList[listIndex].push(franchiseId)
  },
  [types.UPDATE_TOP_LIST](state, {listIndex, indexFranchise, franchiseId}) {
    state.workBooks[state.currentWorkBookIndex].topList[listIndex][indexFranchise] = franchiseId
  },
  [types.ADD_TO_LIST_FRANCHISE](state, {franchise}) {
    state.workBooks[state.currentWorkBookIndex].franchise.push(franchise)
  },
  [types.UPDATE_LIST_FRANCHISE](state, {franchiseIndex, franchise}) {
    state.workBooks[state.currentWorkBookIndex].franchise[franchiseIndex] = franchise
  },
  [types.ADD_TO_LIST_FRANCHISE_CUSTOMERS](state, {franchiseCustomer}) {
    state.workBooks[state.currentWorkBookIndex].franchiseCustomers.push(franchiseCustomer)
  },
  [types.UPDATE_LIST_FRANCHISE_CUSTOMERS](state, {index, franchiseCustomer}) {
    state.workBooks[state.currentWorkBookIndex].franchiseCustomers[index] = franchiseCustomer
  },
  [types.SET_WORKBOOK_STACK](state, {key, val}) {
    state.workBooksStack[key] = val

    let sum = 0
    for (const el in state.workBooksStack[key]) {
      if (state.workBooksStack[key].hasOwnProperty(el)) {
        sum += parseFloat(state.workBooksStack[key][el])
      }
    }
    state.workBooksStackCounter = sum
  },
  [types.RESET_WORKBOOK_STACK](state) {
    state.workBooksStack = []
  },
  [types.UPDATE_INDEX_EDIT_TITLE](state, index) {
    state.editTitleWorkBookIndex = index
  },
  [types.UPDATE_NAME_WORKBOOK](state, {newName, index}) {
    state.workBooks[index].name = newName
  },
  [types.UPDATE_FULL_NAME_CANDIDATES](state, {indexCandidate, fullNameCandidates}) {
    state.workBooks[state.currentWorkBookIndex].candidates[indexCandidate] = fullNameCandidates
  },
  [types.UPDATE_SAVE_STATUS](state, {status, workBookIndex}) {

    Vue.set(state.needToSave, workBookIndex, status)
  },
  [types.UPDATE_PROGRESS](state) {
    state.workBooks[state.currentWorkBookIndex].progress.sectionIndex = state.currentSectionIndex
    state.workBooks[state.currentWorkBookIndex].progress.chapterIndex = state.currentChapterIndex
  },
  [types.UPDATE_PROGRESS_LAST](state, {sectionIndex, chapterIndex}) {
    state.workBooks[state.currentWorkBookIndex].progress.lastSectionIndex = sectionIndex
    state.workBooks[state.currentWorkBookIndex].progress.lastChapterIndex = chapterIndex
  },
  [types.UPDATE_STATUS_SHOW_MESSAGE](state, status) {
    state.showMessage = status
  },
  [types.UPDATE_STATUS_COMPLETE](state, status) {
    state.workBooks[state.currentWorkBookIndex].isComplete = status
  },
}

// actions
export const actions = {
  createWorkBook({commit, state}) {
    const workBook = cloneDeep(state.workBooks)
    const newWorkBook = {name: 'My workbook'}

    const newSections = []
    WORK_BOOK.forEach((section) => {
      const newChapters = []
      section.chapters.forEach((chapter) => {
        const newBlock = []
        chapter.questionsBlocks.forEach((block) => {
          const newQuestions = []
          block.questions.forEach((question) => {
            newQuestions.push({id: question.id, value: createDefaultObjectAnswers(block)})
          })
          newBlock.push({id: block.id, answers: newQuestions, type: block.type})
        })
        newChapters.push({id: chapter.id, questionsBlocks: newBlock})
      })
      newSections.push({id: section.id, chapters: newChapters})
    })
    newWorkBook.answers = newSections

    newWorkBook.topList = cloneDeep(WORK_BOOK_TOP_LIST_KEYS);

    Object.keys(WORK_BOOK_TOP_LIST_KEYS).forEach(key => {
      const insertArr = []
      if (key === WORK_BOOK_TOP_LIST_KEYS.TEN) {
        for (let i = 1; i <= 10; i += 1) {
          insertArr.push(i)
        }
      }

      newWorkBook.topList[key] = insertArr
    })

    newWorkBook.candidates = []
    newWorkBook.franchise = []
    newWorkBook.franchiseCustomers = []
    newWorkBook.isComplete = false
    newWorkBook.progress = {
      sectionIndex: 0,
      chapterIndex: 0,
      lastSectionIndex: null,
      lastChapterIndex: null,
    }

    for (let i = 0; i < 5; i += 1) {
      newWorkBook.candidates.push('')
    }

    workBook.push(newWorkBook)

    commit(types.UPDATE_WORKBOOK_IDS_LIST, {id: 0, index: null})
    commit(types.UPDATE_WORKBOOKS, workBook)
  },
  changeCurrentChapter({commit, state}, {workBookIndex, sectionIndex, chapterIndex}) {
    commit(types.SET_CURRENT_WORKBOOK_INDEX, workBookIndex)
    commit(types.SET_CURRENT_WORKBOOK_SECTION_INDEX, sectionIndex)
    commit(types.SET_CURRENT_WORKBOOK_CHAPTER_INDEX, chapterIndex)
    commit(types.SET_CURRENT_CHAPTER, state.workBooks[workBookIndex].answers[sectionIndex].chapters[chapterIndex])
  },
  async fetchWorkbooks({dispatch, commit, state}) {
    commit(types.UPDATE_BUSY_STATUS, true)
    try {
      const {data} = await this.$axios.get('/api/v2/workbook/get-all')
      const savedWorkBooks = []


      data.forEach((item, itemIndex) => {
        savedWorkBooks.push(JSON.parse(item.content))
        commit(types.UPDATE_WORKBOOK_IDS_LIST, {id: item.id, index: itemIndex})
      })
      const lengthBlankWorkBook = state.workBooksIdsList.filter(item => item === 0).length

      if (savedWorkBooks.length) {
        commit(types.UPDATE_WORKBOOKS, savedWorkBooks)
      } else if (lengthBlankWorkBook === 0) {
        dispatch('createWorkBook')
      }
    } catch (e) {
    }
    commit(types.UPDATE_BUSY_STATUS, false)
  },
  async saveWorkBook({state, commit, dispatch}, index = null) {
    commit(types.UPDATE_BUSY_STATUS, true)
    try {

      const workBookIndex = index === null ? state.currentWorkBookIndex : index
      const workBookId = state.workBooksIdsList[workBookIndex]

      const {data} = await this.$axios.post(`/api/v2/workbook/add/${workBookId}`, {
        content: JSON.stringify(state.workBooks[workBookIndex])
      })
      if (state.showMessage)
        this.$toast.success('Save was successful')

      commit(types.UPDATE_SAVE_STATUS, {status: false, workBookIndex})
      
      if (data.status === 'success' && Number(workBookId) === 0)
        commit(types.UPDATE_WORKBOOK_IDS_LIST, {
          id: data.result.id,
          index: workBookIndex
        })
    } catch (e) {
      if (state.showMessage)
        this.$toast.error('Something went wrong. Try again later.')

    }
    commit(types.UPDATE_STATUS_SHOW_MESSAGE, true)
    commit(types.UPDATE_BUSY_STATUS, false)
  },
  async deleteWorkbook({state, commit}, index) {
    const id = Number(state.workBooksIdsList[index])

    if (id > 0)
      await this.$axios.post(`/api/v2/workbook/delete/${id}`)

    commit(types.SET_CURRENT_WORKBOOK_INDEX, null)
    commit(types.DELETE_WORKBOOK, index)

  },
  changeIndexEditTitle({commit}, index) {
    commit(types.UPDATE_INDEX_EDIT_TITLE, index)
  },
  changeAnswer({commit, dispatch}, {blockIndex, questionIndex, value}) {
    commit(types.UPDATE_ANSWER, {blockIndex, questionIndex, value})
    dispatch('updateSaveStatus', true)
  },
  deleteAnswer({commit, dispatch}, {blockIndex, answerIndex}) {
    commit(types.DELETE_ANSWER, {blockIndex, answerIndex})
    dispatch('updateSaveStatus', true)
  },
  addAnswer({commit, state, dispatch}, {blockIndex}) {
    const block = WORK_BOOK[state.currentSectionIndex].chapters[state.currentChapterIndex].questionsBlocks[blockIndex]
    const value = {
      id: '',
      value: createDefaultObjectAnswers(block)
    }
    commit(types.CREATE_ANSWER, {blockIndex, value})
    dispatch('updateSaveStatus', true)
  },
  updateListFranchise({commit, state}, {franchise}) {
    const franchiseIndex = state.workBooks[state.currentWorkBookIndex].franchise.findIndex(item => item.id === franchise.id)

    if (franchiseIndex === -1)
      commit(types.ADD_TO_LIST_FRANCHISE, {franchise})

    if (franchiseIndex !== -1)
      commit(types.UPDATE_LIST_FRANCHISE, {franchiseIndex, franchise})

  },
  updateListFranchiseCustomers({commit, state}, {franchiseCustomer}) {
    const index = state.workBooks[state.currentWorkBookIndex].franchiseCustomers.findIndex(item => item.id === franchiseCustomer.id)

    if (index === -1)
      commit(types.ADD_TO_LIST_FRANCHISE_CUSTOMERS, {franchiseCustomer})

    if (index !== -1)
      commit(types.UPDATE_LIST_FRANCHISE_CUSTOMERS, {index, franchiseCustomer})

  },
  updateTopList({commit, state}, {listIndex, indexFranchise, franchiseId}) {
    const currentTopList = state.topList[listIndex]
    if (typeof currentTopList[indexFranchise] === "undefined") {
      commit(types.ADD_TO_TOP_LIST, {listIndex, franchiseId})
      return
    }

    commit(types.UPDATE_TOP_LIST, {listIndex, indexFranchise, franchiseId})
  },
  setStack({commit}, {key, val}) {
    commit(types.SET_WORKBOOK_STACK, {key, val})
  },
  resetStack({commit}) {
    commit(types.RESET_WORKBOOK_STACK)
  },
  updateNameWorkBook({state, commit, dispatch}, {newName, index}) {
    commit(types.UPDATE_INDEX_EDIT_TITLE, null)
    if (state.workBooks[index].name === newName)
      return

    commit(types.UPDATE_NAME_WORKBOOK, {newName, index})
    dispatch('saveWorkBook', index)
  },
  updateListCandidates({commit}, {indexCandidate, fullNameCandidates}) {
    commit(types.UPDATE_FULL_NAME_CANDIDATES, {indexCandidate, fullNameCandidates})
  },
  gotoNextChapter({state, commit, dispatch}) {
    let sectionIndex = 0
    let chapterIndex = 0
    const workBookIndex = state.currentWorkBookIndex
    const lastSectionIndex = WORK_BOOK.length - 1
    const lastChapterIndex = WORK_BOOK[state.currentSectionIndex].chapters.length - 1

    if (state.currentChapterIndex < lastChapterIndex) {
      sectionIndex = state.currentSectionIndex
      chapterIndex = state.currentChapterIndex + 1
    }

    if (state.currentChapterIndex === lastChapterIndex && state.currentSectionIndex < lastSectionIndex) {
      sectionIndex = state.currentSectionIndex + 1
      chapterIndex = 0
    }

    if (state.currentChapterIndex === lastChapterIndex && state.currentSectionIndex === lastSectionIndex)
      return


    dispatch('changeCurrentChapter', {workBookIndex, sectionIndex, chapterIndex})

    dispatch('updateProgress')
    const progress = state.workBooks[state.currentWorkBookIndex].progress

    if (
      (sectionIndex === progress.lastSectionIndex &&
        chapterIndex === progress.lastChapterIndex) ||
      (sectionIndex > progress.lastSectionIndex ||
        chapterIndex > progress.lastChapterIndex)
    )
      dispatch('updateProgressLast', {sectionIndex: null, chapterIndex: null})


    dispatch('saveWorkBook')
    commit(types.UPDATE_STATUS_SHOW_MESSAGE, false)
  },
  updateStatusComplete({commit}, status) {
    commit(types.UPDATE_STATUS_COMPLETE, status)
  },
  updateProgress({commit}) {
    commit(types.UPDATE_PROGRESS)
  },
  updateProgressLast({commit}, {sectionIndex, chapterIndex}) {
    commit(types.UPDATE_PROGRESS_LAST, {sectionIndex, chapterIndex})
  },
  updateSaveStatus({state, commit, dispatch}, status) {
    const selectedSectionIndex = state.currentSectionIndex
    const selectedChapterIndex = state.currentChapterIndex
    const inProgressSectionIndex = state.workBooks[state.currentWorkBookIndex].progress.sectionIndex
    const inProgressChapterIndex = state.workBooks[state.currentWorkBookIndex].progress.chapterIndex

    if (
      (selectedChapterIndex !== inProgressChapterIndex ||
        selectedSectionIndex !== inProgressSectionIndex) &&
      status
    ) {
      dispatch('updateProgressLast', {
        sectionIndex: inProgressSectionIndex,
        chapterIndex: inProgressChapterIndex
      })
      dispatch('updateProgress')
    }

    commit(types.UPDATE_SAVE_STATUS, {status, workBookIndex: state.currentWorkBookIndex})
  },
}
