import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _4fcef69e from '../pages/about.vue'
import _67b78851 from '../pages/account.vue'
import _bad263d8 from '../pages/account/index.vue'
import _86df9168 from '../pages/account/comparisons.vue'
import _e0cc0c54 from '../pages/account/dashboard.vue'
import _2cab3a89 from '../pages/account/favorite-franchises.vue'
import _08fe9d6c from '../pages/account/franchise-university.vue'
import _33cf7640 from '../pages/account/my-matches.vue'
import _5193a8eb from '../pages/account/profile.vue'
import _a40e3e52 from '../pages/account-franchisor.vue'
import _48f92a1a from '../pages/account-franchisor/index.vue'
import _f9b9b4a6 from '../pages/account-franchisor/budget.vue'
import _ed644c04 from '../pages/account-franchisor/contacts/index.vue'
import _45c2e2dc from '../pages/account-franchisor/dashboard.vue'
import _25ef61c2 from '../pages/account-franchisor/onboarding-packet.vue'
import _4a5730b2 from '../pages/account-franchisor/submissions/index.vue'
import _72ab664e from '../pages/account-franchisor/tasks/index.vue'
import _073e54e5 from '../pages/account-franchisor/contacts/_contact.vue'
import _646d8290 from '../pages/account-franchisor/_franchiseId/steps/_step/index.vue'
import _14eede36 from '../pages/account-franchisor/_franchiseId/steps/_step/chat/index.vue'
import _185c20c6 from '../pages/account-franchisor/_franchiseId/steps/_step/chat/_slug.vue'
import _e913029e from '../pages/blog/index.vue'
import _f28780f0 from '../pages/chat-page.vue'
import _3a7d0c9b from '../pages/company-pages/index.vue'
import _64d9cbb4 from '../pages/contact-pre-register.vue'
import _92d225b2 from '../pages/disclaimer.vue'
import _c623e5d6 from '../pages/DM.vue'
import _019c21bc from '../pages/editor/index.vue'
import _2df35224 from '../pages/franchise-directory/index.vue'
import _3b642f0d from '../pages/franchise-glossary-definitions.vue'
import _7327dbca from '../pages/franchise-university.vue'
import _e074c5e8 from '../pages/franchisee-register.vue'
import _8cddbade from '../pages/franchisee-steps/index.vue'
import _96804a08 from '../pages/franchising-faqs.vue'
import _2934e6dc from '../pages/industry-reports.vue'
import _61d569e2 from '../pages/membership.vue'
import _be5006de from '../pages/new-landing/index.vue'
import _7ce0e754 from '../pages/own-franchise-steps.vue'
import _68157974 from '../pages/pcmania/index.vue'
import _0c093aba from '../pages/privacy-policy.vue'
import _51f65e0e from '../pages/privacy-statement.vue'
import _23c4e3c2 from '../pages/radio.vue'
import _2f0b4d88 from '../pages/see-franchises.vue'
import _bc63c4aa from '../pages/terms.vue'
import _2976078e from '../pages/test-component.vue'
import _755191ac from '../pages/test-editor.vue'
import _530692fd from '../pages/top-franchises/index.vue'
import _5a437436 from '../pages/auth/auto-login.vue'
import _3a2edc16 from '../pages/auth/logout.vue'
import _9963f1c6 from '../pages/franchise-directory/industries/index.vue'
import _12d3b8e9 from '../pages/franchisee-steps/chat.vue'
import _705ad44b from '../pages/new/editor/index.vue'
import _e64b3386 from '../pages/blog/category/_category.vue'
import _5da50bfe from '../pages/blog/post/_post.vue'
import _186a130b from '../pages/franchise-directory/industries/_industry/index.vue'
import _2a8f45a4 from '../pages/new/landing/_landing/index.vue'
import _3ff6ed50 from '../pages/franchise-directory/industries/_industry/_company/index.vue'
import _16b371c3 from '../pages/franchise-directory/industries/_industry/_slug.vue'
import _5379a1ca from '../pages/new/landing/_landing/_page.vue'
import _43642fe0 from '../pages/franchise-directory/industries/_industry/_company/_slug.vue'
import _6aed5c70 from '../pages/company-pages/_company/index.vue'
import _0999412d from '../pages/new-landing/_page.vue'
import _0d0f1988 from '../pages/top-franchises/_category.vue'
import _6e5a9f00 from '../pages/company-pages/_company/_slug.vue'
import _105f4114 from '../pages/index.vue'
import _974797aa from '../pages/index/auth/login.vue'
import _d91201de from '../pages/index/auth/reset.vue'
import _340171d9 from '../pages/index/email/confirm-change.vue'
import _6e2d0b7d from '../pages/index/auth/verify/_userId.vue'
import _1b1e4a67 from '../pages/_landing/index.vue'
import _f840e1fa from '../pages/_landing/_page.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _4fcef69e,
    name: "about___en"
  }, {
    path: "/account",
    component: _67b78851,
    children: [{
      path: "",
      component: _bad263d8,
      name: "account___en"
    }, {
      path: "comparisons",
      component: _86df9168,
      name: "account-comparisons___en"
    }, {
      path: "dashboard",
      component: _e0cc0c54,
      name: "account-dashboard___en"
    }, {
      path: "favorite-franchises",
      component: _2cab3a89,
      name: "account-favorite-franchises___en"
    }, {
      path: "franchise-university",
      component: _08fe9d6c,
      name: "account-franchise-university___en"
    }, {
      path: "my-matches",
      component: _33cf7640,
      name: "account-my-matches___en"
    }, {
      path: "profile",
      component: _5193a8eb,
      name: "account-profile___en"
    }]
  }, {
    path: "/account-franchisor",
    component: _a40e3e52,
    children: [{
      path: "",
      component: _48f92a1a,
      name: "account-franchisor___en"
    }, {
      path: "budget",
      component: _f9b9b4a6,
      name: "account-franchisor-budget___en"
    }, {
      path: "contacts",
      component: _ed644c04,
      name: "account-franchisor-contacts___en"
    }, {
      path: "dashboard",
      component: _45c2e2dc,
      name: "account-franchisor-dashboard___en"
    }, {
      path: "onboarding-packet",
      component: _25ef61c2,
      name: "account-franchisor-onboarding-packet___en"
    }, {
      path: "submissions",
      component: _4a5730b2,
      name: "account-franchisor-submissions___en"
    }, {
      path: "tasks",
      component: _72ab664e,
      name: "account-franchisor-tasks___en"
    }, {
      path: "contacts/:contact",
      component: _073e54e5,
      name: "account-franchisor-contacts-contact___en"
    }, {
      path: ":franchiseId/steps/:step",
      component: _646d8290,
      name: "account-franchisor-franchiseId-steps-step___en"
    }, {
      path: ":franchiseId/steps/:step?/chat",
      component: _14eede36,
      name: "account-franchisor-franchiseId-steps-step-chat___en"
    }, {
      path: ":franchiseId/steps/:step?/chat/:slug",
      component: _185c20c6,
      name: "account-franchisor-franchiseId-steps-step-chat-slug___en"
    }]
  }, {
    path: "/blog",
    component: _e913029e,
    name: "blog___en"
  }, {
    path: "/chat-page",
    component: _f28780f0,
    name: "chat-page___en"
  }, {
    path: "/company-pages",
    component: _3a7d0c9b,
    name: "company-pages___en"
  }, {
    path: "/contact-pre-register",
    component: _64d9cbb4,
    name: "contact-pre-register___en"
  }, {
    path: "/disclaimer",
    component: _92d225b2,
    name: "disclaimer___en"
  }, {
    path: "/DM",
    component: _c623e5d6,
    name: "DM___en"
  }, {
    path: "/editor",
    component: _019c21bc,
    name: "editor___en"
  }, {
    path: "/franchise-directory",
    component: _2df35224,
    name: "franchise-directory___en"
  }, {
    path: "/franchise-glossary-definitions",
    component: _3b642f0d,
    name: "franchise-glossary-definitions___en"
  }, {
    path: "/franchise-university",
    component: _7327dbca,
    name: "franchise-university___en"
  }, {
    path: "/franchisee-register",
    component: _e074c5e8,
    name: "franchisee-register___en"
  }, {
    path: "/franchisee-steps",
    component: _8cddbade,
    name: "franchisee-steps___en"
  }, {
    path: "/franchising-faqs",
    component: _96804a08,
    name: "franchising-faqs___en"
  }, {
    path: "/industry-reports",
    component: _2934e6dc,
    name: "industry-reports___en"
  }, {
    path: "/membership",
    component: _61d569e2,
    name: "membership___en"
  }, {
    path: "/new-landing",
    component: _be5006de,
    name: "new-landing___en"
  }, {
    path: "/own-franchise-steps",
    component: _7ce0e754,
    name: "own-franchise-steps___en"
  }, {
    path: "/pcmania",
    component: _68157974,
    name: "pcmania___en"
  }, {
    path: "/privacy-policy",
    component: _0c093aba,
    name: "privacy-policy___en"
  }, {
    path: "/privacy-statement",
    component: _51f65e0e,
    name: "privacy-statement___en"
  }, {
    path: "/radio",
    component: _23c4e3c2,
    name: "radio___en"
  }, {
    path: "/see-franchises",
    component: _2f0b4d88,
    name: "see-franchises___en"
  }, {
    path: "/terms",
    component: _bc63c4aa,
    name: "terms___en"
  }, {
    path: "/test-component",
    component: _2976078e,
    name: "test-component___en"
  }, {
    path: "/test-editor",
    component: _755191ac,
    name: "test-editor___en"
  }, {
    path: "/top-franchises",
    component: _530692fd,
    name: "top-franchises___en"
  }, {
    path: "/auth/auto-login",
    component: _5a437436,
    name: "auth-auto-login___en"
  }, {
    path: "/auth/logout",
    component: _3a2edc16,
    name: "auth-logout___en"
  }, {
    path: "/franchise-directory/industries",
    component: _9963f1c6,
    name: "franchise-directory-industries___en"
  }, {
    path: "/franchisee-steps/chat",
    component: _12d3b8e9,
    name: "franchisee-steps-chat___en"
  }, {
    path: "/new/editor",
    component: _705ad44b,
    name: "new-editor___en"
  }, {
    path: "/blog/category/:category?",
    component: _e64b3386,
    name: "blog-category-category___en"
  }, {
    path: "/blog/post/:post?",
    component: _5da50bfe,
    name: "blog-post-post___en"
  }, {
    path: "/franchise-directory/industries/:industry",
    component: _186a130b,
    name: "franchise-directory-industries-industry___en"
  }, {
    path: "/new/landing/:landing",
    component: _2a8f45a4,
    name: "new-landing-landing___en"
  }, {
    path: "/franchise-directory/industries/:industry/:company",
    component: _3ff6ed50,
    name: "franchise-directory-industries-industry-company___en"
  }, {
    path: "/franchise-directory/industries/:industry/:slug",
    component: _16b371c3,
    name: "franchise-directory-industries-industry-slug___en"
  }, {
    path: "/new/landing/:landing/:page",
    component: _5379a1ca,
    name: "new-landing-landing-page___en"
  }, {
    path: "/franchise-directory/industries/:industry/:company/:slug",
    component: _43642fe0,
    name: "franchise-directory-industries-industry-company-slug___en"
  }, {
    path: "/company-pages/:company",
    component: _6aed5c70,
    name: "company-pages-company___en"
  }, {
    path: "/new-landing/:page?",
    component: _0999412d,
    name: "new-landing-page___en"
  }, {
    path: "/top-franchises/:category?",
    component: _0d0f1988,
    name: "top-franchises-category___en"
  }, {
    path: "/company-pages/:company?/:slug",
    component: _6e5a9f00,
    name: "company-pages-company-slug___en"
  }, {
    path: "/",
    component: _105f4114,
    name: "index___en",
    children: [{
      path: "auth/login",
      component: _974797aa,
      name: "index-auth-login___en"
    }, {
      path: "auth/reset",
      component: _d91201de,
      name: "index-auth-reset___en"
    }, {
      path: "email/confirm-change",
      component: _340171d9,
      name: "index-email-confirm-change___en"
    }, {
      path: "auth/verify/:userId?",
      component: _6e2d0b7d,
      name: "index-auth-verify-userId___en"
    }]
  }, {
    path: "/:landing",
    component: _1b1e4a67,
    name: "landing___en"
  }, {
    path: "/:landing/:page",
    component: _f840e1fa,
    name: "landing-page___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
