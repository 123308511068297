import {
  FRANCHISOR_FLOW_OPTIONS,
  REGISTRATION_STAGE_FORM,
  REGISTRATION_STAGE_SELECT_USER_TYPE,
  USER_TYPE_FRANCHISOR
} from "@/store/constants"
import * as types from './mutation-types'
import {cloneDeep} from 'lodash/fp'

// state
export const state = () => ({
  stage: '',
  franchise_stage: 0,
  show: false,
  userType: '',
  franchiseId: null,
  option: '',
  franchise: {},
  company_url: '',
  allow_reclaim: false,
  fast_signup_flow_franchisor: false,
  enableFlowBeginToday: false,
  enableFlowPendingList: false,
  pendingListEmail: '',
  pendingListSendArr: false,
  leads: {},
})

// getters
export const getters = {
  stage: state => state.stage,
  franchise_stage: state => state.franchise_stage,
  show: state => state.show,
  userType: state => state.userType,
  franchiseId: state => state.franchiseId,
  franchise: state => state.franchise,
  option: state => state.option,
  company_url: state => state.company_url,
  allow_reclaim: state => state.allow_reclaim,
  fast_signup_flow_franchisor: state => state.fast_signup_flow_franchisor,
  enableFlowBeginToday: state => state.enableFlowBeginToday,
  enableFlowPendingList: state => state.enableFlowPendingList,
  pendingListEmail: state => state.pendingListEmail,
  pendingListSendArr: state => state.pendingListSendArr,
  leads: state => state.leads,
}

// mutations
export const mutations = {
  [types.SET_REGISTRATION_STAGE](state, stage) {
    state.stage = stage
  },
  [types.SET_REGISTRATION_FRANCHISEE_STAGE](state, stage) {
    state.franchise_stage = stage
  },
  [types.SET_SHOW_REGISTRATION_MODAL](state, show) {
    state.show = show
  },
  [types.SET_REGISTRATION_USER_TYPE](state, userType) {
    state.userType = userType
  },
  [types.SET_FRANCHISE_ID](state, franchiseId) {
    state.franchiseId = franchiseId
  },
  [types.SET_OPTION](state, option) {
    state.option = option
  },
  [types.FETCH_BUSINESS_SUCCESS](state, franchise) {
    state.franchise = franchise
  },
  [types.FETCH_BUSINESS_FAILURE](state) {
    state.franchise = {}
  },
  [types.SET_COMPANY_PAGE_URL](state, url) {
    state.company_url = url
  },
  [types.SET_ALLOW_RECLAIM](state, stage) {
    state.allow_reclaim = stage
  },
  [types.TOGGLE_FAST_SIGNUP_FRANCHISOR](state, toggle) {
    state.fast_signup_flow_franchisor = toggle
  },
  [types.TOGGLE_ENABLE_FLOW_BEGIN_TODAY](state, toggle) {
    state.enableFlowBeginToday = toggle
  },
  [types.TOGGLE_ENABLE_FLOW_PENDING_LIST](state, toggle) {
    state.enableFlowPendingList = toggle
  },
  [types.UPDATE_PENDING_LIST_EMAIL](state, email) {
    state.pendingListEmail = email
  },
  [types.UPDATE_PENDING_LIST_TOGGLE_SAVE](state, status) {
    state.pendingListSendArr = status
  },
  [types.FETCH_LEADS_SUCCESS](state, leads) {
    state.leads = leads
  },
  [types.FETCH_LEADS_FAILURE](state) {
    state.leads = {
      business: 0,
      industry: 0,
      lost: 0,
    }
  },
}

// actions
export const actions = {
  setStage({commit}, stage) {
    commit(types.SET_REGISTRATION_STAGE, stage)
  },
  setShow({commit}, show) {
    commit(types.SET_SHOW_REGISTRATION_MODAL, show)
  },
  setUserType({commit}, userType) {
    commit(types.SET_REGISTRATION_USER_TYPE, userType)
  },
  setFranchiseId({commit}, franchiseId) {
    commit(types.SET_FRANCHISE_ID, franchiseId)
  },
  setOption({commit}, option) {
    commit(types.SET_OPTION, option)
  },
  setAllowReclaim({commit}, stage) {
    commit(types.SET_ALLOW_RECLAIM, stage)
  },
  openModal({commit}, payload) {
    const params = {
      stage: REGISTRATION_STAGE_SELECT_USER_TYPE,
      userType: null,
    }

    if (payload) {
      if (payload.stage) {
        params.stage = payload.stage
      }

      if (payload.userType) {
        params.userType = payload.userType
      }
    }
    commit(types.SET_REGISTRATION_STAGE, params.stage)
    commit(types.SET_REGISTRATION_USER_TYPE, params.userType)
    commit(types.SET_SHOW_REGISTRATION_MODAL, true)
  },
  closeModal({commit}) {
    commit(types.SET_SHOW_REGISTRATION_MODAL, false)

    setTimeout(() => {
      commit(types.SET_REGISTRATION_USER_TYPE, null)
      commit(types.SET_REGISTRATION_STAGE, REGISTRATION_STAGE_SELECT_USER_TYPE)
    }, 300)
  },
  async fetchFranchise({commit, state}) {
    if (state.franchiseId && state.option === FRANCHISOR_FLOW_OPTIONS.claim) {
      try {
        const {data} = await this.$axios.get(`/api/v1/business/${state.franchiseId}/data/latest`)
        const mapped = data.data.map(b => {
          return {
            franchise_name: b.business_name.attribute_value_string ?? '',
            established: b.established.attribute_value_string ?? ''
              ? b.established.attribute_value_string.substr(0, 4)
              : null,
            investment_min: b.investment.attribute_value_numeric_low,
            investment_max: b.investment.attribute_value_numeric_high,
            franchise_fee_min: b.franchise_fee.attribute_value_numeric_low,
            franchise_fee_max: b.franchise_fee.attribute_value_numeric_high,
            franchising_since: b.franchising_since.attribute_value_string ?? ''
              ? b.franchising_since.attribute_value_string.substr(0, 4)
              : null,
            naics: b.NAICS.attribute_value_string ?? '',
            royalty: b.main_royalty_fee.attribute_value_string ?? '',
            royalty_percent: b.main_royalty_fee_percent.attribute_value_string ?? '',
            website_url: '',
            industry: b.industry.attribute_value_string ?? '',
            short_descr: b.short_descr.attribute_value_string ?? ''
          }
        })

        const franchise = mapped[0]
        const stateIds = []
        data.data[0].states.forEach(state => {
          stateIds.push(state.id)
        })
        franchise.states = stateIds

        commit(types.FETCH_BUSINESS_SUCCESS, franchise)
      } catch (e) {
        console.log(e)
        commit(types.FETCH_BUSINESS_FAILURE)
      }
    }
  },
  async fetchLeads({commit, state}) {
    if (state.franchiseId) {
      try {
        const {data} = await this.$axios.get(`/api/v2/statistics/leads-count-by-franchise/${state.franchiseId}`)
        commit(types.FETCH_LEADS_SUCCESS, data.result)
      } catch (e) {
        console.log(e)
        commit(types.FETCH_LEADS_FAILURE)
      }
    }
  },
  async enableFastSignUpFlowFranchisor({dispatch, commit}, franchiseId) {
    if (!franchiseId)
      return

    commit(types.SET_FRANCHISE_ID, franchiseId)
    commit(types.SET_OPTION, FRANCHISOR_FLOW_OPTIONS.claim)
    commit(types.TOGGLE_FAST_SIGNUP_FRANCHISOR, true)

    dispatch('fetchFranchise')
    dispatch('openModal', {stage: REGISTRATION_STAGE_FORM, userType: USER_TYPE_FRANCHISOR})

  },
  changeStatusFlowBeginToday({commit}, status) {
    commit(types.TOGGLE_ENABLE_FLOW_BEGIN_TODAY, status)
  },
  changeStatusFlowPendingList({commit}, email) {
    commit(types.UPDATE_PENDING_LIST_EMAIL, email)
    commit(types.TOGGLE_ENABLE_FLOW_PENDING_LIST, email !== '')
  },
  changeStatusFlowPendingListWithSave({commit}, email) {
    const status = email !== '' || email === 'default'
    commit(types.UPDATE_PENDING_LIST_EMAIL, email !== 'default' ? email : '')
    commit(types.TOGGLE_ENABLE_FLOW_PENDING_LIST, status)
    commit(types.UPDATE_PENDING_LIST_TOGGLE_SAVE, status)
  },
  savePendingList({rootState, dispatch}) {
    try {
      const url = 'api/v2/business-pending/save-list-without-user'
      const list = cloneDeep(rootState.account.pendingListArr)
      const {data} = this.$axios.post(url, {list})

      if (data.result){
        this.$axios.post('api/v2/business-pending/save-list', {list})
      }
        dispatch('account/updatePendingList', [])
    } catch (e) {

    }
  },
}
