import * as types from "@/store/mutation-types"
import {cloneDeep} from "lodash/fp"

export const state = () => ({
  taskListArr: [],
  paginationObj: {
    per_page: 3,
    current_page: 1,
  },
  filterObj: {
    startDate: null,
    endDate: null,
  },
  busy: false,
})


export const getters = {
  taskListArr: state => state.taskListArr,
  paginationObj: state => state.paginationObj,
  filterObj: state => state.filterObj,
  busy: state => state.busy,
}

export const mutations = {
  [types.UPDATE_CONTACT_TASK_LIST_ARR](state, taskListArr) {
    state.taskListArr = taskListArr
  },
  [types.UPDATE_CONTACT_TASK_PAGINTAION](state, paginationObj) {
    state.paginationObj = paginationObj
  },
  [types.UPDATE_CONTACT_TASK_FILTER](state, filterObj) {
    state.filterObj = filterObj
  },
  [types.UPDATE_CONTACT_TASK_STATUS_BUSY](state, status) {
    state.busy = status
  },
}


export const actions = {
  async fetchTasks({commit, state, rootState}, franchiseeId) {
    commit(types.UPDATE_CONTACT_TASK_STATUS_BUSY, true)
    try {
      const url = `/api/v2/franchisor-contacts/tasks/list`
      const params = {
        franchisee_id: franchiseeId,
        franchise_id: rootState["business-v2"].franchise?.id ?? 0,
        per_page: state.paginationObj.per_page,
        page: state.paginationObj.current_page,
      }

      if (state.filterObj.startDate !== null)
        params.start_date = `${state.filterObj.startDate.substring(0, 19)}+00:00`
      if (state.filterObj.endDate !== null)
        params.end_date = `${state.filterObj.endDate.substring(0, 19)}+00:00`

      const {data} = await this.$axios.get(url, {params})
      const tasksArr = cloneDeep(data.result.data)
      delete (data.result.data)
      commit(types.UPDATE_CONTACT_TASK_LIST_ARR, tasksArr)
      commit(types.UPDATE_CONTACT_TASK_PAGINTAION, data.result)
    } catch (e) {
      console.log(e)
    }
    commit(types.UPDATE_CONTACT_TASK_STATUS_BUSY, false)
  },
  changePage({commit, state}, page) {
    const pagination = cloneDeep(state.paginationObj)
    pagination.current_page = page
    commit(types.UPDATE_CONTACT_TASK_PAGINTAION, pagination)
  },
  updateFilter({commit}, filterObj) {
    commit(types.UPDATE_CONTACT_TASK_FILTER, filterObj)
  },
}
