/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  SET_BUSINESS_CLIENT_STEPS,
  SET_IN_PROGRESS_BUSINESS_CLIENT_STEP,
  SET_FRANCHISEE_STEP_DOCUMENTS,
  SET_FRANCHISEE_STEP_DOCUMENTS_LOADING,
  SET_AS_DOCUMENT_DOWNLOADED,
} from './mutation-types';
import { STATUS_IN_PROGRESS } from '~/store/constants';

// state
export const state = () => ({
  businessStepDocumentList: [],
  businessStepDocumentLoading: false,
  businessClientSteps: [],
  inProgressBusinessClientStep: null,
});

// getters
export const getters = {
  businessClientSteps: (state) => state.businessClientSteps,
  businessStepDocumentList: (state) => state.businessStepDocumentList,
  businessStepDocumentLoading: (state) => state.businessStepDocumentLoading,
  inProgressBusinessClientStep: (state) => state.inProgressBusinessClientStep,
};

// mutations
export const mutations = {
  [SET_BUSINESS_CLIENT_STEPS](state, businessClientSteps) {
    state.businessClientSteps = businessClientSteps;
  },
  [SET_IN_PROGRESS_BUSINESS_CLIENT_STEP](state, businessClientSteps) {
    const actualStepArray = businessClientSteps.filter(
      (step) => step.status === STATUS_IN_PROGRESS
    );
    if (actualStepArray.length) {
      state.inProgressBusinessClientStep = actualStepArray[0];
    }
  },
  [SET_FRANCHISEE_STEP_DOCUMENTS](state, businessStepDocumentList) {
    state.businessStepDocumentList = businessStepDocumentList;
  },
  [SET_FRANCHISEE_STEP_DOCUMENTS_LOADING](state, loading) {
    state.businessStepDocumentLoading = loading;
  },
  [SET_AS_DOCUMENT_DOWNLOADED](state, { stepIndex, docIndex }) {
    state.businessClientSteps[stepIndex].docs[docIndex].is_downloaded = true;
  },
};

// actions
export const actions = {
  async fetchStepDocuments({ commit }, businessStepId) {
    commit(SET_FRANCHISEE_STEP_DOCUMENTS_LOADING, true);
    try {
      const url = `/api/v1/business-step-docs/${businessStepId}`;
      const { data } = await this.$axios.get(url);

      commit(SET_FRANCHISEE_STEP_DOCUMENTS, data.data);
    } catch (e) {
      console.log(e);
    }
    commit(SET_FRANCHISEE_STEP_DOCUMENTS_LOADING, false);
  },
  /**
   * Get all steps, create if not exist
   * @param commit
   * @param businessSlug
   */
  async getOrCreateSteps({ commit }, businessSlug) {
    const url = `/api/v1/business-client-steps/${businessSlug}/franchisee-steps`;
    try {
      const { data } = await this.$axios.get(url);

      commit(SET_BUSINESS_CLIENT_STEPS, data);
      commit(SET_IN_PROGRESS_BUSINESS_CLIENT_STEP, data);
    } catch (e) {
      console.log(e);
    }
  },
};
