export const state = () => ({
  seoData: [
    {
      "title": "Franchises for Sale, Opportunities & Insights",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Franchise123 is your ultimate hub for A to Z franchising from franchise opportunities, information, and exclusive insights to available franchises for sale."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Franchises for Sale, Opportunities & Insights | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Franchise123 is your ultimate hub for A to Z franchising from franchise opportunities, information, and exclusive insights to available franchises for sale."
        }
      ],
      "url": "/"
    },
    {
      "title": "Disclaimer",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Learn about the disclaimers set forth by Franchise123 on the information and franchising opportunities found on the website."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Disclaimer | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Learn about the disclaimers set forth by Franchise123 on the information and franchising opportunities found on the website."
        }
      ],
      "url": "/disclaimer"
    },
    {
      "title": "Terms of Use",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Understanding the terms and conditions of a website is important before you sign up. Learn about Franchise123's terms of use."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Terms of Use | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Understanding the terms and conditions of a website is important before you sign up. Learn about Franchise123's terms of use."
        }
      ],
      "url": "/terms"
    },
    {
      "title": "Privacy Policy",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Concerned about the safety of the data you submit to Franchise123? Learn about our privacy policy and how we protect your details."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Privacy Policy | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Concerned about the safety of the data you submit to Franchise123? Learn about our privacy policy and how we protect your details."
        }
      ],
      "url": "/privacy-policy"
    },
    {
      "title": "Franchising Industry Reports",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Read the latest franchising industry reports with exclusive research, analysis and information on all types of opportunities spanning across 38 industries."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Franchising Industry Reports | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Read the latest franchising industry reports with exclusive research, analysis and information on all types of opportunities spanning across 38 industries."
        }
      ],
      "url": "/industry-reports"
    },
    {
      "title": "Franchise Business Glossary",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Unsure about common terms related to franchising? Check out our franchise glossary with must-know terms and definitions for franchisees."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Franchise Business Glossary | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Unsure about common terms related to franchising? Check out our franchise glossary with must-know terms and definitions for franchisees."
        }
      ],
      "url": "/franchise-glossary-definitions"
    },
    {
      "title": "Franchising FAQ",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Do you have a lot of questions related to franchising but not sure where to get the right answers? Take a look at the most common franchising FAQs."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "Franchising FAQ | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Do you have a lot of questions related to franchising but not sure where to get the right answers? Take a look at the most common franchising FAQs."
        }
      ],
      "url": "/franchising-faqs"
    },
    {
      "title": "About",
      "meta": [
        {
          "hid": "description",
          "property": "description",
          "content": "Franchise123 exhibits you with tons of franchising opportunities. Learn more about Franchise123 before you opt for any of the franchises we offer."
        },
        {
          "hid": "og:type",
          "property": "og:type",
          "content": "website"
        },
        {
          "hid": "og:title",
          "property": "og:title",
          "content": "About | Franchise123"
        },
        {
          "hid": "og:url",
          "property": "og:url",
          "content": process.env.baseUrl
        },
        {
          "hid": "og:description",
          "property": "og:description",
          "content": "Franchise123 exhibits you with tons of franchising opportunities. Learn more about Franchise123 before you opt for any of the franchises we offer."
        }
      ],
      "url": "/about"
    }
  ],
  headInfo: []
});

export const getters = {
  seoData: state => state.seoData
}

export const mutations = {
  SET_HEAD_INFO (state, payload) {
    state.headInfo = payload;
  }
}
