import * as types from './mutation-types'

// helpers
// convert slug to title
function makeTitle(slug) {
  if (!slug) {
    return '';
  }

  const words = slug.split('-');

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

// state
export const state = () => ({
  url: {
    main: '/api/v1/blog',
    category: '/api/v1/blog/category/',
    seo: '/api/v1/seo/'
  },
  articlesArr: [],
  articlesRecentArr: [],
  articleObj: {},
  categoryListArr: [],
  categoryObj: {
    title: null
  },
  seoAttributes: {
    title: '',
    description: '',
    keywords: ''
  },
  paginationObj: {
    page: 1,
    per_page: 12,
    current_page: 1,
    total: 0
  },
  search: '',
  filterByCategory: null,
  recentPostCount: 3,

  busy: false
})

// getters
export const getters = {
  articlesArr: state => state.articlesArr,
  articlesRecentArr: state => state.articlesRecentArr,
  articleObj: state => state.articleObj,
  categoryListArr: state => state.categoryListArr,
  categoryObj: state => state.categoryObj,
  seoAttributes: state => state.seoAttributes,
  paginationObj: state => state.paginationObj,
  busy: state => state.busy,
}

// mutations
export const mutations = {
  [types.BLOG_ARTICLE_FETCH_SUCCESS](state, data) {
    state.articleObj = data;
  },
  [types.BLOG_ARTICLE_FETCH_FAILURE](state) {
    state.articleObj = {}
  },
  [types.BLOG_ARTICLE_LIST_FETCH_SUCCESS](state, data) {
    state.articlesArr = data;
  },
  [types.BLOG_ARTICLE_LIST_FETCH_FAILURE](state) {
    state.articlesArr = []
  },
  [types.BLOG_ARTICLE_RECENT_FETCH_SUCCESS](state, data) {
    state.articlesRecentArr = data;
  },
  [types.BLOG_ARTICLE_RECENT_FETCH_FAILURE](state) {
    state.articlesRecentArr = []
  },
  [types.BLOG_CATEGORY_LIST_FETCH_SUCCESS](state, data) {
    state.categoryListArr = data;
  },
  [types.BLOG_CATEGORY_LIST_FETCH_FAILURE](state) {
    state.categoryListArr = [];
  },
  [types.SET_CATEGORY](state, data) {
    state.categoryObj = data;
  },
  [types.SET_SEO_ATTRIBUTES](state, data) {
    state.seoAttributes = data;
  },
  [types.BLOG_SET_SEARCH](state, word) {
    state.search = word
  },
  [types.BLOG_SET_CATEGORY](state, category) {
    state.filterByCategory = category
  },
  [types.BLOG_SET_PAGINATION](state, pagination) {
    state.paginationObj = Object.assign(state.paginationObj, pagination)
  },
  [types.SET_STATUS_FETCH_DATA](state, status) {
    state.busy = status
  },
}

// actions
export const actions = {
  async fetchArticlesList({commit, state}) {
    const params = {
      page: state.paginationObj.page,
      limit: state.paginationObj.per_page,
      category: state.filterByCategory,
    };

    if (state.search)
      params.search = state.search

    try {
      const {data} = await this.$axios.get(state.url.main, {params});

      const pagination = {
        total: data.total,
        current_page: data.current_page
      }
      commit(types.BLOG_ARTICLE_LIST_FETCH_SUCCESS, data.data);
      commit(types.BLOG_SET_PAGINATION, pagination);
    } catch (e) {
      console.log(e);
      commit(types.BLOG_ARTICLE_LIST_FETCH_FAILURE)
    }
  },
  async fetchRecentArticles({commit, state}, limit) {
    const params = {
      limit: limit ?? state.recentPostCount
    };

    try {
      const {data} = await this.$axios.get(state.url.main, {params});

      commit(types.BLOG_ARTICLE_RECENT_FETCH_SUCCESS, data.data);
    } catch (e) {
      console.log(e);
      commit(types.BLOG_ARTICLE_RECENT_FETCH_FAILURE)
    }
  },
  async fetchCategoryList({commit, state}) {
    try {
      const {data} = await this.$axios.get(state.url.category);
      let currentCategory = {
        title: null
      }

      if (state.filterByCategory !== null) {
        currentCategory = data.filter(category => category.slug === state.filterByCategory).shift();
      }

      commit(types.SET_CATEGORY, currentCategory);
      commit(types.BLOG_CATEGORY_LIST_FETCH_SUCCESS, data);
    } catch (e) {
      console.log(e);
      commit(types.BLOG_CATEGORY_LIST_FETCH_FAILURE)
    }
  },
  async fetchArticle({commit, state}, slug) {
    try {
      const {data} = await this.$axios.get(`/api/v1/blog/show/${slug}`)
      commit(types.BLOG_ARTICLE_FETCH_SUCCESS, data)
      commit(types.SET_SEO_ATTRIBUTES, data.seo)
    } catch (e) {
      console.log(e);
      commit(types.BLOG_ARTICLE_FETCH_FAILURE)
    }
  },
  async getSeoAttributes({commit, state}, item) {
    let slug;
    let id
    if (item.type === 'post') {
      slug = item.slug.split('-')
      id = slug[slug.length - 1]
    }
    if (item.type === 'category') {
      id = item.slug;
    }

    const seoAttributes = {
      title: makeTitle('Blog'),
      description: `Blog posts - ${makeTitle('Main')}`
    }
    const url = `/api/v1/seo/${item.type}/${id}`

    try {
      const {data} = await this.$axios.get(url);

      if (data) {
        seoAttributes.title = data.title;
        seoAttributes.description = data.description;
        seoAttributes.keywords = data.keywords;
      }
    } catch (e) {
      console.log(e);
    }

    commit(types.SET_SEO_ATTRIBUTES, seoAttributes)
  }

}
