import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

export default ({store}, inject) => {
  const token = store.getters['auth/token']
  const options = {
    broadcaster: 'pusher',
    key: process.env.PUSHER_APP_KEY,
    encrypted: true,
    cluster: process.env.PUSHER_APP_CLUSTER,
  }
  if (token) {
    options.auth = {
      authEndpoint: `${process.env.BACKEND_BASE_URL}/broadcasting/auth`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }
  const echo = new Echo(options)
  inject('echo', echo);
};
