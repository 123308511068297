import * as types from './mutation-types'

// state
export const state = () => ({
  states: [],
  industries: [],
  tags: [],
  busyFetchIndustry: false,
  busyFetchStates: false,
  busyFetchTags: false,
  restoreIndustryFilter: true,
})

// getters
export const getters = {
  states: state => state.states,
  statesOptions: state => {
    const options = []
    state.states.forEach(el => {
      options.push({
        title: `${el.name}, ${el.code}`,
        value: el.id
      })
    })
    return options
  },
  industries: state => state.industries,
  tags: state => state.tags,
  busyFetchIndustry: state => state.busyFetchIndustry,
  busyFetchStates: state => state.busyFetchStates,
  busyFetchTags: state => state.busyFetchTags,
  restoreIndustryFilter: state => state.restoreIndustryFilter,
}

// mutations
export const mutations = {
  [types.FETCH_DIRECTORY_STATES_SUCCESS](state, {states}) {
    state.states = states
  },
  [types.FETCH_DIRECTORY_STATES_FAILURE](state) {
    state.states = []
  },
  [types.FETCH_DIRECTORY_INDUSTRIES_SUCCESS](state, {industries}) {
    state.industries = industries
  },
  [types.FETCH_DIRECTORY_INDUSTRIES_FAILURE](state) {
    state.industries = []
  },
  [types.FETCH_DIRECTORY_TAGS_SUCCESS](state, {tags}) {
    state.tags = tags
  },
  [types.FETCH_DIRECTORY_TAGS_FAILURE](state) {
    state.tags = []
  },
  [types.CHANGE_STATUS_FETCH_INDUSTRY](state, status) {
    state.busyFetchIndustry = status
  },
  [types.CHANGE_STATUS_FETCH_STATES](state, status) {
    state.busyFetchStates = status
  },
  [types.CHANGE_STATUS_FETCH_TAGS](state, status) {
    state.busyFetchTags = status
  },
  [types.SET_RESTORE_INDUSTRY_FILTER](state, status) {
    state.restoreIndustryFilter = status
  },
}

// actions
export const actions = {
  async fetchStates({commit, state}) {
    if (state.states.length > 0)
      return

    commit(types.CHANGE_STATUS_FETCH_STATES, true)
    try {
      const {data} = await this.$axios.get('/api/v1/directories/states')

      commit(types.FETCH_DIRECTORY_STATES_SUCCESS, {states: data})
    } catch (e) {
      commit(types.FETCH_DIRECTORY_STATES_FAILURE)
    }
    commit(types.CHANGE_STATUS_FETCH_STATES, false)
  },
  async fetchIndustries({commit, state}) {
    if (state.industries.length > 0)
      return

    commit(types.CHANGE_STATUS_FETCH_INDUSTRY, true)
    try {
      const {data} = await this.$axios.get('/api/v1/industry/get')

      commit(types.FETCH_DIRECTORY_INDUSTRIES_SUCCESS, {industries: data})
    } catch (e) {
      commit(types.FETCH_DIRECTORY_INDUSTRIES_FAILURE)
    }
    commit(types.CHANGE_STATUS_FETCH_INDUSTRY, false)

  },
  async fetchTags({commit, state}) {
    if (state.tags.length > 0)
      return

    commit(types.CHANGE_STATUS_FETCH_TAGS, true)
    try {
      const {data} = await this.$axios.get('/api/v1/tag/get')
      commit(types.FETCH_DIRECTORY_TAGS_SUCCESS, {tags: data})
    } catch (e) {
      commit(types.FETCH_DIRECTORY_TAGS_FAILURE)
    }
    commit(types.CHANGE_STATUS_FETCH_TAGS, false)
  },
  setRestoreIndustryFilter({commit}, status) {
    commit(types.SET_RESTORE_INDUSTRY_FILTER, status)
  }
}
