import * as types from './mutation-types'

export const state = () => ({
})

export const getters = {
}

export const mutations = {
}

export const actions = {
  async addNewLead({commit, state}, {leadForm, franchiseId}) {
    const url = `/api/v2/manage-leads/lead/${franchiseId}`
    try {
      await leadForm.post(url)
    } catch (e) {
      console.log(e)
    }
  }
}
