import * as types from "../mutation-types"

export const state = () => ({
  list: {},
  selected: {},
  info: {},
  contactList: [],
  busy: true,
})

export const getters = {
  listPagination: state => state.list,
  contactList: state => state.contactList,
  list: state => state.list.data,
  selected: state => state.selected,
  info: state => state.info,
  busy: state => state.busy,
}

export const mutations = {
  [types.FETCH_CONTACTS_LIST_SUCCESS](state, data) {
    state.list = data
  },
  [types.FETCH_CONTACTS_LIST_FAILURE](state) {
    state.list = {}
  },
  [types.UPDATE_CONTACT_LIST_ALL](state, data) {
    state.contactList = data
  },
  [types.FETCH_CONTACTS_SELECTED_SUCCESS](state, data) {
    state.selected = data
  },
  [types.FETCH_CONTACTS_SELECTED_FAILURE](state) {
    state.selected = {}
  },

  [types.SET_CONTACTS_BUSY](state, status) {
    state.busy = status
  },
  [types.UPDATE_CONTACT_INFO](state, data) {
    state.info = data
  },
}

export const actions = {
  async fetchContactsListAll({commit, rootState}) {
    commit(types.SET_CONTACTS_BUSY, true)
    try {
      const url = `/api/v2/business-client-steps/contacts-list`
      const params = {
        franchise_id: rootState['business-v2'].franchise?.id ?? null
      }
      const {data} = await this.$axios.get(url, {params})
      commit(types.UPDATE_CONTACT_LIST_ALL, data.result)
    } catch (e) {
      console.log(e)
    }
    commit(types.SET_CONTACTS_BUSY, false)
  },
  async fetchContactsList({commit, rootState}, payload) {
    commit(types.SET_CONTACTS_BUSY, true)
    const search = payload?.search
    const activeStep = payload?.activeStep
    const filter = payload?.filter
    const preferences = payload?.preferences
    const page = payload?.page ?? 1
    const franchiseId = rootState['business-v2'].franchise?.id ?? null

    let url = `/api/v2/business-client-steps/contacts?page=${page}`
    if (search) url = `${url}&search=${search}`
    if (activeStep) {
      activeStep.forEach(step => {
        url = `${url}&active_step[]=${step}`
      })
    }
    if (filter) {
      filter.forEach(option => {
        url = `${url}&${option}=true`
      })
    }
    if (preferences) {
      Object.keys(preferences).forEach(key => {
        if (preferences[key]) {
          url = `${url}&preferences[${key}]=${preferences[key]}`
        }
      })
    }
    if (franchiseId) url = `${url}&franchise_id=${franchiseId}`

    try {
      const {data} = await this.$axios.get(url)
      commit(types.FETCH_CONTACTS_LIST_SUCCESS, data.result)
    } catch (e) {
      console.log(e)
      commit(types.FETCH_CONTACTS_LIST_FAILURE)
    }
    commit(types.SET_CONTACTS_BUSY, false)
  },
  async fetchContactsSelected({commit, rootState}, payload) {
    commit(types.SET_CONTACTS_BUSY, true)
    const franchiseeId = payload.franchisee_id
    const franchiseId = rootState['business-v2'].franchise?.id ?? null
    let url = `/api/v2/business-client-steps/contacts?franchisee_id=${franchiseeId}`
    if (franchiseId) url = `${url}&franchise_id=${franchiseId}`

    try {
      const {data} = await this.$axios.get(url)
      commit(types.FETCH_CONTACTS_SELECTED_SUCCESS, data.result.data[0])
    } catch (e) {
      console.log(e)
      commit(types.FETCH_CONTACTS_SELECTED_FAILURE)
    }
    commit(types.SET_CONTACTS_BUSY, false)
  },
  async fetchCurrentContact({commit}, {franchiseId, userFranchiseeId}) {
    try {
      const url = `/api/v2/contact/${franchiseId}/${userFranchiseeId}/get`
      const {data} = await this.$axios.get(url)

      commit(types.UPDATE_CONTACT_INFO, data)
    } catch (e) {
      console.log(e)
    }
  }
}
