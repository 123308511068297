/* eslint-disable no-param-reassign */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as types from './mutation-types';

// state
export const state = () => ({
  subtypes: [],
  fetchSubtypesError: null,
});

// getters
export const getters = {
  subtypes: (state) => state.subtypes,
};

// mutations
export const mutations = {
  [types.SET_DOCUMENT_SUBTYPES](state, subtypes) {
    state.subtypes = subtypes;
  },
  [types.SET_DOCUMENT_FETCH_SUBTYPES_ERROR](state, error) {
    state.fetchSubtypesError = error;
  },
};

// actions
export const actions = {
  async fetchSubtypes({ commit }) {
    try {
      const { data } = await this.$axios.get('/api/v1/business-step-docs/subtypes');
      commit(types.SET_DOCUMENT_SUBTYPES, data);
    } catch (err) {
      commit(types.SET_DOCUMENT_FETCH_SUBTYPES_ERROR, err);
    }
  },
};
