import Cookies from 'js-cookie'

export default async ({store, $axios, redirect}) => {
	if (!process.client)
		return

  store.commit('auth/UPDATE_STATUS_MOBILE', true)

	const cookieToken = Cookies.get('token') ? Cookies.get('token') : localStorage.userToken
	const cookieRemember = Cookies.get('remember') ? Cookies.get('remember') : localStorage.userRemember
	const cookieExpires = Cookies.get('expires') ? Cookies.get('expires') : localStorage.userExpires
	const authCheck = store.getters['auth/check']

	if (cookieToken)
		$axios.setToken(cookieToken, 'Bearer')


	if (!authCheck && cookieToken) {
    store.dispatch('auth/fetchUser')

    store.commit('auth/SAVE_TOKEN', {
      token: cookieToken,
      expires: cookieExpires,
      remember: cookieRemember
    })
  }
}
