// franchising_directory.vue
export const ITEMS_STATE_CARD = 'ITEMS_STATE_CARD'
export const ITEMS_STATE_TABLE = 'ITEMS_STATE_TABLE'
export const ITEMS_STATES = [
  ITEMS_STATE_CARD,
  ITEMS_STATE_TABLE,
]

// env type
export const ENV_TYPE = {
  'local': 'local',
  'staging': 'staging',
  'production': 'production',
}

// contact actions menu
export const CONTACT_ACTIONS_MENU = {
  activity: {
    id: `activity`,
    title: `Activity`,
    status: false,
  },
  tasks: {
    id: `tasks`,
    title: `Tasks`,
    status: true,
  },
  notes: {
    id: `notes`,
    title: `Notes`,
    status: true,
  },
  emails: {
    id: `emails`,
    title: `Emails`,
    status: false,
  },
  history: {
    id: `history`,
    title: `History`,
    status: true,
  },
  calls: {
    id: `calls`,
    title: `Calls`,
    status: true,
  },
  meetings: {
    id: `meetings`,
    title: `Meetings`,
    status: false,
  },
}

// mob os
export const MOB_OS_IOS = 'iOS'
export const MOB_OS_ANDROID = 'Android'
export const MOB_OS_UNKNOWN = 'unknown'

// payment
export const PAYMENT_MEMBERSHIP_LIST = {
  'Platinum': 'Platinum',
  'Gold': 'Gold',
  'Business': 'Business',
  'Later': 'Later',
}
export const PAYMENT_MEMBERSHIP_WITH_CHAT = [
  'Gold',
  'Platinum',
  'Business',
]
export const PAYMENT_MEMBERSHIP_WITH_EDIT_PAGES = [
  'Gold',
  'Platinum',
]

// registration
export const REGISTRATION_STAGE_FRANCHISEE_PREVIEW = 'franchisee-preview'
export const REGISTRATION_STAGE_FRANCHISOR_PREVIEW = 'franchisor-preview'
export const REGISTRATION_STAGE_SELECT_USER_TYPE = 'user-type'
export const REGISTRATION_STAGE_FORM = 'form'
export const REGISTRATION_STAGE_PREFERENCES = 'preferences'
export const REGISTRATION_STAGE_STEPPER = 'stepper'
export const REGISTRATION_STAGE_STEPPER_CONGRATULATIONS = 'stepper-congratulations'
export const REGISTRATION_STAGE_CONGRATULATIONS = 'congratulations'
export const REGISTRATION_DEFAULT_FIRST_NAME = 'USER'
export const REGISTRATION_DEFAULT_LAST_NAME = 'USER'
export const REGISTRATION_DEFAULT_PASSWORD = 'franchise123'

export const USER_PREFERENCES_TOTAL_AMOUNT_INVEST = [
  {value: 1, title: 'Up to 10K', min: 0, max: 10000},
  {value: 2, title: 'Up to 50K', min: 0, max: 50000},
  {value: 3, title: 'Up to 100K', min: 0, max: 100000},
  {value: 4, title: 'Up to 200K', min: 0, max: 200000},
  {value: 5, title: 'Up to 300K', min: 0, max: 300000},
  {value: 6, title: 'Any', min: null, max: null},
]


export const ASSOCIATIONS_AND_PARTNERSHIPS = [
  {
    value: 'International Franchise Association (IFA)',
    title: 'International Franchise Association (IFA)',
    slug: 'international-franchise-association',
  },
  {
    value: 'American Association of Franchises & Dealers',
    title: 'American Association of Franchises & Dealers',
    slug: 'american-association-of-franchises-and-dealers',
  },
  {
    value: 'Institute of Certified Franchise Executives (ICFE)',
    title: 'Institute of Certified Franchise Executives (ICFE)',
    slug: 'institute-of-certified-franchise-executives',
  },
]

export const USER_PREFERENCES_STATE = [
  {
    id: 1,
    name: 'Alabama',
    code: 'AL',
  },
  {
    id: 2,
    name: 'Alaska',
    code: 'AK',
  },
  {
    id: 3,
    name: 'Arizona',
    code: 'AZ',
  },
  {
    id: 4,
    name: 'Arkansas',
    code: 'AR',
  },
  {
    id: 5,
    name: 'California',
    code: 'CA',
  },
  {
    id: 6,
    name: 'Colorado',
    code: 'CO',
  },
  {
    id: 7,
    name: 'Connecticut',
    code: 'CT',
  },
  {
    id: 8,
    name: 'Delaware',
    code: 'DE',
  },
  {
    id: 10,
    name: 'Florida',
    code: 'FL',
  },
  {
    id: 11,
    name: 'Georgia',
    code: 'GA',
  },
  {
    id: 12,
    name: 'Hawaii',
    code: 'HI',
  },
  {
    id: 13,
    name: 'Idaho',
    code: 'ID',
  },
  {
    id: 14,
    name: 'Illinois',
    code: 'IL',
  },
  {
    id: 15,
    name: 'Indiana',
    code: 'IN',
  },
  {
    id: 16,
    name: 'Iowa',
    code: 'IA',
  },
  {
    id: 17,
    name: 'Kansas',
    code: 'KS',
  },
  {
    id: 18,
    name: 'Kentucky',
    code: 'KY',
  },
  {
    id: 19,
    name: 'Louisiana',
    code: 'LA',
  },
  {
    id: 20,
    name: 'Maine',
    code: 'ME',
  },
  {
    id: 21,
    name: 'Maryland',
    code: 'MD',
  },
  {
    id: 22,
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    id: 23,
    name: 'Michigan',
    code: 'MI',
  },
  {
    id: 24,
    name: 'Minnesota',
    code: 'MN',
  },
  {
    id: 25,
    name: 'Mississippi',
    code: 'MS',
  },
  {
    id: 26,
    name: 'Missouri',
    code: 'MO',
  },
  {
    id: 27,
    name: 'Montana',
    code: 'MT',
  },
  {
    id: 28,
    name: 'Nebraska',
    code: 'NE',
  },
  {
    id: 29,
    name: 'Nevada',
    code: 'NV',
  },
  {
    id: 30,
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    id: 31,
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    id: 32,
    name: 'New Mexico',
    code: 'NM',
  },
  {
    id: 33,
    name: 'New York',
    code: 'NY',
  },
  {
    id: 34,
    name: 'North Carolina',
    code: 'NC',
  },
  {
    id: 35,
    name: 'North Dakota',
    code: 'ND',
  },
  {
    id: 36,
    name: 'Ohio',
    code: 'OH',
  },
  {
    id: 37,
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    id: 38,
    name: 'Oregon',
    code: 'OR',
  },
  {
    id: 39,
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    id: 40,
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    id: 41,
    name: 'South Carolina',
    code: 'SC',
  },
  {
    id: 42,
    name: 'South Dakota',
    code: 'SD',
  },
  {
    id: 43,
    name: 'Tennessee',
    code: 'TN',
  },
  {
    id: 44,
    name: 'Texas',
    code: 'TX',
  },
  {
    id: 45,
    name: 'Utah',
    code: 'UT',
  },
  {
    id: 46,
    name: 'Vermont',
    code: 'VT',
  },
  {
    id: 47,
    name: 'Virginia',
    code: 'VA',
  },
  {
    id: 48,
    name: 'Washington',
    code: 'WA',
  },
  {
    id: 49,
    name: 'West Virginia',
    code: 'WV',
  },
  {
    id: 50,
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    id: 51,
    name: 'Wyoming',
    code: 'WY',
  },
  {
    id: 52,
    name: 'Empty',
    code: 'E',
  },
]

export const USER_TYPE_FRANCHISEE = 1
export const USER_TYPE_FRANCHISOR = 2
export const USER_TYPE_MASTER_ADMIN = 100

export const USER_TYPE_FRANCHISEE_TITLE = 'franchisee'
export const USER_TYPE_FRANCHISOR_TITLE = 'franchisor'

export const USER_TYPES = [
  {value: USER_TYPE_FRANCHISOR, title: 'Franchisor'},
  {value: USER_TYPE_FRANCHISEE, title: 'Franchisee'},
  {value: USER_TYPE_MASTER_ADMIN, title: 'Master Admin'},
]

export const USER_PLATFORM_SOURCE = {
  'web': 0,
  'ios': 1,
  'android': 2,
}

export const SIGN_UP_FRANCHISE_FAST_STEP_ONE = 0
export const SIGN_UP_FRANCHISE_FAST_STEP_TWO = 1
export const SIGN_UP_FRANCHISE_FAST_STEP_THREE = 2
export const SIGN_UP_FRANCHISE_FAST_STEP_FOUR = 3

export const FRANCHISOR_FLOW_OPTIONS = {
  add: 0,
  claim: 1,
}

// franchise directory event types
export const EVENT_LIKE = 'event-like'
export const EVENT_DOWNLOAD_FDD = 'event-download-fdd'
export const EVENT_COMPARE = 'event-compare'

// hubspot event types
export const HUBSPOT_EVENT_TYPES = {
  openFullFDD: 'open-full-fdd',
  openItem7: 'open-item-7',
  openItem19: 'open-item-19',
  openFranchiseesList: 'open-franchisees-list',
  openPresentation: 'open-presentation',
}

// closed form types
export const CLOSED_FORM_TYPES = {
  signUpFranchisee: 'sign-up-franchisee',
  signUpFranchisor: 'sign-up-franchisor',
}

// franchise categoryes
export const TOP_FITNESS = 'fitness'
export const TOP_PET = 'pet'
export const TOP_CLEANING = 'cleaning'
export const TOP_RESTAURANT = 'restaurant'
export const TOP_HEALTH_BEAUTY = 'health-beauty'
export const TOP_HOME_SERVICES = 'home-services'

export const TOP_FRANCHISE_CATEGORYES = [
  {
    name: TOP_FITNESS,
    title: 'Top Fitness Franchises',
    background_sm: require('~/assets/images/top-industry/top-fitness.jpg'),
    background: require('~/assets/images/top-industry/top-fitness-bg.jpg'),
    heroDescription: `Thanks to an increasing public interest in leading a balanced, healthy life, the $81 billion fitness franchise industry continues to grow. Whether it’s a specialized boutique studio or a budget-conscious big box gym, there is something out there for everyone. <br> <br>
                      With dozens of fitness franchise concepts currently in expansion mode, it can be hard to know which one to invest in. We’ve put together a list of the twelve most popular fitness franchise opportunities from our database to help you begin your search.`,
    idData: [3007, 2867, 3121, 2889, 3988, 890, 954, 112, 397, 1420, 1858, 2795],
    pageTitle: 'Top Fitness Franchises For Sale',
    pageDescription:
      'The fitness industry continues to grow, especially in the post-COVID era. Here are the top 12 fitness franchise opportunities to potentially invest in.',
  },
  {
    name: TOP_PET,
    title: 'Top Pet Franchises',
    background_sm: require('~/assets/images/top-industry/top-pet.jpg'),
    background: require('~/assets/images/top-industry/top-pet.jpg'),
    heroDescription: `If you are an animal lover and looking to make a career out of your passion, look no further! Pet franchises cover many different services and products needed by pet owners, such as grooming, boarding, training, food, treats, and supplies.<br> <br>
                      The pet industry is a recession-resistant, booming industry. Pets are part of the family, and pet owners are willing to pay top dollar to ensure they receive the best care possible. Franchising with one of these twelve most popular pet franchises is a win-win scenario.`,
    idData: [3810, 461, 546, 3405, 2593, 2356, 1660, 962, 2955, 1257, 3572, 3004],
    pageTitle: 'Top Pet Franchises',
    pageDescription:
      'Explore our list of top food franchises of 2021 that can be started with low initial investment but with a good profit return. Get started today!',
  },
  {
    name: TOP_CLEANING,
    title: 'Cleaning Franchises with Lowest Initial Investment',
    background_sm: require('~/assets/images/top-industry/top-cleaning.jpg'),
    background: require('~/assets/images/top-industry/top-cleaning-bg.jpg'),
    heroDescription: `The cleaning industry includes everything from home and office janitorial services to disaster restoration services. Cleaning franchises are a great franchise opportunity because they often don’t require physical brick-and-mortar shops, reducing overhead costs. Post-COVID, many businesses will rely heavily on professional cleaning services to maintain the levels of safety and cleanliness expected by their customers. Cleaning franchises are facing a time of massive growth and offer a promising return on investment for franchisees. Here are our selections for the top 12 cleaning franchises on the market.`,
    industry: 'Cleaning Franchises',
    pageTitle: 'Top Cleaning Business For Sale',
    pageDescription:
      'Cleaning businesses are seeing massive growth and offer a promising ROI for franchisees. Explore our list of top cleaning franchises on the market.',
  },
  {
    name: TOP_RESTAURANT,
    title: 'Restaurant Franchises with Lowest Initial Investment',
    background_sm: require('~/assets/images/top-industry/top-restaurant.jpg'),
    background: require('~/assets/images/top-industry/top-restaurant-bg.jpg'),
    heroDescription:
      'Restaurants are facing a difficult time right now in light of the COVID-19 pandemic, but once restaurant dining rooms can safely open, they’ll offer a much needed social escape for people that have been cooped up for so many months. In the meantime, take out orders and delivery services are keeping the restaurant industry busy. We’ve taken a look at many different restaurant franchises to determine which ones are best poised for success post-COVID, and here are our top twelve selections. ',
    industry: 'Restaurant Franchises',
    pageTitle: 'Top Restaurant Franchises For Sale',
    pageDescription:
      'Since the dine-in got allowed post-COVID, restaurants are booming. Explore our hand-picked list of the top restaurant franchises to invest in and run.',
  },
  {
    name: TOP_HEALTH_BEAUTY,
    title: 'Health & Beauty Franchises with Lowest Initial Investment',
    background_sm: require('~/assets/images/top-industry/top-health-beauty-bg.jpg'),
    background: require('~/assets/images/top-industry/top-health-beauty-bg.jpg'),
    heroDescription:
      'The health and beauty industry encompasses everything from lash salons to senior care companies. Passion plays a huge role in which type of health and beauty franchise concept is best for you, especially in such a diverse industry. With that being taken into consideration, we’ve put together a list of top twelve health and beauty franchises based on initial investment. You can use this list to help you narrow down which franchises might be right for you.',
    industry: 'Health & Beauty Franchises',
    pageTitle: 'Top Health & Beauty Franchises For Sale',
    pageDescription:
      'Looking for a health or beauty franchise to invest in? We have done all the hard work. Browse our top health & beauty franchises list for 2021. Start now!',
  },
  {
    name: TOP_HOME_SERVICES,
    title: 'Top Home Services Franchises',
    background_sm: require('~/assets/images/top-industry/top-home-service.jpg'),
    background: require('~/assets/images/top-industry/top-home-service-bg.jpg'),
    heroDescription: `There’s no place like home, and proper home maintenance is always in high demand. The home services industry is a broad and competitive market for all kinds of professionals, from floor installation to pest control and plumbing. <br> <br>
                      According to Verified Market Research, Home Services made up a $281 billion industry in 2018, with substantial growth predicted over the next several years. With so much earning potential and a wide array of service types to choose from, there is plenty of opportunity in this industry for prospective franchise owners.`,
    idData: [3547, 733, 2182, 1050, 3691, 1731, 891, 664, 3075, 1965, 2569, 478],
    pageTitle: 'Top Home Services Franchises',
    pageDescription: 'Home services franchises are a favorite of many with opportunities from dry cleaning to mobile repair. Explore the best service franchise you can invest in!',
  },
]

// franchisor-steps
export const CHAT_CONTACT_STATUS_ONLINE = 'online'
export const CHAT_CONTACT_STATUS_OFFLINE = 'offline'

// franchisee steps statuses
export const STATUS_TODO = 'todo'
export const STATUS_IN_PROGRESS = 'in_progress'
export const STATUS_DONE = 'done'

export const LANDING_FONT_LIST = [
  'Chunkfive',
  'Lato',
  'Lato Black',
  'Abel',
  'Abril Fatface',
  'Acme',
  'Amatic SC',
  'Antic Slab',
  'Anton',
  'Architects Daughter',
  'Archivo Narrow',
  'Arimo',
  'Arvo',
  'Asap',
  'Assistant',
  'Balsamiq Sans',
  'Barlow',
  'Barlow Condensed',
  'Bebas Neue',
  'Bitter',
  'Bree Serif',
  'Cabin',
  'Cairo',
  'Catamaran',
  'Comfortaa',
  'Cormorant Garamond',
  'Crimson Text',
  'Century Gothic Std Bold',
  'Dancing Script',
  'Domine',
  'Dosis',
  'EB Garamond',
  'Exo',
  'Exo 2',
  'Fira Sans',
  'Fjalla One',
  'Frank Ruhl Libre',
  'Fredoka One',
  'Gotham A',
  'Heebo',
  'Hind',
  'Hind Madurai',
  'Hind Siliguri',
  'IBM Plex Sans',
  'IBM Plex Serif',
  'Inconsolata',
  'Indie Flower',
  'Inter',
  'Josefin Sans',
  'Kanit',
  'Karla',
  'Libre Baskerville',
  'Libre Franklin',
  'Lobster',
  'Lora',
  'Martel',
  'Merriweather',
  'Merriweather Sans',
  'Montserrat',
  'Mukta',
  'Myriad Pro',
  'Nanum Gothic',
  'Noto Sans',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans SC',
  'Noto Sans TC',
  'Noto Serif JP',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'Open Sans Condensed',
  'Oswald',
  'Overpass',
  'Oxygen',
  'Pacifico',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Prompt',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Questrial',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Russo One',
  'Shadows Into Light',
  'Signika',
  'Slabo 27px',
  'Source Code Pro',
  'Source Sans Pro',
  'Source Serif Pro',
  'Teko',
  'Titillium Web',
  'Ubuntu',
  'Varela Round',
  'Work Sans',
  'Yanone Kaffeesatz',
  'Zilla Slab',
]

export const FONT_STYLES = [
  'italic',
  'normal',
  'oblique',
]

export const FONT_WEIGHTS = [
  'bold',
  'bolder',
  'lighter',
  'normal',
]

// Business client step documents types
export const DOCUMENT_TYPE_DEFAULT = 'file'
export const DOCUMENT_TYPE_PRESENTATION = 'presentation' // deprecated
export const DOCUMENT_TYPE_VIDEO = 'video'
export const DOCUMENT_TYPE_LINK = 'link'

export const DOCUMENT_SUBTYPE_DEFAULT = 'none'

// search
export const SEARCH_TYPE_FRANCHISEE = 'franchisee'
export const SEARCH_TYPE_FRANCHISOR = 'franchisor'
export const SEARCH_OPTIONS_INVESTMENT = [
  {
    title: 'up to $10K',
    value: 1000,
  },
  {
    title: 'up to $20K',
    value: 20000,
  },
  {
    title: 'up to $30K',
    value: 30000,
  },
  {
    title: 'up to $40K',
    value: 40000,
  },
  {
    title: 'up to $50K',
    value: 50000,
  },
  {
    title: 'up to $60K',
    value: 60000,
  },
  {
    title: 'up to $70K',
    value: 70000,
  },
  {
    title: 'up to $80K',
    value: 80000,
  },
  {
    title: 'up to $90K',
    value: 90000,
  },
  {
    title: 'up to $100K',
    value: 100000,
  },
  {
    title: 'up to $200K',
    value: 200000,
  },
  {
    title: 'up to $300K',
    value: 300000,
  },
  {
    title: 'up to $400K',
    value: 400000,
  },
  {
    title: 'up to $500K',
    value: 500000,
  },
  {
    title: 'up to $1mln',
    value: 1000000,
  },
  {
    title: 'over $1 mln',
    value: 10000000000,
  },
]

// modal id`s
export const MODAL_SIGN_UP_FRANCHISOR_ID = 'modal-sign-up-franchisor-id'
export const MODAL_SIGN_UP_FRANCHISE_ID = 'modal-sign-up-franchise-id'
export const MODAL_PREFERENCE_FRANCHISEE_ID = 'modal-preference-franchise-id'
export const MODAL_CHANGE_EMAIL_ID = 'modal-change-email-id'
export const MODAL_CONFIRM_CHANGE_EMAIL_ID = 'modal-confirm-change-email-id'
export const MODAL_EDIT_FRANCHISE_ID = 'modal-edit-franchise-id'
export const MODAL_VIEW_FRANCHISEE_INFO = 'modal-view-franchisee-info-id'
export const MODAL_MESSAGE_ID = 'modal-message-id'
export const MODAL_WORKBOOK_ID = 'modal-workbook-id'
export const MODAL_EDIT_FRANCHISEE_ID = 'modal-edit-franchisee-id'
export const MODAL_EDIT_QUESTIONNAIRE_ID = 'modal-edit-questionnaire-id'
export const MODAL_RATING_FRANCHISE_ID = 'modal-rating-franchise-id'

// landing
export const LANDING_OLD_LIST = [3007, 3810, 2867, 4240, 3988, 3547]
export const LANDING_WEBSITE_PREFIX = 'new/landing'

// auth providers
export const AUTH_PROVIDER_GOOGLE = 'google'
export const AUTH_PROVIDER_APPLE = 'apple'


// Work Book
export const WORK_BOOK_QUESTION_TYPES = {
  'OPEN_QUESTION': 'OPEN_QUESTION',
  'OPEN_QUESTION_ADDITIONAL': 'OPEN_QUESTION_ADDITIONAL',
  'OPEN_QUESTION_WITH_CHOICE': 'OPEN_QUESTION_WITH_CHOICE',
  'TOP_ONE_ENTRY_CUSTOM': 'TOP_ONE_ENTRY_CUSTOM',
  'TOP_TWO_ENTRY_CUSTOM': 'TOP_TWO_ENTRY_CUSTOM',
  'OPEN_QUESTION_TOP_TEN_ENTRY': 'OPEN_QUESTION_TOP_TEN_ENTRY',
  'OPEN_QUESTION_TOP_FIVE_ENTRY': 'OPEN_QUESTION_TOP_FIVE_ENTRY',
  'OPEN_QUESTION_TOP_THREE_ENTRY': 'OPEN_QUESTION_TOP_THREE_ENTRY',
  'OPEN_QUESTION_TOP_ONE_ENTRY': 'OPEN_QUESTION_TOP_ONE_ENTRY',
  'OPEN_QUESTION_TOP_ONE_ENTRY_CENTERED': 'OPEN_QUESTION_TOP_ONE_ENTRY_CENTERED',
  'OPEN_QUESTION_ALT_TOP_NINETY_ENTRY': 'OPEN_QUESTION_ALT_TOP_NINETY_ENTRY',
  'OPEN_QUESTION_ALT_TOP_TWELVE_ENTRY': 'OPEN_QUESTION_ALT_TOP_TWELVE_ENTRY',
  'OPEN_QUESTION_ALT_TOP_TEN_ENTRY': 'OPEN_QUESTION_ALT_TOP_TEN_ENTRY',
  'OPEN_QUESTION_ALT_TOP_FIVE_ENTRY': 'OPEN_QUESTION_ALT_TOP_FIVE_ENTRY',
  'OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY': 'OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY',
  'OPEN_QUESTION_ALT_TOP_THREE_ENTRY': 'OPEN_QUESTION_ALT_TOP_THREE_ENTRY',
  'OPEN_QUESTION_ALT_TOP_ONE_ENTRY': 'OPEN_QUESTION_ALT_TOP_ONE_ENTRY',
  'CLOSED_QUESTION': 'CLOSED_QUESTION',
  'CLOSED_QUESTION_SELECT': 'CLOSED_QUESTION_SELECT',
  'CLOSED_QUESTION_WITH_SCORE_CANDIDATES': 'CLOSED_QUESTION_WITH_SCORE_CANDIDATES',
  'CLOSED_QUESTION_WITH_SCORE_TOP_TEN': 'CLOSED_QUESTION_WITH_SCORE_TOP_TEN',
  'SCORING_SECTION': 'SCORING_SECTION',
  'SCORING_CHAPTER_TEN': 'SCORING_CHAPTER_TEN',
  'SCORING_CHAPTER_FIVE': 'SCORING_CHAPTER_FIVE',
  'TOP_TEN_ENTRY': 'TOP_TEN_ENTRY',
  'TOP_TEN_ENTRY_CUSTOM': 'TOP_TEN_ENTRY_CUSTOM',
  'INFO_PERFORMANCE_INDICATORS': 'INFO_PERFORMANCE_INDICATORS',
  'SELECT_TOP_FIVE': 'SELECT_TOP_FIVE',
  'SELECT_TOP_THREE': 'SELECT_TOP_THREE',
  'SELECT_TOP_TWO': 'SELECT_TOP_TWO',
  'ADDITIONAL_QUESTIONS_TOP_FIVE': 'ADDITIONAL_QUESTIONS_TOP_FIVE',
  'NET_WORTH_CALCULATOR': 'NET_WORTH_CALCULATOR',
  'PROS_AND_CONS_TOP_THREE_ENTRY': 'PROS_AND_CONS_TOP_THREE_ENTRY',
  'COMPETITIVE_ANALYSIS_TOP_THREE_ENTRY': 'COMPETITIVE_ANALYSIS_TOP_THREE_ENTRY',
  'TABLE_TOTAL': 'TABLE_TOTAL',
  'TABLE_TOTAL_TOP_FIVE': 'TABLE_TOTAL_TOP_FIVE',
  'SELECT_TOP_ONE': 'SELECT_TOP_ONE',
  'MATH_QUESTION': 'MATH_QUESTION',
  'CONGRATULATIONS': 'CONGRATULATIONS',
  'LOCAL_MARKETING_TOOLKIT': 'LOCAL_MARKETING_TOOLKIT',
  'PERSONAL_PREFERENCES': 'PERSONAL_PREFERENCES',
  'BUSINESS_ATTRIBUTES': 'BUSINESS_ATTRIBUTES',
  'TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS': 'TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS',
  'TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS_VIEWED': 'TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS_VIEWED',
  'CULTURE_TRAITS_TOP_TEN': 'CULTURE_TRAITS_TOP_TEN',
  'CULTURE_TRAITS_TOP_THREE': 'CULTURE_TRAITS_TOP_THREE',
}

export const WORK_BOOK_CHAPTER_STATUS = {
  'DEFAULT': 'DEFAULT',
  'CURRENT': 'CURRENT',
  'IN_PROGRESS': 'IN_PROGRESS',
  'FINISHED': 'FINISHED',
}

export const WORK_BOOK_TOP_LIST_KEYS = {
  'TEN': 'TEN',
  'FIVE': 'FIVE',
  'THREE': 'THREE',
  'TWO': 'TWO',
  'ONE': 'ONE',
}

export const WORK_BOOK_FIELD_TYPES = {
  'SELECT': 'SELECT',
  'RANGE': 'RANGE',
  'NUMBER': 'NUMBER',
  'RELATION': 'RELATION',
  'CHECKBOX': 'CHECKBOX',
  'CHECKBOX_NOT_LABEL': 'CHECKBOX_NOT_LABEL',
  'TOTAL': 'TOTAL',
  'TOTAL_CALCULATED': 'TOTAL_CALCULATED',
  'TOTAL_LINE': 'TOTAL_LINE',
  'TITLE': 'TITLE',
  'DEFAULT': 'DEFAULT',
  'DATE': 'DATE',
  'TEXT': 'TEXT',
  'RADIO': 'RADIO',
  'AUTO_SCORE_TOP_FIVE': 'AUTO_SCORE_TOP_FIVE',
  'DIFFERENCE': 'DIFFERENCE',
}

export const WORK_BOOK_FIELD_VALUE_TYPES = {
  'TEN_FRANCHISEE': 'TEN_FRANCHISEE',
  'TEN_FRANCHISEE_TOTAL': 'TEN_FRANCHISEE_TOTAL',
}

export const WORK_BOOK_CALC_TYPE = {
  'SUM': 'SUM',
  'NUMBER': 'NUMBER',
  'OPERATOR': 'OPERATOR',
}

export const WORK_BOOK_TAB_TYPE = {
  'ARROW': 'ARROW',
  'LIST': 'LIST',
}

export const WORK_BOOK = [
  {
    id: 1,
    name: 'Identify',
    chapters: [
      {
        id: 1,
        name: 'Personal history',
        description: 'This exercise will help you identify if franchising is truly a fit for you. Score 4 for yes and 0 for no.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION,
            calculateTotal: true,
            scoring: [
              {
                name: 'Yes',
                value: 4,
              },
              {
                name: 'No',
                value: 0,
              }
            ],
            questions: [
              {id: 1, text: 'In past jobs, did you operate independently?'},
              {id: 2, text: 'In past jobs, did you create policies and procedures?'},
              {id: 3, text: 'In past jobs, did you utilize policies and procedures?'},
              {id: 4, text: 'Do you consider policies and procedures to be hard rules?'},
              {
                id: 5,
                text: 'Do you prefer to utilize standardized technology tools rather than create your own?'
              },
              {id: 6, text: 'Do you prefer large office environments over small ones?'},
              {id: 7, text: 'Do you have experience in working with outsourced support services?'},
              {id: 8, text: 'Do you have experience in investor relations?'},
              {id: 9, text: 'Do you have experience in customer acquisition?'},
              {
                id: 10,
                text: 'Do you appreciate the role that policies and procedure play in an organization?'
              },
            ],
          }
        ]
      },
      {
        id: 2,
        name: 'Your vision',
        description: 'Highlight the top three most important questions below. (Note, you will select your preferred geographic location on Franchise123.com.)',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_WITH_CHOICE,
            calculateTotal: false,
            scoring: [],
            questions: [
              {
                id: 1,
                text: 'What makes you happy, proud, and excited in life? I.e accomplishments, goals and objectives, rewards, etc.'
              },
              {id: 2, text: 'What is your short term (0-12 months) vision for your life?'},
              {id: 3, text: 'What is your long term (12+ months) vision for your life?'},
              {id: 4, text: 'Where do you expect to be in five years, and in 20 years?'},
              {
                id: 5,
                text: 'Is there a specific geographic area you desire to be in? If so, list why.'
              },
              {
                id: 6,
                text: 'Do you have a preference or dislike for a specific industry? For example, do you dislike fixing things? Enjoy hospitality?'
              },
              {id: 7, text: 'List your top five industries.'},
              {id: 8, text: 'What is the maximum amount you are willing or able to invest?'},
              {id: 9, text: 'What are your non-negotiables?'},
              {
                id: 10,
                text: 'If you already have specific franchises in mind, write them down along with your reasons for liking them.'
              },
            ],
          }
        ]
      },
      {
        id: 3,
        name: 'Personal preferences',
        description: 'Are you interested in pursuing a specific field, industry, or area of expertise? (Note: you will filter your industry selections when creating your account on Franchise123.com)',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.PERSONAL_PREFERENCES,
            calculateTotal: false,
            name: 'Industries',
            questions: [
              {id: 1, text: "Accounting & Financial", tabIndex: 0},
              {id: 2, text: "Advertising & Marketing", tabIndex: 0},
              {id: 3, text: "Automotive", tabIndex: 0},
              {id: 4, text: "Business Opportunities", tabIndex: 0},
              {id: 5, text: "Business Services", tabIndex: 0},
              {id: 6, text: "Children's", tabIndex: 0},
              {id: 7, text: "Cleaning", tabIndex: 0},
              {id: 8, text: "Coffee", tabIndex: 0},
              {id: 9, text: "Computer & Internet", tabIndex: 0},
              {id: 10, text: "Consultants & Business Brokers", tabIndex: 0},
              {id: 11, text: "Courier", tabIndex: 0},
              {id: 12, text: "Education", tabIndex: 0},
              {id: 13, text: "Employment & Staffing", tabIndex: 0},
              {id: 14, text: "Entertainment", tabIndex: 0},
              {id: 15, text: "Fitness", tabIndex: 0},
              {id: 16, text: "Food", tabIndex: 0},
              {id: 17, text: "Franchise Resales", tabIndex: 0},
              {id: 18, text: "Franchise Service Providers", tabIndex: 0},
              {id: 19, text: "Health & Beauty", tabIndex: 0},
              {id: 20, text: "Healthcare & Senior Care", tabIndex: 0},
              {id: 21, text: "Home Services", tabIndex: 0},
              {id: 22, text: "Home-Based", tabIndex: 0},
              {id: 23, text: "Hospitality Industry", tabIndex: 0},
              {id: 24, text: "Industrial", tabIndex: 0},
              {id: 25, text: "Mailing & Shipping", tabIndex: 0},
              {id: 26, text: "Moving & Storage", tabIndex: 0},
              {id: 27, text: "Pet", tabIndex: 0},
              {id: 28, text: "Photography", tabIndex: 0},
              {id: 29, text: "Printer, Copying & Sign", tabIndex: 0},
              {id: 30, text: "Real Estate", tabIndex: 0},
              {id: 31, text: "Restaurant", tabIndex: 0},
              {id: 32, text: "Retail", tabIndex: 0},
              {id: 33, text: "Security", tabIndex: 0},
              {id: 34, text: "Sports", tabIndex: 0},
              {id: 35, text: "Tax", tabIndex: 0},
              {id: 36, text: "Travel", tabIndex: 0},
              {id: 37, text: "Vending & ATM", tabIndex: 0},
              {id: 38, text: "Other", tabIndex: 0},
              {id: 39, text: 'What is your annual income goal?', tabIndex: 1},
              {
                id: 40,
                text: 'Would you rather be in retail sales or performing a service?',
                tabIndex: 1
              },
              {
                id: 41,
                text: 'How many hours per week can you devote to our new business?',
                tabIndex: 1
              },
              {
                id: 42,
                text: 'Do you intend to hire a manager or operate the business yourself?',
                tabIndex: 1
              },
              {
                id: 43,
                text: 'Will the income from the franchise be your primary income or a supplement to your current income? Describe.',
                tabIndex: 1
              },
              {
                id: 44,
                text: 'Do you get bored quickly and need a new challenge regularly, or is this a long-term commitment for you?',
                tabIndex: 1
              },
              {
                id: 45,
                text: 'Would you prefer to own one franchise unit or multiple? If multiple, do you plan on doing so right away?',
                tabIndex: 1
              },
              {id: 46, text: 'What is your geographical region of interest?', tabIndex: 1},
              {id: 47, text: 'What is your maximum investment threshold?', tabIndex: 1},
            ],
            fields: [
              {
                name: 'Yes',
                slug: 'yes',
                type: WORK_BOOK_FIELD_TYPES.CHECKBOX_NOT_LABEL,
                tabIndex: 0,
                checkedValue: {
                  label: 'Yes',
                  value: 'yes',
                },
              },
              {
                name: 'Answers',
                slug: 'answers',
                type: WORK_BOOK_FIELD_TYPES.TEXT,
                tabIndex: 1,
              },
            ],
            tabs: [
              {
                name: 'Table 1',
                slug: 'tableOne',
                questionTitle: 'Industries',
              },
              {
                name: 'Table 2',
                slug: 'tableTwo',
                questionTitle: 'Questions',
              },
            ]
          },
        ]
      },
      {
        id: 4,
        name: 'Entrepreneurial skills assessment',
        description: 'Rate the below questions on a scale of 1-5 where  “Strongly disagree” answer equals to score 1, “Strongly agree” answer equals to score 5',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION,
            calculateTotal: true,
            subDescription: 'Score of 1-10: Manager<br>Score of 11-20: Operator<br>Score of 21+: Entrepreneur',
            scoring: [
              {
                name: 'Strongly Disagree',
                value: 1,
              },
              {
                name: 'Disagree',
                value: 2,
              },
              {
                name: 'Undecided',
                value: 3,
              },
              {
                name: 'Agree',
                value: 4,
              },
              {
                name: 'Strongly Agree',
                value: 5,
              }
            ],
            questions: [
              {id: 1, text: 'Do you typically have an unwavering vision?'},
              {
                id: 2,
                text: 'Do you often feel that your vision is misunderstood by others or difficult to grasp?'
              },
              {id: 3, text: 'Are your goals and timeline clear?'},
              {id: 4, text: 'Do you view failures as a learning experience?'},
              {id: 5, text: 'Do you believe it is okay to be considered an outlier?'},
              {id: 6, text: 'Do you believe that people are the key to success?'},
              {id: 7, text: 'Do you believe that failure is inevitable but always recoverable?'},
            ],
          }
        ]
      },
      {
        id: 5,
        name: 'Business attributes assessment',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.BUSINESS_ATTRIBUTES,
            calculateTotal: false,
            scoring: [],
            questions: [
              {id: 1, text: 'List your three greatest attributes in business.'},
              {id: 2, text: 'List your three greatest weaknesses in business.'},
            ],
          }
        ]
      },
      {
        id: 6,
        name: 'Business skills assessment',
        description: 'Score the following on a scale of 1-5. Score 5 meaning you are strong in this area.  Score 1 meaning you might need some help in this area.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_SELECT,
            calculateTotal: true,
            scoring: [
              {
                name: '1',
                value: 1,
              },
              {
                name: '2',
                value: 2,
              },
              {
                name: '3',
                value: 3,
              },
              {
                name: '4',
                value: 4,
              },
              {
                name: '5',
                value: 5,
              }
            ],
            questions: [
              {id: 1, text: 'Sales and Marketing'},
              {id: 2, text: 'Media Engagement'},
              {id: 3, text: 'Customer Service'},
              {id: 4, text: 'Team Building'},
              {id: 5, text: 'Technical Skills'},
            ],
          }
        ]
      },
      {
        id: 7,
        name: 'Reflection',
        description: 'Before moving onto the next section, here are some questions to ask yourself:',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION,
            calculateTotal: false,
            scoring: [],
            questions: [
              {
                id: 1,
                text: 'What type of owner do you want to be: investor, part-time, or owner/operator?'
              },
              {id: 2, text: 'Do you anticipate opening multiple franchise units?'},
              {id: 3, text: 'How much are you willing and able to invest in total?'},
              {
                id: 4,
                text: 'Where will the funds for this business be coming from (cash, 401K, home equity, SBA loan, etc.)?'
              },
              {id: 5, text: 'In what industry do you want to operate?'},
              {id: 6, text: 'Where do you want to open your franchise?'},
              {id: 7, text: 'How soon do you want to have your business up and running?'},
              {
                id: 8,
                text: 'Will anyone else be involved in the decision making process (spouse, business partner, etc.)?'
              },
              {id: 9, text: 'How soon do you plan on making a decision?'},
              {id: 10, text: 'Have you  ever owned a business before?'},
              {id: 11, text: 'Do you have the skills necessary to run a business?'},
            ],
          }
        ]
      },
      {
        id: 8,
        name: 'Am I right for franchising?',
        description: 'A total score of 50 or above typically signifies that franchising could be a great fit for you.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.SCORING_SECTION,
            calculateTotal: false,
            scoring: [],
            questions: [],
          }
        ]
      },
      {
        id: 9,
        name: 'Non-negotiables',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION,
            calculateTotal: false,
            description: 'Now that we have identified that you are the right fit for franchising, let\'s start to narrow down your search. Our goal is to bring you from thousands of opportunities down to 100, then 50, then 10, and continue on until ultimately we find your perfect match.<br/><img src="/img/workbook_chapter_1.9.jpg" ><br/>With over 4,000 franchises to choose from, we advise you to first identify your non-negotiables.',
            subDescription: 'If you don’t know these right now, that’s okay, but it will make it much easier to narrow your search.',
            scoring: [],
            questions: [
              {id: 1, text: 'State location(s)'},
              {id: 2, text: 'Budget'},
              {id: 3, text: 'Top 5 Industries of Interest'},
            ],
          }
        ]
      },
      {
        id: 10,
        name: 'Key performance indicators',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.INFO_PERFORMANCE_INDICATORS,
            calculateTotal: false,
            scoring: [],
            questions: [],
          }
        ]
      },
    ],
  },
  {
    id: 2,
    name: 'Research',
    chapters: [
      {
        id: 1,
        customNumber: '2.1',
        name: 'Top ten franchisors',
        description: 'Now it’s time to pick your top ten franchises. Using the comparison tool on Franchise123.com, you can easily compare key performance indicators of your current top 20 franchise selections. Use this data to select your top ten. <br/><br/> Congratulations, now you are ready to begin researching your top ten! Simply click the “Begin Today” button on your top ten franchises on Franchise123.com.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY,
            scoring: [],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
              {
                id: 7,
              },
              {
                id: 8,
              },
              {
                id: 9,
              },
              {
                id: 10,
              },
            ],
            fields: [
              {
                name: 'Franchise',
                slug: 'franchise',
              },
              {
                name: 'Industry',
                slug: 'industry',
              },
              {
                name: 'Why you’re interested',
                slug: 'interested',
              },
            ]
          }
        ]
      },
      {
        id: 2,
        customNumber: '2.2',
        name: 'FDD comparison chart',
        description: 'Score the following on a scale of 1-5. Make sure to review all the 10 franchises.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN,
            scoring: [],
            questions: [
              {id: 1, text: 'Length of time franchising',},
              {id: 2, text: 'Experience',},
              {id: 3, text: 'Lawsuits',},
              {id: 4, text: 'Bankruptcy',},
              {id: 5, text: 'Royalty assessment',},
              {id: 6, text: 'Fee assessment',},
              {id: 7, text: 'Investment value',},
              {id: 8, text: 'Concerns on restrictions',},
              {id: 9, text: 'Obligations aligned?',},
              {id: 10, text: 'Is financing necessary?',},
              {id: 11, text: 'Franchisor assistance',},
              {id: 12, text: 'Do the territories fit your needs?',},
              {id: 13, text: 'Trademark concerns',},
              {id: 14, text: 'IP concerns',},
              {id: 15, text: 'Your obligation concerns',},
              {id: 16, text: 'Your restrictions',},
              {id: 17, text: 'Concerns on terms',},
              {id: 18, text: 'Concerns on arrangements',},
              {id: 19, text: 'Financials meet your goals',},
              {id: 20, text: 'Franchisee discussion',},
              {id: 21, text: 'Financial statement concerns',},
              {id: 22, text: 'Contract concerns',},
              {id: 23, text: 'Management team',},
              {id: 24, text: 'Company reputation',},
              {id: 25, text: 'Overall impression',},
            ],
            fields: []
          }
        ]
      },
      {
        id: 3,
        customNumber: '2.3',
        name: 'FDD review tracker',
        description: 'As you review the franchise disclosure document, use this chart to summarize your questions, comments, and concerns of each section. Make sure to review all the 10 franchises.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_TEN_ENTRY,
            scoring: [],
            showTotal: false,
            classList: ['sticky'],
            questionsTitle: '',
            questions: [
              {id: 1, text: 'Parent, predecessors, affiliates'},
              {id: 2, text: 'Business experience'},
              {id: 3, text: 'Litigation'},
              {id: 4, text: 'Bankruptcy'},
              {id: 5, text: 'Franchise fees'},
              {id: 6, text: 'Other fees'},
              {id: 7, text: 'Estimated initial investment'},
              {id: 8, text: 'Restrictions on sources of products/services'},
              {id: 9, text: 'Franchisee obligations'},
              {id: 10, text: 'Financing'},
              {id: 11, text: 'Franchisor assistance'},
              {id: 12, text: 'Territory'},
              {id: 13, text: 'Trademarks'},
              {id: 14, text: 'Patents, copyright, IP'},
              {id: 15, text: 'Obligations'},
              {id: 16, text: 'Restrictions'},
              {id: 17, text: 'Terms'},
              {id: 18, text: 'Arrangements'},
              {id: 19, text: 'Financial representation'},
              {id: 20, text: 'Franchisee information'},
              {id: 21, text: 'Financial statements'},
              {id: 22, text: 'Contracts'},
              {id: 23, text: 'Receipt'},
            ],
            fields: [
              {
                name: 'Questions',
                slug: 'questions',

              },
              {
                name: 'Answers',
                slug: 'answers',
              },
            ]
          }
        ]
      },
      {
        id: 4,
        customNumber: '2.4',
        name: 'Franchisor contact information',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Contact Person',
                slug: 'contactPerson',
                cellClassList: [
                  'question_open__table__cell'
                ],
              },
              {
                name: 'Phone Number',
                slug: 'phoneNumber',
                cellClassList: [
                  'question_open__table__cell'
                ],
              },
              {
                name: 'Email Address',
                slug: 'emailAddress',
                cellClassList: [
                  'question_open__table__cell'
                ],
              },
            ]
          }
        ]
      },
      {
        id: 5,
        customNumber: '2.5',
        name: 'Franchisor engagement tracker',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_TOP_TEN,
            description: '',
            scoring: [],
            questions: [
              {id: 1, text: 'Website reviewed'},
              {id: 2, text: 'Initial package reviewed'},
              {id: 3, text: 'Initial impression total'},
            ],
            fields: [
              {
                name: 'Website reviewed',
                slug: 'websiteReviewed',
              },
              {
                name: 'Initial package reviewed',
                slug: 'initialPackageReviewed',
              },
              {
                name: 'Initial impression iotal',
                slug: 'initialImpressionTotal',
              },
            ]
          }
        ]
      },
      {
        id: 6,
        customNumber: '2.6',
        name: 'Initial interview questions',
        description: 'When you have your first phone call with the franchise representative, here are some recommended questions to ask. Score the questions in the tracker below from 1-5.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'How many franchise locations opened over the past year and how many do you plan to open this year?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Last Year',
                slug: 'lastYear',
              },
              {
                name: 'This Year',
                slug: 'thisYear',
              },
              {
                name: 'Difference',
                slug: 'difference',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: 'Decrease',
                    value: 1
                  },
                  {
                    text: 'No Change',
                    value: 3
                  },
                  {
                    text: 'Increase',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 2,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'How is the stability of the industry you operate in?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 3,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'What is your brand’s competitive advantage?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 4,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'What is your primary goal for franchisees?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 5,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'What is your company culture like?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 6,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'What are your expectations of me as a franchise owner?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 7,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'How do you support your franchisees?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 8,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'Tell me about your team’s experience in your core business.',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 9,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'Tell me about your team’s experience in franchising.',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 10,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TEN_ENTRY,
            description: '',
            tabName: 'What is your company’s reputation and what contributes to it?',
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: 'Franchise Name',
                slug: 'franchiseName',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TEN,
                  index: 'franchise',
                }
              },
              {
                name: 'Answer',
                slug: 'answer',
              },
              {
                name: 'Score',
                slug: 'score',
                isScore: true,
                cellClassList: ['align_left', 'width_150'],
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ]
          },
          {
            id: 11,
            type: WORK_BOOK_QUESTION_TYPES.SCORING_CHAPTER_TEN,
            description: '',
            tabName: 'Summary and Totals',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: []
          },
        ]
      },
      {
        id: 7,
        customNumber: '2.7',
        name: 'Top five franchisors',
        description: 'Rank your top five franchisors based on points.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.SELECT_TOP_FIVE,
            scoring: [],
            questions: [],
            fields: []
          },
        ]
      },
      {
        id: 8,
        customNumber: '2.8',
        name: 'Additional questions for the franchisor',
        description: 'In this section, you will narrow your search from five to three franchisors. Score the following on a scale of 1-5. Score 5 meaning this Franchise is strong in this area.  Score 1 meaning this is a weak point of this Franchise.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ADDITIONAL,
            description: '',
            tabName: '',
            scoring: [],
            showTotal: true,
            questionsTitle: '',
            questions: [
              {id: 1, text: 'What is the history of the franchise company?', tabIndex: 0,},
              {
                id: 2,
                text: 'Is the founder of the franchise still involved with the company?',
                tabIndex: 0,
              },
              {
                id: 3,
                text: 'Who are the officers of the franchise, and what are their backgrounds and qualifications?',
                tabIndex: 0,
              },
              {id: 4, text: 'When was the company founded?', tabIndex: 0,},
              {id: 5, text: 'How long has the company been franchising?', tabIndex: 0,},
              {
                id: 6,
                text: 'How many locations were open prior to franchising the company?',
                tabIndex: 0,
              },
              {id: 7, text: 'How many locations are open currently?', tabIndex: 0,},
              {id: 8, text: 'How many franchise locations have been sold?', tabIndex: 0,},
              {id: 9, text: 'What is your mission statement and brand vision?', tabIndex: 0,},
              {
                id: 10,
                text: 'Does the franchisor have adequate experience in both the industry and with running a successful franchise system?',
                tabIndex: 0,
              },
              {id: 11, text: 'What are the main goals of the franchise?', tabIndex: 1,},
              {
                id: 12,
                text: 'How big is the market for the products/services you sell?',
                tabIndex: 1,
              },
              {
                id: 13,
                text: 'What are the market growth projections over the next year? 5 years? 10 years?',
                tabIndex: 1,
              },
              {
                id: 14,
                text: 'What are your franchise development goals and growth projections?',
                tabIndex: 1,
              },
              {
                id: 15,
                text: 'What are the biggest challenges for your franchise to overcome?',
                tabIndex: 1,
              },
              {id: 16, text: 'What is the turnover rate of franchise units?', tabIndex: 1,},
              {id: 17, text: 'How many franchise locations close each year?', tabIndex: 1,},
              {
                id: 18,
                text: 'Are there any franchise units that were sold but have not opened yet or never opened? How many? If so, what was the reason?',
                tabIndex: 1,
              },
              {id: 19, text: 'How many units are re-sold each year?', tabIndex: 1,},
              {
                id: 20,
                text: 'How many franchise units have failed in the last year? In the last three years?',
                tabIndex: 1,
              },
              {
                id: 21,
                text: 'Are the franchisor’s financial assets and resources sufficient to support a growing number of franchisees?',
                tabIndex: 1,
              },
              {id: 22, text: 'Does the franchisor have a steady growth plan?', tabIndex: 1,},
              {
                id: 23,
                text: 'Does the majority of the franchisor’s income come from franchise sales or from royalty fees?',
                tabIndex: 1,
              },
              {
                id: 24,
                text: 'How much of these funds get re-invested in the franchise system?',
                tabIndex: 1,
              },
              {
                id: 25,
                text: 'Does the franchisor make most of its income from the sale of franchises or from continuing royalties?',
                tabIndex: 1,
              },
              {
                id: 26,
                text: 'Does the franchise devote sufficient funds to support a growing franchise system?',
                tabIndex: 1,
              },
              {
                id: 27,
                text: 'What are the benefits of joining your franchise system?',
                tabIndex: 2,
              },
              {
                id: 28,
                text: 'What support do you offer franchisees in the pre-open, grand opening, and operational stages (marketing, real estate, construction, employee management, sales, etc.)?',
                tabIndex: 2,
              },
              {
                id: 29,
                text: 'What competitive advantages does your franchise have over others in the industry?',
                tabIndex: 2,
              },
              {
                id: 30,
                text: 'Do you utilize collective buying power to offer vendor discounts or supply chain services?',
                tabIndex: 2,
              },
              {id: 31, text: 'What does the franchisee training program look like?', tabIndex: 2,},
              {id: 32, text: 'Do you offer training for employees/team members?', tabIndex: 2,},
              {
                id: 33,
                text: 'How well does the initial training prepare a franchisee for opening and operating the business?',
                tabIndex: 2,
              },
              {
                id: 34,
                text: 'What ongoing training and support does the franchisor provide?',
                tabIndex: 2,
              },
              {
                id: 35,
                text: 'Are franchisees able to easily get in touch with the leadership team with questions or concerns?',
                tabIndex: 2,
              },
              {
                id: 36,
                text: 'What is the initial investment and what does this amount include?',
                tabIndex: 3,
              },
              {
                id: 37,
                text: 'Did you include a financial performance representation or earnings claim in the franchise disclosure document (item 19)? If not, why?',
                tabIndex: 3,
              },
              {
                id: 38,
                text: 'Are there any additional expenses incurred by the franchisee for training?',
                tabIndex: 3,
              },
              {
                id: 39,
                text: 'Which metrics will most impact my revenue, growth, and profitability (marketing spend, conversion rate, etc.)?',
                tabIndex: 3,
              },
              {
                id: 40,
                text: 'Are there any pending lawsuits against the franchisor?',
                tabIndex: 4,
              },
              {
                id: 41,
                text: 'Have there been any legal disputes with franchisees in the past? If so, how were they resolved?',
                tabIndex: 4,
              },
              {
                id: 42,
                text: 'What is the length of time covered by the franchise agreement?',
                tabIndex: 4,
              },
              {
                id: 43,
                text: 'Any bankruptcies in the franchisor’s past or that of the franchise’s officers?',
                tabIndex: 4,
              },
              {
                id: 44,
                text: 'What territorial protections do you offer to franchisees?',
                tabIndex: 4,
              },
              {
                id: 45,
                text: 'What is the hourly time commitment expected of an owner/operator?',
                tabIndex: 5,
              },
              {
                id: 46,
                text: 'Is the franchisee required to be involved in the day-to-day operations, or can this be delegated to a general manager?',
                tabIndex: 5,
              },
              {
                id: 47,
                text: 'Can franchisees control their own advertising methods? How is marketing performance measured?',
                tabIndex: 5,
              },
              {
                id: 48,
                text: 'What is the most common mistake franchisees make in their first year?',
                tabIndex: 5,
              },
              {
                id: 49,
                text: 'What do your most successful franchisees have in common? Least successful?',
                tabIndex: 5,
              },
              {
                id: 50,
                text: 'Is there a franchise advisory council? What are some changes that have come about as a result?',
                tabIndex: 5,
              },
            ],
            fields: [
              {
                name: 'Answers',
                slug: 'answers',
              },
              {
                name: 'Rank',
                description: '(1-5)',
                slug: 'score',
                isScore: true,
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [
                  {
                    text: '1',
                    value: 1
                  },
                  {
                    text: '2',
                    value: 2
                  },
                  {
                    text: '3',
                    value: 3
                  },
                  {
                    text: '4',
                    value: 4
                  },
                  {
                    text: '5',
                    value: 5
                  }
                ]
              },
            ],
            tabs: [
              {
                name: 'Franchisor Background',
                slug: 'franchisorBackground',
              },
              {
                name: 'Industry and Growth',
                slug: 'industryAndGrowth',
              },
              {
                name: 'Franchisee Benefits',
                slug: 'franchiseeBenefits',
              },
              {
                name: 'Financials',
                slug: 'financials',
              },
              {
                name: 'Legal',
                slug: 'legal',
              },
              {
                name: 'Franchisee Role',
                slug: 'franchiseeRole',
              },
            ],
          },
        ]
      },
      {
        id: 9,
        customNumber: '2.8.1',
        name: 'Additional questions total scores',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.TABLE_TOTAL_TOP_FIVE,
            description: '',
            scoring: [],
            questions: [],
            fields: [],
          }
        ]
      },
      {
        id: 10,
        customNumber: '2.8.2',
        name: 'Other additional questions',
        description: 'In this section, you will narrow your search from five to three franchisors.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.ADDITIONAL_QUESTIONS_TOP_FIVE,
            scoring: [],
            questions: [
              {id: 1}
            ],
            fields: []
          }
        ]
      },
      {
        id: 11,
        customNumber: '2.9',
        name: 'Top three franchisors',
        description: 'Rank your top three franchisors.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.SELECT_TOP_THREE,
            scoring: [],
            questions: [],
            fields: []
          },
        ]
      },
      {
        id: 12,
        customNumber: '2.10',
        name: 'Franchise model assessment',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
            ],
            fields: [
              {
                name: 'Franchise',
                slug: 'franchise',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.THREE,
                  index: 'franchise',
                }
              },
              {
                name: 'Industry',
                slug: 'industry',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.THREE,
                  index: 'industry',
                }
              },
              {
                name: 'Brick and Mortar?',
                slug: 'brickAndMortar',
              },
              {
                name: 'Date Founded',
                slug: 'dateFounded',
              },
              {
                name: 'Franchisees',
                slug: 'franchisees',
              },
              {
                name: 'Rating',
                slug: 'rating',
                type: WORK_BOOK_FIELD_TYPES.AUTO_SCORE_TOP_FIVE,
                isScore: true,
                styleList: {
                  minWidth: '50px',
                },
              },
              {
                name: 'Financial Strategy',
                slug: 'financialStrategy',
                type: WORK_BOOK_FIELD_TYPES.CHECKBOX,
                checkedValue: {
                  label: 'Yes',
                  value: 'yes',
                },
              },
            ]
          },

        ]
      },
      {
        id: 13,
        customNumber: '2.11',
        name: 'Marketing assessment',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
            ],
            fields: [
              {
                name: 'Franchise',
                slug: 'franchise',
                type: WORK_BOOK_FIELD_TYPES.RELATION,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.THREE,
                  index: 'franchise',
                }
              },
              {
                name: 'Marketing channel',
                slug: 'marketingChannel',
              },
              {
                name: 'Branding or lead generation',
                slug: 'brandingOrLeadGeneration',
              },
              {
                name: 'Monthly cost',
                slug: 'monthlyCost',
              },
              {
                name: 'Estimated number of leads',
                slug: 'estimatedNumberOfLeads',
              },
              {
                name: 'Cost per lead',
                description: '(monthly cost / estimated number of leads)',
                slug: 'costPerLead',
              },
              {
                name: 'Estimated conversion rate',
                slug: 'estimatedConversionRate',
              },
              {
                name: 'Total clients/sales ',
                description: '(total leads * conversion rate)',
                slug: 'totalClients',
              },
              {
                name: 'Cost per acquisition',
                description: '( monthly cost / total clients)',
                slug: 'costPerAcquisition',
              },
            ]
          },

        ]
      },
      {
        id: 14,
        customNumber: '2.12',
        name: 'Competitive analysis',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.COMPETITIVE_ANALYSIS_TOP_THREE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1, text: 'Demographics', fieldIndex: 0},
              {id: 2, text: 'Location', fieldIndex: 0},
              {id: 3, text: 'Average Household income', fieldIndex: 0},
              {
                id: 4,
                text: '',
                fieldIndex: 1,
                groupIndex: 0,
                groupTitle: 'Companies with similar customer targets'
              },
              {
                id: 5,
                text: '1 mile:',
                fieldIndex: 1,
                groupIndex: 1,
                groupTitle: 'Direct competitors within your sector'
              },
              {
                id: 6,
                text: '5 miles:',
                fieldIndex: 1,
                groupIndex: 1,
                groupTitle: 'Direct competitors within your sector'
              },
              {
                id: 7,
                text: '10 miles:',
                fieldIndex: 1,
                groupIndex: 1,
                groupTitle: 'Direct competitors within your sector'
              },
              {
                id: 8,
                text: '50 miles:',
                fieldIndex: 1,
                groupIndex: 1,
                groupTitle: 'Direct competitors within your sector'
              },
              {
                id: 9,
                text: 'Nationwide:',
                fieldIndex: 1,
                groupIndex: 1,
                groupTitle: 'Direct competitors within your sector'
              },
              {id: 10, text: 'Company Name', fieldIndex: 2},
              {id: 11, text: 'Industry', fieldIndex: 2},
              {id: 12, text: 'Sector', fieldIndex: 2},
              {id: 13, text: 'Company Size', fieldIndex: 2},
              {id: 14, text: 'Customer Opinions', fieldIndex: 2},
              {id: 15, text: 'Company Products/Services', fieldIndex: 2},
              {id: 16, text: 'Pricing', fieldIndex: 2},
              {id: 17, text: 'Target Customer', fieldIndex: 2},
              {id: 18, text: 'Geographic Area', fieldIndex: 2},
              {id: 19, text: 'Customer Reach/Distance', fieldIndex: 2},
              {id: 20, text: 'Physical Accessibility/Convenience', fieldIndex: 2},
              {id: 21, text: 'Company Name', fieldIndex: 3},
              {id: 22, text: 'Industry', fieldIndex: 3},
              {id: 23, text: 'Sector', fieldIndex: 3},
              {id: 24, text: 'Company Size', fieldIndex: 3},
              {id: 25, text: 'Customer Opinions', fieldIndex: 3},
              {id: 26, text: 'Company Products/Services', fieldIndex: 3},
              {id: 27, text: 'Pricing', fieldIndex: 3},
              {id: 28, text: 'Target Customer', fieldIndex: 3},
              {id: 29, text: 'Geographic Area', fieldIndex: 3},
              {id: 30, text: 'Customer Reach/Distance', fieldIndex: 3},
              {id: 31, text: 'Physical Accessibility/Convenience', fieldIndex: 3},
              {id: 32, text: 'Company Name', fieldIndex: 4},
              {id: 33, text: 'Industry', fieldIndex: 4},
              {id: 34, text: 'Sector', fieldIndex: 4},
              {id: 35, text: 'Company Size', fieldIndex: 4},
              {id: 36, text: 'Customer Opinions', fieldIndex: 4},
              {id: 37, text: 'Company Products/Services', fieldIndex: 4},
              {id: 38, text: 'Pricing', fieldIndex: 4},
              {id: 39, text: 'Target Customer', fieldIndex: 4},
              {id: 40, text: 'Geographic Area', fieldIndex: 4},
              {id: 41, text: 'Customer Reach/Distance', fieldIndex: 4},
              {id: 42, text: 'Physical Accessibility/Convenience', fieldIndex: 4},
              {id: 43, text: 'Complimentary non-competing businesses', fieldIndex: 5},
              {id: 44, text: 'Vendors and local suppliers', fieldIndex: 5},
              {id: 45, text: 'Strategic partnerships for co-marketing, etc.', fieldIndex: 5},
              {id: 46, text: 'Investors', fieldIndex: 5},
            ],
            fields: [
              {
                name: 'Your Customer Profile',
                slug: 'yourCustomerProfile',
              },
              {
                name: 'Define Your Competition',
                slug: 'defineYourCompetition',
              },
              {
                name: 'Competitor #1',
                slug: 'сompetitorOne',
                description: 'Select your top three competitors to fill out the worksheets below.',
              },
              {
                name: 'Competitor #2',
                slug: 'сompetitorTwo',
                description: 'Select your top three competitors to fill out the worksheets below.',
              },
              {
                name: 'Competitor #3',
                slug: 'сompetitorThree',
                description: 'Select your top three competitors to fill out the worksheets below.',
              },
              {
                name: 'Your Allies',
                slug: 'yourAllies',
                info: 'Now that you have picked your top three, it’s time to start to dig into the feasibility of investment into each brand.'
              },
            ]
          },
        ]
      },
      {
        id: 15,
        customNumber: '2.13',
        name: 'Net worth calculator',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.NET_WORTH_CALCULATOR,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1, text: 'Real Estate', fieldIndex: 0},
              {id: 2, text: 'Checking Accounts', fieldIndex: 0},
              {id: 3, text: 'Savings Accounts', fieldIndex: 0},
              {id: 4, text: 'Retirement/Investment Accounts', fieldIndex: 0},
              {id: 5, text: 'Vehicles', fieldIndex: 0},
              {id: 6, text: 'Other Assets', fieldIndex: 0},
              {id: 7, text: 'Mortgages', fieldIndex: 1},
              {id: 8, text: 'Consumer Debt', fieldIndex: 1},
              {id: 9, text: 'Personal Loans', fieldIndex: 1},
              {id: 10, text: 'Student Loans', fieldIndex: 1},
              {id: 11, text: 'Auto Loans', fieldIndex: 1},
              {id: 12, text: 'Other Debt', fieldIndex: 1},
            ],
            fields: [
              {
                name: 'Assets',
                slug: 'assets',
              },
              {
                name: 'Liabilities',
                slug: 'liabilities',
              },
            ]
          },
        ]
      },
      {
        id: 16,
        customNumber: '2.14',
        name: 'Financial modeling for ROI analysis',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_THREE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            showTotal: false,
            questionsTitle: '',
            questions: [
              {id: 1, text: 'Revenue'},
              {id: 2, text: 'Expense'},
              {id: 3, text: 'Breakeven Calculation'},
              {id: 4, text: 'Total Initial Investment'},
              {id: 5, text: 'Estimated Time to Break-even'},
              {id: 6, text: 'Total Operating Capital Needed'},
              {id: 7, text: 'Total Capital Needed (Initial Investment + Operating Capital)'},
            ],
            fields: [
              {
                name: 'Answers',
                slug: 'answers',
              },
            ]
          },
        ]
      },
      {
        id: 17,
        customNumber: '2.15',
        name: 'Lending option comparisons tool',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_FIVE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
            ],
            fields: [
              {
                name: 'Lender name',
                slug: 'lenderName',
              },
              {
                name: 'Total cost',
                slug: 'totalCost',
              },
              {
                name: 'Loan type',
                slug: 'loanType',
              },
              {
                name: 'Interest rate',
                slug: 'interestRate',
              },
              {
                name: 'Length of loan',
                slug: 'lengthOfLoan',
              },
              {
                name: 'Pre-payment penalty',
                slug: 'prePaymentPenalty',
              },
              {
                name: 'Monthly payment amount',
                slug: 'monthlyPaymentAmount',
              },
              {
                name: 'Down payment required',
                slug: 'downPaymentRequired',
              },
              {
                name: 'Personal guarantee or collateral',
                slug: 'personalGuaranteeOrCollateral',
              },
            ]
          },

        ]
      },
      {
        id: 18,
        customNumber: '2.16',
        name: 'Pros and cons tracker',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.PROS_AND_CONS_TOP_THREE_ENTRY,
            description: '',
            tabName: '',
            scoring: [],
            questions: [
              {id: 1, text: 'Pros'},
              {id: 2, text: 'Cons'},
            ],
            fields: []
          },
        ]
      },
      {
        id: 19,
        customNumber: '2.17',
        name: 'Top two franchisors',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.SELECT_TOP_TWO,
            scoring: [],
            questions: [],
            fields: []
          },
        ]
      },
    ],
  },
  {
    id: 3,
    name: `Decide`,
    chapters: [
      {
        id: 1,
        customNumber: `3.1`,
        name: `Due Diligence Checklist`,
        description: ``,
        questionsBlocks: [
          {
            id: 1,
            tabName: `Franchise #1`,
            isTabNamefranchise: true,
            numberTop: 2,
            name: `Checklist`,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION,
            scoring: [
              {
                name: `Yes`,
                value: 1,
              },
              {
                name: `No`,
                value: 0,
              },
            ],
            questions: [
              {
                id: 1,
                text: `Do you understand how the franchisor will support you during pre-opening, grand opening, and ongoing operations?`,
              },
              {
                id: 2,
                text: `Have you thoroughly reviewed the FDD with a lawyer, especially Items 7 and 19?`,
              },
              {
                id: 3,
                text: `Have you confirmed the franchisor's claims with existing franchisees?`,
              },
              {
                id: 4,
                text: `Do you have the tools to succeed with this business model?`,
              },
            ],
            fields: [],
          },
          {
            id: 2,
            tabName: `Franchise #2`,
            isTabNamefranchise: true,
            numberTop: 2,
            name: `Checklist`,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION,
            scoring: [
              {
                name: `Yes`,
                value: 1,
              },
              {
                name: `No`,
                value: 0,
              },
            ],
            questions: [
              {
                id: 1,
                text: `Do you understand how the franchisor will support you during pre-opening, grand opening, and ongoing operations?`,
              },
              {
                id: 2,
                text: `Have you thoroughly reviewed the FDD with a lawyer, especially Items 7 and 19?`,
              },
              {
                id: 3,
                text: `Have you confirmed the franchisor's claims with existing franchisees?`,
              },
              {
                id: 4,
                text: `Do you have the tools to succeed with this business model?`,
              },
            ],
            fields: [],
          },
        ]
      },
      {
        id: 2,
        name: `Validation`,
        customNumber: `3.2`,
        description: `
          Organize the franchisees that you'd like to interview below. For each franchise brand, we recommend that you call a range of franchisees with the following experience:<br>
          New opened (2), 5 or more years of experience if applicable (2), Highest performers (2), Lowest performers (2), Random (2), Total - 10
         `,
        questionsBlocks: [
          {
            id: 0,
            tabName: `Franchise #1`,
            isTabNamefranchise: true,
            numberTop: 2,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS,
            classList: [
              `mode_one`,
              `sticky-header`,
            ],
            scoring: [
              {
                name: `Yes`,
                value: 0,
              },
              {
                name: `No`,
                value: 0,
              },
            ],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
              {
                id: 7,
              },
              {
                id: 8,
              },
              {
                id: 9,
              },
              {
                id: 10,
              },
            ],
            fields: [
              {
                name: `Franchisee name`,
                slug: `franchisee`,
                styleList: {
                  width: `366px`,
                },
              },
              {
                name: `Location`,
                slug: `location`,
                styleList: {
                  width: `308px`,
                },
              },
              {
                name: `Phone number`,
                slug: `phoneNumber`,
                styleList: {
                  width: `173px`,
                },
              },
              {
                name: `Years in operation`,
                slug: `yearsInOperation`,
                styleList: {
                  width: `96px`,
                },
              },
            ],
          },
          {
            id: 0,
            tabName: `Franchise #2`,
            numberTop: 2,
            isTabNamefranchise: true,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS,
            classList: [
              `mode_one`,
              `sticky-header`,
            ],
            scoring: [
              {
                name: `Yes`,
                value: 0,
              },
              {
                name: `No`,
                value: 0,
              },
            ],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
              {
                id: 7,
              },
              {
                id: 8,
              },
              {
                id: 9,
              },
              {
                id: 10,
              },
            ],
            fields: [
              {
                name: `Franchisee name`,
                slug: `franchisee`,
                styleList: {
                  width: `366px`,
                },
              },
              {
                name: `Location`,
                slug: `location`,
                styleList: {
                  width: `308px`,
                },
              },
              {
                name: `Phone number`,
                slug: `phoneNumber`,
                styleList: {
                  width: `173px`,
                },
              },
              {
                name: `Years in operation`,
                slug: `yearsInOperation`,
                styleList: {
                  width: `96px`,
                },
              },
            ],
          },
        ]
      },
      {
        id: 3,
        customNumber: `3.3`,
        name: `Franchisee Interviews`,
        description: `Next we recommend that you interview franchisees and compare your final impression of the franchise opportunity to your initial impression. Do this for your top two franchisors. `,
        questionsBlocks: [
          {
            id: 0,
            numberTop: 2,
            itemsListAmount: 10,
            isLeftTitle: true,
            leftTitle: `Question`,
            classList: [
              `sticky-header`,
            ],
            leftTitleStyle: {
              width: `300px`,
              textAlign: `left`,
            },
            tabName: `Franchise #1`,
            isTabNamefranchise: true,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            hideTableFooter: true,
            questions: [
              {
                id: 1,
                text: `What support does the franchisor provide to its franchisees?`,
              },
              {
                id: 2,
                text: `Does this franchise create a strong opportunity for financial success?`,
              },
              {
                id: 3,
                text: `Has the franchisor met your expectations?`,
              },
              {
                id: 4,
                text: `Does the franchise operate similar to the FDD description?`,
              },
              {
                id: 5,
                text: `Describe your day as an operator`,
              },
              {
                id: 6,
                text: `Have you experienced marketing success? Challenges?`,
              },
              {
                id: 7,
                text: `How is the technology systems support?`,
              },
              {
                id: 8,
                text: `What are the skill sets required to operate this franchise?`,
              },
              {
                id: 9,
                text: `Where does this franchisee rank in the system? Why?`,
              },
              {
                id: 10,
                text: `Is this franchisee someone I want to emulate?`,
              },
              {
                id: 11,
                text: `Can I replicate the success of high performing franchises?`,
              },
              {
                id: 12,
                index: ``,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_LINE,
                text: 'Total score:',
                fields: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,],
              },
            ],
            fields: [
              {
                id: 1,
                name: `Answer`,
                slug: `answer`,
                classList: `align__right`,
              },
              {
                id: 2,
                name: `Your initial <br/>impression`,
                slug: `initalScore`,
                classList: `align__right`,
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                selectLabel: `0`,
                values: [
                  {
                    text: '1',
                    value: 0.5,
                  },
                  {
                    text: '2',
                    value: 1,
                  },
                  {
                    text: '3',
                    value: 1.5,
                  },
                  {
                    text: '4',
                    value: 2,
                  },
                  {
                    text: '5',
                    value: 2.5
                  },
                ],
              },
              {
                id: 3,
                name: `Impression <br/>after the call`,
                slug: `impressionScore`,
                classList: `align__right`,
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                selectLabel: `0`,
                values: [
                  {
                    text: '1',
                    value: 0.5,
                  },
                  {
                    text: '2',
                    value: 1,
                  },
                  {
                    text: '3',
                    value: 1.5,
                  },
                  {
                    text: '4',
                    value: 2,
                  },
                  {
                    text: '5',
                    value: 2.5
                  },
                ],
              },
            ],
            calc: {
              name: 'Total Score',
              fields: [
                {
                  slug: 'initalScore',
                  type: WORK_BOOK_CALC_TYPE.SUM,
                },
                {
                  slug: 'impressionScore',
                  type: WORK_BOOK_CALC_TYPE.SUM,
                },
              ],
            }
          },
          {
            id: 1,
            numberTop: 2,
            itemsListAmount: 10,
            isLeftTitle: true,
            leftTitle: `Question`,
            classList: [
              `sticky-header`,
            ],
            leftTitleStyle: {
              width: `300px`,
              textAlign: `left`,
            },
            tabName: `Franchise #2`,
            isTabNamefranchise: true,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            hideTableFooter: true,
            questions: [
              {
                id: 1,
                text: `What support does the franchisor provide to its franchisees?`,
              },
              {
                id: 2,
                text: `Does this franchise create a strong opportunity for financial success?`,
              },
              {
                id: 3,
                text: `Has the franchisor met your expectations?`,
              },
              {
                id: 4,
                text: `Does the franchise operate similar to the FDD description?`,
              },
              {
                id: 5,
                text: `Describe your day as an operator`,
              },
              {
                id: 6,
                text: `Have you experienced marketing success? Challenges?`,
              },
              {
                id: 7,
                text: `How is the technology systems support?`,
              },
              {
                id: 8,
                text: `What are the skill sets required to operate this franchise?`,
              },
              {
                id: 9,
                text: `Where does this franchisee rank in the system? Why?`,
              },
              {
                id: 10,
                text: `Is this franchisee someone I want to emulate?`,
              },
              {
                id: 11,
                text: `Can I replicate the success of high performing franchises?`,
              },
              {
                id: 12,
                index: ``,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_LINE,
                text: 'Total score:',
                fields: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,],
              },
            ],
            fields: [
              {
                id: 1,
                name: `Answer`,
                slug: `answer`,
                classList: `align__right`,
              },
              {
                id: 2,
                name: `Your initial <br/>impression`,
                slug: `initalScore`,
                classList: `align__right`,
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                selectLabel: `0`,
                values: [
                  {
                    text: '1',
                    value: 0.5,
                  },
                  {
                    text: '2',
                    value: 1,
                  },
                  {
                    text: '3',
                    value: 1.5,
                  },
                  {
                    text: '4',
                    value: 2,
                  },
                  {
                    text: '5',
                    value: 2.5
                  },
                ],
              },
              {
                id: 3,
                name: `Impression <br/>after the call`,
                slug: `impressionScore`,
                classList: `align__right`,
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                selectLabel: `0`,
                values: [
                  {
                    text: '1',
                    value: 0.5,
                  },
                  {
                    text: '2',
                    value: 1,
                  },
                  {
                    text: '3',
                    value: 1.5,
                  },
                  {
                    text: '4',
                    value: 2,
                  },
                  {
                    text: '5',
                    value: 2.5
                  },
                ],
              },
            ],
            calc: {
              name: 'Total Score',
              fields: [
                {
                  slug: 'initalScore',
                  type: WORK_BOOK_CALC_TYPE.SUM,
                },
                {
                  slug: 'impressionScore',
                  type: WORK_BOOK_CALC_TYPE.SUM,
                },
              ],
            }
          },
        ]
      },
      {
        id: 4,
        customNumber: `3.3.1`,
        name: `Franchisee Total Score`,
        description: `Now, total up the scores for each franchisee. At this point you should be starting to get a good feel for which franchisor is right for you.`,
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_FRANCHISE_CUSTOMERS_VIEWED,
            updateTop: false,
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
              {
                id: 7,
              },
              {
                id: 8,
              },
              {
                id: 9,
              },
              {
                id: 10,
              },
            ],
            fields: [
              {
                name: 'Franchisee',
                slug: 'item',
                valueType: WORK_BOOK_FIELD_VALUE_TYPES.TEN_FRANCHISEE,
                valueTypeIndex: 0,
                cellClassList: [
                  'franchisee-output',
                ],
              },
              {
                name: 'Franchise #1 Scores',
                slug: 'franchise1',
                valueType: WORK_BOOK_FIELD_VALUE_TYPES.TEN_FRANCHISEE_TOTAL,
                valueTypeIndex: 0,
                cellClassList: [
                  'franchisee-output',
                  'franchisee-output__score',
                ],
              },
              {
                name: 'Franchisee',
                slug: 'item',
                valueType: WORK_BOOK_FIELD_VALUE_TYPES.TEN_FRANCHISEE,
                valueTypeIndex: 1,
                cellClassList: [
                  'franchisee-output',
                ],
              },
              {
                name: 'Franchise #2 Scores',
                slug: 'franchise2',
                valueType: WORK_BOOK_FIELD_VALUE_TYPES.TEN_FRANCHISEE_TOTAL,
                valueTypeIndex: 1,
                cellClassList: [
                  'franchisee-output',
                  'franchisee-output__score',
                ],
              },
            ]
          }
        ]
      },
      {
        id: 5,
        customNumber: `3.4`,
        name: `Calculate Revenue Projections`,
        description: `You can turn to our online tool on Franchise123.com to quickly populate these sheets.`,
        questionsBlocks: [
          {
            id: 0,
            numberTop: 2,
            itemsListAmount: 1,
            isLeftTitle: false,
            isLeftDisabled: true,
            tabName: `Franchise #1`,
            isTabNamefranchise: true,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            classList: ['sticky-header'],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
            ],
            fields: [
              {
                id: 1,
                name: `Product type`,
                slug: `productType`,
                styleList: {
                  minWidth: `117px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                hideTotal: true,
              },
              {
                id: 2,
                name: `Revenue per Unit ($)`,
                slug: `revenuePerUnit`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                styleList: {
                  minWidth: `150px`,
                  paddingRight: `16px`,
                },
                cellStyleList: {
                  minWidth: `150px`,
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 3,
                name: `1`,
                slug: `month1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 4,
                name: `2`,
                slug: `month2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 5,
                name: `3`,
                slug: `month3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 6,
                name: `4`,
                slug: `month4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 7,
                name: `5`,
                slug: `month5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 8,
                name: `6`,
                slug: `month6`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 9,
                name: `7`,
                slug: `month7`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 10,
                name: `8`,
                slug: `month8`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 11,
                name: `9`,
                slug: `month9`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 12,
                name: `10`,
                slug: `month10`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 13,
                name: `11`,
                slug: `month11`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 14,
                name: `12`,
                slug: `month12`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
            ],
            calc: {
              name: 'Totals',
              fields: [
                {
                  slug: `revenuePerUnit`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month1`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month2`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month3`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month4`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month5`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month6`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month7`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month8`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month9`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month10`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month11`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month12`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: ``,
                colspan: 1,
              },
              {
                name: `Month to Month Growth Rate (%)`,
                colspan: 12,
              },
            ],
          },
          {
            id: 1,
            numberTop: 2,
            itemsListAmount: 1,
            isLeftTitle: false,
            isLeftDisabled: true,
            tabName: `Franchise #1`,
            isTabNamefranchise: true,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
              {
                id: 4,
              },
              {
                id: 5,
              },
              {
                id: 6,
              },
            ],
            fields: [
              {
                id: 1,
                name: `Product type`,
                slug: `productType`,
                styleList: {
                  minWidth: `117px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                hideTotal: true,
              },
              {
                id: 2,
                name: `Revenue per Unit ($)`,
                slug: `revenuePerUnit`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                styleList: {
                  minWidth: `150px`,
                  paddingRight: `16px`,
                },
                cellStyleList: {
                  minWidth: `150px`,
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 3,
                name: `1`,
                slug: `month1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 4,
                name: `2`,
                slug: `month2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 5,
                name: `3`,
                slug: `month3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 6,
                name: `4`,
                slug: `month4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 7,
                name: `5`,
                slug: `month5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 8,
                name: `6`,
                slug: `month6`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 9,
                name: `7`,
                slug: `month7`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 10,
                name: `8`,
                slug: `month8`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 11,
                name: `9`,
                slug: `month9`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 12,
                name: `10`,
                slug: `month10`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 13,
                name: `11`,
                slug: `month11`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
              {
                id: 14,
                name: `12`,
                slug: `month12`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__center`,
                placeholder: `100`,
                placeholderCounter: false,
                inputStyleList: {
                  width: `48px`,
                  padding: `11px 0px`,
                },
              },
            ],
            calc: {
              name: 'Totals',
              fields: [
                {
                  slug: `revenuePerUnit`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month1`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month2`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month3`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month4`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month5`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month6`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month7`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month8`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month9`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month10`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month11`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `month12`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: ``,
                colspan: 1,
              },
              {
                name: `Month to Month Growth Rate (%)`,
                colspan: 12,
              },
            ],
          },
        ]
      },
      {
        id: 6,
        customNumber: `3.5`,
        name: `Expense Calculations`,
        description: ``,
        questionsBlocks: [
          {
            id: 0,
            numberTop: 2,
            itemsListAmount: 1,
            tableTotal: true,
            classList: [
              `sticky-header`,
              `counter-v2`,
              `horizontal-scroll`,
            ],
            cellClassList: `nowrap`,
            isLeftTitle: true,
            isTabNamefranchise: true,
            leftTitle: `Fixed Recurring`,
            leftTitleStyle: {
              minWidth: `184px`,
              textAlign: `left`,
            },
            tabName: ``,
            type: WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                index: 1,
                text: `Rent`,
              },
              {
                id: 2,
                index: 2,
                text: `Phone and Internet`,
              },
              {
                id: 3,
                index: 3,
                text: `Salaried Employees`,
              },
              {
                id: 4,
                index: 4,
                text: `Administrative Costs`,
              },
              {
                id: 5,
                index: 5,
                text: `Equipment`,
              },
              {
                id: 6,
                index: 6,
                text: `Loan Payments`,
              },
              {
                id: 7,
                index: 7,
                text: `Vehicle`,
              },
              {
                id: 8,
                index: 8,
                text: `Other`,
              },
              {
                id: 9,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Total Fixed ($)',
                fields: [1, 2, 3, 4, 5, 6, 7, 8],
              },
              {
                id: 10,
                type: WORK_BOOK_FIELD_TYPES.TITLE,
                text: 'Variable',
              },
              {
                id: 11,
                index: 1,
                text: `Hourly Employees`,
              },
              {
                id: 12,
                index: 2,
                text: `Royalty Fees`,
              },
              {
                id: 13,
                index: 3,
                text: `Marketing Fees`,
              },
              {
                id: 14,
                index: 4,
                text: `Credit Card Processing`,
              },
              {
                id: 15,
                index: 5,
                text: `Utilities`,
              },
              {
                id: 16,
                index: 6,
                text: `Other Variable`,
              },
              {
                id: 17,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Total Variable ($)',
                fields: [11, 12, 13, 14, 15, 16],
              },
            ],
            fields: [
              {
                id: 1,
                name: `1`,
                slug: `month1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 2,
                name: `2`,
                slug: `month2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 3,
                name: `3`,
                slug: `month3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 4,
                name: `4`,
                slug: `month4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 5,
                name: `5`,
                slug: `month5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 6,
                name: `6`,
                slug: `month6`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 7,
                name: `7`,
                slug: `month7`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 8,
                name: `8`,
                slug: `month8`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 9,
                name: `9`,
                slug: `month9`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 10,
                name: `10`,
                slug: `month10`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 11,
                name: `11`,
                slug: `month11`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 12,
                name: `12`,
                slug: `month12`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
            ],
            calc: {
              name: 'Total Expenses ($)',
              fields: [
                {
                  slug: `month1`,
                  classList: `align__center`,
                },
                {
                  slug: `month2`,
                  classList: `align__center`,
                },
                {
                  slug: `month3`,
                  classList: `align__center`,
                },
                {
                  slug: `month4`,
                  classList: `align__center`,
                },
                {
                  slug: `month5`,
                  classList: `align__center`,
                },
                {
                  slug: `month6`,
                  classList: `align__center`,
                },
                {
                  slug: `month7`,
                  classList: `align__center`,
                },
                {
                  slug: `month8`,
                  classList: `align__center`,
                },
                {
                  slug: `month9`,
                  classList: `align__center`,
                },
                {
                  slug: `month10`,
                  classList: `align__center`,
                },
                {
                  slug: `month11`,
                  classList: `align__center`,
                },
                {
                  slug: `month12`,
                  classList: `align__center`,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: `Monthly Expense ($)`,
                colspan: 12,
              },
            ],
          },
          {
            id: 1,
            numberTop: 2,
            itemsListAmount: 1,
            tableTotal: true,
            classList: [
              `sticky-header`,
              `counter-v2`,
              `horizontal-scroll`,
            ],
            isLeftTitle: true,
            isTabNamefranchise: true,
            leftTitle: `Fixed Recurring`,
            leftTitleStyle: {
              minWidth: `184px`,
              textAlign: `left`,
            },
            tabName: ``,
            type: WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                index: 1,
                text: `Rent`,
              },
              {
                id: 2,
                index: 2,
                text: `Phone and Internet`,
              },
              {
                id: 3,
                index: 3,
                text: `Salaried Employees`,
              },
              {
                id: 4,
                index: 4,
                text: `Administrative Costs`,
              },
              {
                id: 5,
                index: 5,
                text: `Equipment`,
              },
              {
                id: 6,
                index: 6,
                text: `Loan Payments`,
              },
              {
                id: 7,
                index: 7,
                text: `Vehicle`,
              },
              {
                id: 8,
                index: 8,
                text: `Other`,
              },
              {
                id: 9,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Total Fixed ($)',
                fields: [1, 2, 3, 4, 5, 6, 7, 8],
              },
              {
                id: 10,
                type: WORK_BOOK_FIELD_TYPES.TITLE,
                text: 'Variable',
              },
              {
                id: 11,
                index: 1,
                text: `Hourly Employees`,
              },
              {
                id: 12,
                index: 2,
                text: `Royalty Fees`,
              },
              {
                id: 13,
                index: 3,
                text: `Marketing Fees`,
              },
              {
                id: 14,
                index: 4,
                text: `Credit Card Processing`,
              },
              {
                id: 15,
                index: 5,
                text: `Utilities`,
              },
              {
                id: 16,
                index: 6,
                text: `Other Variable`,
              },
              {
                id: 17,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Total Variable ($)',
                fields: [11, 12, 13, 14, 15, 16],
              },
            ],
            fields: [
              {
                id: 1,
                name: `1`,
                slug: `month1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 2,
                name: `2`,
                slug: `month2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 3,
                name: `3`,
                slug: `month3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 4,
                name: `4`,
                slug: `month4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 5,
                name: `5`,
                slug: `month5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 6,
                name: `6`,
                slug: `month6`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 7,
                name: `7`,
                slug: `month7`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 8,
                name: `8`,
                slug: `month8`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 9,
                name: `9`,
                slug: `month9`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 10,
                name: `10`,
                slug: `month10`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 11,
                name: `11`,
                slug: `month11`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 12,
                name: `12`,
                slug: `month12`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
            ],
            calc: {
              name: '',
              fields: [
                {
                  slug: `month1`,
                  classList: `align__center`,
                },
                {
                  slug: `month2`,
                  classList: `align__center`,
                },
                {
                  slug: `month3`,
                  classList: `align__center`,
                },
                {
                  slug: `month4`,
                  classList: `align__center`,
                },
                {
                  slug: `month5`,
                  classList: `align__center`,
                },
                {
                  slug: `month6`,
                  classList: `align__center`,
                },
                {
                  slug: `month7`,
                  classList: `align__center`,
                },
                {
                  slug: `month8`,
                  classList: `align__center`,
                },
                {
                  slug: `month9`,
                  classList: `align__center`,
                },
                {
                  slug: `month10`,
                  classList: `align__center`,
                },
                {
                  slug: `month11`,
                  classList: `align__center`,
                },
                {
                  slug: `month12`,
                  classList: `align__center`,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: `Monthly Expense ($)`,
                colspan: 12,
              },
            ],
          },
        ]
      },
      {
        id: 7,
        customNumber: `3.6`,
        name: `Financial Goals`,
        description: ``,
        tabsType: WORK_BOOK_TAB_TYPE.LIST,
        questionsBlocks: [
          {
            id: 0,
            numberTop: 1,
            classList: [
              `counter-v2`,
              `horizontal-scroll`,
            ],
            cellClassList: `nowrap`,
            leftTitleStyle: {
              minWidth: `150px`,
            },
            tabName: `Profit and Loss Summary`,
            type: WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                index: 1,
                text: `Revenue`,
              },
              {
                id: 2,
                index: 2,
                negative: true,
                text: `Expenses`,
              },
              {
                id: 3,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Profit/Loss',
                fields: [1, 2,],
              },
              {
                id: 4,
                index: ``,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_LINE,
                text: 'Total Profit/Loss for first 12 months:',
                fields: [1, 2],
              },
            ],
            fields: [
              {
                id: 1,
                name: `1`,
                slug: `month1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 2,
                name: `2`,
                slug: `month2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 3,
                name: `3`,
                slug: `month3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 4,
                name: `4`,
                slug: `month4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 5,
                name: `5`,
                slug: `month5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 6,
                name: `6`,
                slug: `month6`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 7,
                name: `7`,
                slug: `month7`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 8,
                name: `8`,
                slug: `month8`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 9,
                name: `9`,
                slug: `month9`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 10,
                name: `10`,
                slug: `month10`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 11,
                name: `11`,
                slug: `month11`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 12,
                name: `12`,
                slug: `month12`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
            ],
            calc: {
              name: `Total Expenses ($)`,
              styleList: {
                opacity: 0,
              },
              fields: [
                {
                  slug: `month1`,
                  classList: `align__center`,
                },
                {
                  slug: `month2`,
                  classList: `align__center`,
                },
                {
                  slug: `month3`,
                  classList: `align__center`,
                },
                {
                  slug: `month4`,
                  classList: `align__center`,
                },
                {
                  slug: `month5`,
                  classList: `align__center`,
                },
                {
                  slug: `month6`,
                  classList: `align__center`,
                },
                {
                  slug: `month7`,
                  classList: `align__center`,
                },
                {
                  slug: `month8`,
                  classList: `align__center`,
                },
                {
                  slug: `month9`,
                  classList: `align__center`,
                },
                {
                  slug: `month10`,
                  classList: `align__center`,
                },
                {
                  slug: `month11`,
                  classList: `align__center`,
                },
                {
                  slug: `month12`,
                  classList: `align__center`,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: `Month`,
                colspan: 12,
              },
            ],
          },
          {
            id: 1,
            numberTop: 1,
            classList: [
              `counter-v2`,
              `horizontal-scroll`,
            ],
            cellClassList: `nowrap`,
            leftTitleStyle: {
              width: `200px`,
            },
            tabName: `Revenue`,
            type: WORK_BOOK_QUESTION_TYPES.TOP_ONE_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                index: 1,
                text: `Initial Revenue Goal<br><span>(from section 1)</span>`,
                negative: true,
              },
              {
                id: 2,
                index: 2,
                text: `Revenue Projection`,
              },
              {
                id: 3,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'Difference',
                fields: [1, 2,],
              },
              {
                id: 4,
                index: ``,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_LINE,
                text: 'Total difference for first 5 years:',
                fields: [1, 2],
              },
            ],
            fields: [
              {
                id: 1,
                name: `1`,
                slug: `year1`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 1,
                name: `2`,
                slug: `year2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 1,
                name: `3`,
                slug: `year3`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 1,
                name: `4`,
                slug: `year4`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
              {
                id: 1,
                name: `5`,
                slug: `year5`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: [
                  `align__right`,
                  `padding-16`,
                ],
                cellClassList: `nowrap`,
                inputStyleList: {
                  textAlign: `right`,
                },
                placeholder: `0`,
                placeholderCounter: false,
              },
            ],
            calc: {
              name: '',
              styleList: {
                opacity: `0`,
              },
              fields: [
                {
                  slug: `year1`,
                  classList: `align__center`,
                },
                {
                  slug: `year2`,
                  classList: `align__center`,
                },
                {
                  slug: `year3`,
                  classList: `align__center`,
                },
                {
                  slug: `year4`,
                  classList: `align__center`,
                },
                {
                  slug: `year5`,
                  classList: `align__center`,
                },
              ],
            },
            extraTableHeader: [
              {
                name: ``,
                colspan: 2,
              },
              {
                name: `Year`,
                colspan: 5,
              },
            ],
          },
        ]
      },
      {
        id: 8,
        customNumber: `3.7`,
        name: `Break-even Analysis`,
        description: ``,
        questionsBlocks: [
          {
            id: 1,
            numberTop: 2,
            type: WORK_BOOK_QUESTION_TYPES.MATH_QUESTION,
            isTabNamefranchise: true,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Total Cash Returned / Total Investment = Return on Investment`,
                totalText: `Return on Investment (%)`,
                totalPostfix: ``,
                formula: [
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 10,
                    slug: `totalCashReturned`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Cash Returned ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `/`
                  },
                  {
                    id: 11,
                    slug: `totalInvestment`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Investment ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ],
              },
              {
                id: 2,
                text: `Fixed Costs / (Sales Price per Unit - Variable Costs per Unit) = Sales Goal for Break-even`,
                totalText: `Sales Goal for Break-even`,
                totalPostfix: ``,
                formula: [
                  {
                    id: 10,
                    slug: `fixedCosts`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Fixed Costs`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `/`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 11,
                    slug: `salesPricePerUnit`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Sales Price per Unit`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 11,
                    slug: `variableCostsPerUnit`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Variable Costs per Unit`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ],
              },
              {
                id: 3,
                text: `Net Sales - Total Operating Expenses = Operating Income`,
                totalText: `Operating Income ($) <br/>&nbsp;`,
                totalPostfix: ``,
                totalStyle: {
                  width: `167px`,
                },
                formula: [
                  {
                    id: 1,
                    slug: `netSales`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Net Sales ($) <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 2,
                    slug: `totalOperatingExpenses`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Operating <br/>Expenses ($)`
                  },
                ],
              },
              {
                id: 4,
                text: `(Operating Income + Interest Income) - (Interest Expense + Other Expenses) = Total Expenses`,
                totalText: `Total Expenses ($)`,
                totalPostfix: ``,
                totalStyle: {
                  width: `150px`,
                },
                formula: [
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 1,
                    slug: `operatingIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Operating Income ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 2,
                    slug: `interestIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Interest Income ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 4,
                    slug: `interestExpense`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Interest Expense ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 5,
                    slug: `otherExpenses`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Other Expenses ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ]
              },
              {
                id: 5,
                text: `Pre-tax Income - (Income Tax Provision + Equity-method Investment Activity) = Net Income`,
                totalText: `Net Income ($) <br>&nbsp;`,
                totalPostfix: ``,
                totalStyle: {
                  width: `125px`,
                },
                formula: [
                  {
                    id: 1,
                    slug: `preTaxIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Pre-tax Income <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 4,
                    slug: `incomeTaxProvision`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Income Tax Provision <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 5,
                    slug: `equityMethodInvestmentActivity`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Equity-method <br/>Investment Activity`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ]
              },
            ],
            fields: [
              {
                name: 'Formula',
                slug: 'formula',
              }
            ]
          },
          {
            id: 2,
            numberTop: 2,
            type: WORK_BOOK_QUESTION_TYPES.MATH_QUESTION,
            isTabNamefranchise: true,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Total Cash Returned / Total Investment = Return on Investment`,
                totalText: `Return on Investment (%)`,
                totalPostfix: ``,
                formula: [
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 10,
                    slug: `totalCashReturned`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Cash Returned ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `/`
                  },
                  {
                    id: 11,
                    slug: `totalInvestment`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Investment ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ],
              },
              {
                id: 2,
                text: `Fixed Costs / (Sales Price per Unit - Variable Costs per Unit) = Sales Goal for Break-even`,
                totalText: `Sales Goal for Break-even`,
                totalPostfix: ``,
                formula: [
                  {
                    id: 10,
                    slug: `fixedCosts`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Fixed Costs`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `/`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 11,
                    slug: `salesPricePerUnit`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Sales Price per Unit`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 11,
                    slug: `variableCostsPerUnit`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Variable Costs per Unit`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ],
              },
              {
                id: 3,
                text: `Net Sales - Total Operating Expenses = Operating Income`,
                totalText: `Operating Income ($) <br/>&nbsp;`,
                totalPostfix: ``,
                totalStyle: {
                  width: `167px`,
                },
                formula: [
                  {
                    id: 1,
                    slug: `netSales`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Net Sales ($) <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 2,
                    slug: `totalOperatingExpenses`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Total Operating <br/>Expenses ($)`
                  },
                ],
              },
              {
                id: 4,
                text: `(Operating Income + Interest Income) - (Interest Expense + Other Expenses) = Total Expenses`,
                totalText: `Total Expenses ($)`,
                totalPostfix: ``,
                totalStyle: {
                  width: `150px`,
                },
                formula: [
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 1,
                    slug: `operatingIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Operating Income ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 2,
                    slug: `interestIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Interest Income ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 4,
                    slug: `interestExpense`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Interest Expense ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 5,
                    slug: `otherExpenses`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Other Expenses ($)`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ]
              },
              {
                id: 5,
                text: `Pre-tax Income - (Income Tax Provision + Equity-method Investment Activity) = Net Income`,
                totalText: `Net Income ($) <br>&nbsp;`,
                totalPostfix: ``,
                totalStyle: {
                  width: `125px`,
                },
                formula: [
                  {
                    id: 1,
                    slug: `preTaxIncome`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Pre-tax Income <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `-`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `(`
                  },
                  {
                    id: 4,
                    slug: `incomeTaxProvision`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Income Tax Provision <br/>&nbsp;`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `+`
                  },
                  {
                    id: 5,
                    slug: `equityMethodInvestmentActivity`,
                    type: WORK_BOOK_CALC_TYPE.NUMBER,
                    text: `Equity-method <br/>Investment Activity`
                  },
                  {
                    id: 0,
                    slug: ``,
                    type: WORK_BOOK_CALC_TYPE.OPERATOR,
                    text: `)`
                  },
                ]
              },
            ],
            fields: [
              {
                name: 'Formula',
                slug: 'formula',
              }
            ]
          },
        ]
      },
      {
        id: 9,
        customNumber: `3.8`,
        name: `Financial Worksheet`,
        description: ``,
        questionsBlocks: [
          {
            id: 0,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            numberTop: 2,
            itemsListAmount: 1,
            classList: [
              `table-width-500`,
              `counter-v2`,
            ],
            leftTitle: `Item`,
            isLeftTitle: true,
            leftTitleStyle: {
              textAlign: `left`,
              maxWidth: `320px`,
            },
            isTabNamefranchise: true,
            hideTableFooter: true,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Current monthly income`,
                index: `1`,
              },
              {
                id: 2,
                text: `Other sources of new income if applicable `,
                index: `2`,
              },
              {
                id: 3,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'New monthly income',
                extraText: `(add 1 and 2)`,
                fields: [1, 2],
                index: `3`,
              },
              {
                id: 4,
                text: `Current expenses`,
                negative: true,
                index: `4`,
              },
              {
                id: 5,
                text: `Other adjusted expenses`,
                index: `5`,
              },
              {
                id: 6,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'New current expenses',
                extraText: `(subtract 4 from 5)`,
                negative: true,
                fields: [4, 5],
                index: `6`,
              },
              {
                id: 7,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_CALCULATED,
                text: 'Adjusted monthly net savings',
                extraText: `(subtract 6 from 3)`,
                fields: [3, 6],
                index: `7`,
              },
            ],
            fields: [
              {
                name: 'Amount ($)',
                slug: 'amount',
                styleList: {
                  textAlign: `left`,
                  width: `112px`,
                },
                placeholder: `0`,
                placeholderCounter: false,
                inputStyleList: {
                  textAlign: `right`,
                },
              },
            ],
            calc: {},
          },
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            numberTop: 2,
            itemsListAmount: 1,
            classList: [
              `table-width-500`,
              `counter-v2`,
            ],
            leftTitle: `Item`,
            isLeftTitle: true,
            leftTitleStyle: {
              textAlign: `left`,
              maxWidth: `320px`,
            },
            isTabNamefranchise: true,
            hideTableFooter: true,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Current monthly income`,
                index: `1`,
              },
              {
                id: 2,
                text: `Other sources of new income if applicable `,
                index: `2`,
              },
              {
                id: 3,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'New monthly income',
                extraText: `(add 1 and 2)`,
                fields: [1, 2],
                index: `3`,
              },
              {
                id: 4,
                text: `Current expenses`,
                negative: true,
                index: `4`,
              },
              {
                id: 5,
                text: `Other adjusted expenses`,
                index: `5`,
              },
              {
                id: 6,
                type: WORK_BOOK_FIELD_TYPES.TOTAL,
                text: 'New current expenses',
                extraText: `(subtract 4 from 5)`,
                negative: true,
                fields: [4, 5],
                index: `6`,
              },
              {
                id: 7,
                type: WORK_BOOK_FIELD_TYPES.TOTAL_CALCULATED,
                text: 'Adjusted monthly net savings',
                extraText: `(subtract 6 from 3)`,
                fields: [3, 6],
                index: `7`,
              },
            ],
            fields: [
              {
                name: 'Amount ($)',
                slug: 'amount',
                styleList: {
                  textAlign: `left`,
                  width: `112px`,
                },
                placeholder: `0`,
                placeholderCounter: false,
                inputStyleList: {
                  textAlign: `right`,
                },
              },
            ],
            calc: {},
          },
        ]
      },
      {
        id: 10,
        customNumber: `3.9`,
        name: `Monthly Budget`,
        description: ``,
        tabsStatus: false,
        questionsBlocks: [
          {
            id: 0,
            numberTop: 1,
            isLeftDisabled: true,
            classList: [
              `overflow-visible`,
              `monthly-budget`,
            ],
            leftTitleStyle: {
              textAlign: `left`,
              minWidth: `250px`,
            },
            tabName: ``,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
              },
              {
                id: 2,
              },
              {
                id: 3,
              },
            ],
            fields: [
              {
                id: 1,
                name: `Category`,
                slug: `category`,
                placeholder: `Income Source`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                hideTotal: true,
                cellStyleList: {
                  minWidth: `150px`,
                },
                inputStyleList: {},
                classList: [
                  `align__left`,
                  `padding-24`,
                ],
              },
              {
                id: 2,
                name: `Budget ($)`,
                slug: `budget`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                cellStyleList: {
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 3,
                name: `Actual ($)`,
                slug: `actual`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                cellStyleList: {
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 4,
                name: `Difference ($)`,
                slug: `difference`,
                type: WORK_BOOK_FIELD_TYPES.DIFFERENCE,
                fieldList: [
                  {
                    id: 1,
                    negative: true,
                  },
                  {
                    id: 2,
                    negative: false,
                  },
                ],
                cellStyleList: {
                  paddingRight: `16px`,
                  width: `150px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
            ],
            calc: {
              name: 'Total Monthly Income',
              fields: [
                {
                  slug: `budget`,
                },
                {
                  slug: `actual`,
                },
                {
                  slug: `difference`,
                },
              ],
            },
            extraTableHeader: [],
          },
          {
            id: 1,
            numberTop: 1,
            isLeftTitle: true,
            leftTitle: ``,
            leftTitleStyle: {
              minWidth: `250px`,
              textAlign: `left`,
            },
            classList: `overflow-visible`,
            hideTableHeader: true,
            tabName: ``,
            type: WORK_BOOK_QUESTION_TYPES.TOP_TEN_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Rent/Mortgage`,
              },
              {
                id: 2,
                text: `Equipment/Supplies`,
              },
              {
                id: 3,
                text: `Payroll and Benefits`,
              },
              {
                id: 4,
                text: `Advertising/Marketing`,
              },
              {
                id: 5,
                text: `Utilities`,
              },
              {
                id: 6,
                text: `Phone and Internet`,
              },
              {
                id: 7,
                text: `Insurance`,
              },
              {
                id: 8,
                text: `Auto`,
              },
              {
                id: 9,
                text: `Services and Maintenance`,
              },
              {
                id: 10,
                text: `Travel Expenses`,
              },
              {
                id: 11,
                text: `Misc. Expenses`,
              },
            ],
            fields: [
              {
                id: 1,
                name: `Budget`,
                slug: `budget`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                cellStyleList: {
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 2,
                name: `Actual`,
                slug: `actual`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                cellStyleList: {
                  paddingRight: `16px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
              {
                id: 3,
                name: `Difference`,
                slug: `difference`,
                type: WORK_BOOK_FIELD_TYPES.DIFFERENCE,
                fieldList: [
                  {
                    id: 0,
                    negative: false,
                  },
                  {
                    id: 1,
                    negative: true,
                  },
                ],
                cellStyleList: {
                  paddingRight: `16px`,
                  width: `150px`,
                },
                inputStyleList: {
                  textAlign: `right`,
                },
                classList: [
                  `align__right`,
                  `padding-24`,
                ],
              },
            ],
            calc: {
              name: 'Total Monthly Expenses',
              fields: [
                {
                  slug: `budget`,
                },
                {
                  slug: `actual`,
                },
                {
                  slug: `difference`,
                },
              ],
            },
            extraTableHeader: [],
          },
        ],
      },
      {
        id: 11,
        customNumber: `3.10`,
        name: `Financial Ranking System`,
        description: ``,
        questionsBlocks: [
          {
            id: 1,
            numberTop: 1,
            leftTitle: `Franchisor Metrics`,
            isLeftTitle: true,
            leftTitleStyle: {
              textAlign: `left`,
            },
            type: WORK_BOOK_QUESTION_TYPES.TOP_TWO_ENTRY_CUSTOM,
            scoring: [],
            questions: [
              {
                id: 1,
                text: `Year 1 profit or loss`,
              },
              {
                id: 2,
                text: `Operational break-even`,
              },
              {
                id: 3,
                text: `Total investment break-even`,
              },
              {
                id: 4,
                text: `5 year goal`,
              },
              {
                id: 5,
                text: `5 year projections`,
              },
            ],
            fields: [
              {
                id: 1,
                name: `Score (1-5)`,
                slug: `franchise1`,
                classList: `align__right`,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TWO,
                  index: 'franchise',
                  id: 0,
                }
              },
              {
                id: 2,
                name: `Score (1-5)`,
                slug: `franchise2`,
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                classList: `align__right`,
                relation: {
                  key: WORK_BOOK_TOP_LIST_KEYS.TWO,
                  index: 'franchise',
                  id: 1,
                }
              },
            ],
            calc: {
              name: 'Total Ranking',
              fields: [
                {
                  slug: `revenuePerUnit`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `franchise1`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
                {
                  slug: `franchise2`,
                  type: WORK_BOOK_FIELD_TYPES.NUMBER,
                },
              ],
            },
            extraTableHeader: [],
          },
        ]
      },
      {
        id: 12,
        customNumber: `3.11`,
        name: `Winning Franchise`,
        description: `Now it’s time to pick your franchise!`,
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.SELECT_TOP_ONE,
            scoring: [],
            questions: [],
            fields: []
          },
        ]
      },
    ],
  },
  {
    id: 4,
    name: 'Launch, grow and thrive',
    chapters: [
      {
        id: 1,
        name: 'Site selection',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY,
            description: 'Site Selection Demographics (Score 1-5)',
            tabName: 'Site Selection Demographics',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
            ],
            fields: [
              {
                name: 'Site',
                slug: 'site',
              },
              {
                name: 'Total traffic',
                slug: 'totalTraffic',
              },
              {
                name: 'Competition within 5 miles',
                slug: 'competitionWithinFiveMiles',
              },
              {
                name: 'Average household income',
                slug: 'averageHouseholdIncome',
              },
              {
                name: 'Population within 5 miles',
                slug: 'populationWithinFiveMiles',
              },
              {
                name: 'Drive time to city center',
                slug: 'driveTimeToCityCenter',
              },
            ]
          },
          {
            id: 2,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_EIGHT_ENTRY,
            description: 'Site Selection Economics (Score 1-5) /RE Class B: 3pts, Class A: 2pts, Class C/D: 1pt',
            tabName: 'Site Selection Economics',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
            ],
            fields: [
              {
                name: 'Site',
                slug: 'site',
              },
              {
                name: 'Total SF',
                slug: 'totalSf',
              },
              {
                name: 'Cost per SF',
                slug: 'costPerSf',
              },
              {
                name: 'Total annual cost',
                slug: 'totalAnnualCost',
              },
              {
                name: 'Tenant improvement allowance',
                slug: 'tenantImprovementAllowance',
              },
              {
                name: 'Lease term',
                slug: 'leaseTerm',
              },
              {
                name: 'Signage costs',
                slug: 'signageCosts',
              },
              {
                name: 'Real estate class',
                slug: 'realEstateClass',
                type: WORK_BOOK_FIELD_TYPES.SELECT,
                values: [

                  {
                    text: 'Class A',
                    value: 2
                  },
                  {
                    text: 'Class B',
                    value: 3
                  },
                  {
                    text: 'Class C/D',
                    value: 1
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        id: 2,
        name: 'Develop your culture',
        description: 'Select your top ten and your top three traits to strive for within your culture.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_TEN,
            description: '',
            tabName: 'Company Culture Traits - Top 10',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
            ],
            fields: [
              {
                name: '',
                slug: 'text',
              },
            ]
          },
          {
            id: 2,
            type: WORK_BOOK_QUESTION_TYPES.CULTURE_TRAITS_TOP_THREE,
            description: '',
            tabName: 'Company Culture Traits - Top 3',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
            ],
            fields: [
              {
                name: '',
                slug: 'text',
              },
            ]
          },
        ]
      },
      {
        id: 3,
        name: 'Hiring your team',
        description: 'Rate the candidate’s skills and experience from 1-5 based on how closely they match the needs of the position.',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.CLOSED_QUESTION_WITH_SCORE_CANDIDATES,
            scoring: [],
            questions: [
              {id: 1, text: 'What is the candidate’s educational history?',},
              {id: 2, text: 'Prior work experience, particularly in this role or industry?',},
              {
                id: 3,
                text: 'Does the candidate possess any and all necessary certifications (or do they have a clear path to obtain them within a reasonable time frame)?',
              },
              {id: 4, text: 'What are the long-term goals of the candidate?',},
              {
                id: 5,
                text: 'Do their strengths and weaknesses align with the job duties they will be expected to perform?',
              },
              {
                id: 6,
                text: 'Has the candidate appropriately handled past conflicts or disagreements at work (examples)?',
              },
              {
                id: 7,
                text: 'Have they been able to correct and learn from past mistakes (examples)?',
              },
              {id: 8, text: 'What draws them to your company?',},
            ],
            questionsTotal: [
              {id: 1, text: 'Education',},
              {id: 2, text: 'Prior experience',},
              {id: 3, text: 'Necessary certifications',},
              {id: 4, text: 'Long-term goals',},
              {id: 5, text: 'Strengths and weaknesses',},
              {id: 6, text: 'Conflict resolution',},
              {id: 7, text: 'Properly handling and mistakes',},
              {id: 8, text: 'What draws them to your company?',},
            ],
            fields: []
          }
        ]
      },
      {
        id: 4,
        name: 'Launch checklist',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_TOP_ONE_ENTRY_CENTERED,
            scoring: [],
            classList: ['centered', 'sticky'],
            showTotal: false,
            questionsTitle: 'Item',
            questions: [
              {id: 1, text: 'Signing the Franchise Agreement'},
              {id: 2, text: 'Franchisee onboarding'},
              {id: 3, text: 'Site selection'},
              {id: 4, text: 'Letter of Intent'},
              {id: 5, text: 'Business training'},
              {id: 6, text: 'Business planning'},
              {id: 7, text: 'Marketing strategy'},
              {id: 8, text: 'Sign lease'},
              {id: 9, text: 'IP order'},
              {id: 10, text: 'Marketing order'},
              {id: 11, text: 'Begin construction'},
              {id: 12, text: 'Launch marketing'},
              {id: 13, text: 'Site buildout completion'},
              {id: 14, text: 'Installations'},
              {id: 15, text: 'Presales (if applicable)'},
              {id: 16, text: 'Final marketing push before opening'},
              {id: 17, text: 'Early access/soft opening'},
              {id: 18, text: 'Grand opening'},
            ],
            fields: [
              {
                name: 'Target Date',
                slug: 'targetDate',
                type: WORK_BOOK_FIELD_TYPES.DATE,
              },
              {
                name: 'Date Completed',
                slug: 'dateCompleted',
                type: WORK_BOOK_FIELD_TYPES.DATE,
              },
            ]
          }
        ]
      },
      {
        id: 5,
        name: 'Local marketing tool kit',
        description: 'Local Marketing Strategy',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.LOCAL_MARKETING_TOOLKIT,
            scoring: [],
            questionsTitle: '',
            questions: [
              {id: 1, text: 'Digital Advertising'},
              {id: 2, text: 'Offline Advertising'},
              {id: 3, text: 'Site selection'},
            ],
            fields: [
              {
                name: 'Vendor',
                slug: 'vendor',
                type: WORK_BOOK_FIELD_TYPES.TEXT,
                styleList: {
                  width: `158px`,
                },
              },
              {
                name: 'Service(s)',
                slug: 'service',
                type: WORK_BOOK_FIELD_TYPES.TEXT,
                styleList: {
                  width: `158px`,
                },
              },
              {
                name: 'Cost ($)',
                slug: 'cost',
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                styleList: {
                  width: `158px`,
                },
              },
              {
                name: 'Direct Acquisition Vendor? ($)',
                slug: 'directAcquisitionVendor',
                type: WORK_BOOK_FIELD_TYPES.NUMBER,
                styleList: {
                  width: `158px`,
                },
              },
              {
                name: 'Estimated Acquisition Cost',
                slug: 'estimatedAcquisitionCost',
                type: WORK_BOOK_FIELD_TYPES.RADIO,
                styleList: {
                  width: `190px`,
                },
                values: [
                  {
                    text: 'Yes',
                    value: 'yes',
                  },
                  {
                    text: 'No',
                    value: 'no',
                  },
                ]
              },
            ]
          }
        ]
      },
      {
        id: 6,
        name: 'Your 90-day plan',
        description: '',
        questionsBlocks: [
          {
            id: 1,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_ONE_ENTRY,
            description: 'What is your primary goal for your first 90 days of operation?',
            tabName: '90-Day Goal',
            scoring: [],
            questions: [
              {id: 1},
            ],
            fields: [
              {
                name: '',
                slug: 'goal',
              },
            ]
          },
          {
            id: 2,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_THREE_ENTRY,
            description: 'These should directly support your 90-day goal.',
            tabName: 'Monthly Goals',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
            ],
            fields: [
              {
                name: '',
                slug: 'goal',
              },
            ]
          },
          {
            id: 3,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_TWELVE_ENTRY,
            description: 'These should help you achieve your monthly goals and 90-day goal.',
            tabName: 'Weekly Goals',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
              {id: 11},
              {id: 12},
            ],
            fields: [
              {
                name: '',
                slug: 'goal',
              },
            ]
          },
          {
            id: 4,
            type: WORK_BOOK_QUESTION_TYPES.OPEN_QUESTION_ALT_TOP_NINETY_ENTRY,
            description: 'Setting a clear goal each day will keep you on track to reach your weekly, monthly, and 90-day targets.',
            tabName: 'Daily Goals',
            scoring: [],
            questions: [
              {id: 1},
              {id: 2},
              {id: 3},
              {id: 4},
              {id: 5},
              {id: 6},
              {id: 7},
              {id: 8},
              {id: 9},
              {id: 10},
              {id: 11},
              {id: 12},
              {id: 13},
              {id: 14},
              {id: 15},
              {id: 16},
              {id: 17},
              {id: 18},
              {id: 19},
              {id: 20},
              {id: 21},
              {id: 22},
              {id: 23},
              {id: 24},
              {id: 25},
              {id: 26},
              {id: 27},
              {id: 28},
              {id: 29},
              {id: 30},
              {id: 31},
              {id: 32},
              {id: 33},
              {id: 34},
              {id: 35},
              {id: 36},
              {id: 37},
              {id: 38},
              {id: 39},
              {id: 40},
              {id: 41},
              {id: 42},
              {id: 43},
              {id: 44},
              {id: 45},
              {id: 46},
              {id: 47},
              {id: 48},
              {id: 49},
              {id: 50},
              {id: 51},
              {id: 52},
              {id: 53},
              {id: 54},
              {id: 55},
              {id: 56},
              {id: 57},
              {id: 58},
              {id: 59},
              {id: 60},
              {id: 61},
              {id: 62},
              {id: 63},
              {id: 64},
              {id: 65},
              {id: 66},
              {id: 67},
              {id: 68},
              {id: 69},
              {id: 70},
              {id: 71},
              {id: 72},
              {id: 73},
              {id: 74},
              {id: 75},
              {id: 76},
              {id: 77},
              {id: 78},
              {id: 79},
              {id: 80},
              {id: 81},
              {id: 82},
              {id: 83},
              {id: 84},
              {id: 85},
              {id: 86},
              {id: 87},
              {id: 88},
              {id: 89},
              {id: 90},
            ],
            fields: [
              {
                name: '',
                slug: 'goal',
              },
            ]
          },
          {
            id: 4,
            type: WORK_BOOK_QUESTION_TYPES.CONGRATULATIONS,
            description: '',
            tabName: 'Congratulations',
            scoring: [],
            questions: [],
            fields: []
          },
        ]
      },
    ],
  },
]

export const WORK_BOOK_COUNTABLE_CHAPTER = {
  'TEN': [
    {
      sectionId: 2,
      chapterId: 2,
      blockId: 1,
    },
    {
      sectionId: 2,
      chapterId: 5,
      blockId: 1,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 1,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 2,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 3,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 4,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 5,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 6,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 7,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 8,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 9,
    },
    {
      sectionId: 2,
      chapterId: 6,
      blockId: 10,
    },
  ],
  'FIVE': [{
    sectionId: 2,
    chapterId: 8,
    blockId: 1,
  }],
  'THREE': [{
    sectionId: 2,
    chapterId: 8,
    blockId: 1,
  }],
  'ONE': [{
    sectionId: 3,
    chapterId: 11,
    blockId: 1,
  }],
}

export const WORK_BOOK_CHAPTER_RULE_TYPES = {
  'EVERYTHING_IS_FULL': 'EVERYTHING_IS_FULL',
  'EVERYTHING_IS_FULL_WITH_HIGHLIGHTED_THREE': 'EVERYTHING_IS_FULL_WITH_HIGHLIGHTED_THREE',
  'MIN_ONE': 'MIN_ONE',
  'MIN_ONE_FRANCHISEE': 'MIN_ONE_FRANCHISEE',
  'MIN_TWO_CANDIDATES': 'MIN_TWO_CANDIDATES',
  'ALLOW': 'ALLOW',
  'FRANCHISE_TOP_TEN': 'FRANCHISE_TOP_TEN',
  'FRANCHISE_TOP_FIVE': 'FRANCHISE_TOP_FIVE',
  'FRANCHISE_TOP_THREE': 'FRANCHISE_TOP_THREE',
  'FRANCHISE_TOP_TWO': 'FRANCHISE_TOP_TWO',
  'FRANCHISE_TOP_ONE': 'FRANCHISE_TOP_ONE',
  'FRANCHISE_CUSTOMER_TOP_TEN': 'FRANCHISE_CUSTOMER_TOP_TEN',
  'FRANCHISE_TOP_TWO_CALCULATE_REVENUE': 'FRANCHISE_TOP_TWO_CALCULATE_REJECTION',
  'FRANCHISE_TOP_TWO_EXPENSE_CALCULATIONS': 'FRANCHISE_TOP_TWO_EXPENSE_CALCULATIONS',
  'FINANCIAL_GOALS': 'FINANCIAL_GOALS',
  'FINANCIAL_WORKSHEET': 'FINANCIAL_WORKSHEET',
  'MONTHLY_BUDGET': 'MONTHLY_BUDGET',
  'FRANCHISOR_METRICS': 'FRANCHISOR_METRICS',
  'FRANCHISE_MODEL_ASSESSMENT': 'FRANCHISE_MODEL_ASSESSMENT',
  'MARKETING_ASSESSMENT': 'MARKETING_ASSESSMENT',
}

export const WORK_BOOK_CHAPTER_RULES = [
  [
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL_WITH_HIGHLIGHTED_THREE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW
    ],
  ],
  [
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_FIVE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_FIVE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_MODEL_ASSESSMENT
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MARKETING_ASSESSMENT
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_THREE
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO
    ],
  ],
  [
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE_FRANCHISEE,
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE_FRANCHISEE,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_CUSTOMER_TOP_TEN,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_CUSTOMER_TOP_TEN,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_CALCULATE_REVENUE,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_CALCULATE_REVENUE,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_EXPENSE_CALCULATIONS,
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_TWO_EXPENSE_CALCULATIONS,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_GOALS,
      WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_GOALS,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_WORKSHEET,
      WORK_BOOK_CHAPTER_RULE_TYPES.FINANCIAL_WORKSHEET,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MONTHLY_BUDGET,
      WORK_BOOK_CHAPTER_RULE_TYPES.MONTHLY_BUDGET,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISOR_METRICS,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.FRANCHISE_TOP_ONE,
    ],
  ],
  [
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE,
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_TWO_CANDIDATES,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.MIN_ONE,
    ],
    [
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.EVERYTHING_IS_FULL,
      WORK_BOOK_CHAPTER_RULE_TYPES.ALLOW,
    ],
  ]
]


// Tiker list franchise id`s

export const TIKER_LIST_FRANCHISE_IDS = [
  10330,
  3007,
  2867,
  9148,
  10268,
  3810,
  3547,
]


// Mobile

export const MOBILE_DEFAULT_FILTER_OBJECT = {
  business_name: '',
  industry: '',
  tags_id: '',
  states_id: '',
  established: '',
  franchising_since: '',
  investment: {
    min: '',
    max: '',
  },
  number_of_locations: {
    min: '',
    max: '',
  },
  franchise_fee: {
    min: '',
    max: '',
  },
  average_revenue: {
    min: '',
    max: ''
  },
  fdd_available: '',
  item_7_available: '',
  item_19_available: '',
}

// Account franchisor crm task
export const ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS = {
  'TODAY': 'TODAY',
  'TOMORROW': 'TOMORROW',
  'IN_TWO_BUSINESS_DAYS': 'IN_TWO_BUSINESS_DAYS',
  'IN_THREE_BUSINESS_DAYS': 'IN_THREE_BUSINESS_DAYS',
  'IN_ONE_WEEK': 'IN_ONE_WEEK',
  'IN_TWO_WEEK': 'IN_TWO_WEEK',
  'IN_ONE_MONTH': 'IN_ONE_MONTH',
  'IN_THREE_MONTHS': 'IN_THREE_MONTHS',
  'IN_SIX_MONTHS': 'IN_SIX_MONTHS',
  'CUSTOM_DATE': 'CUSTOM_DATE',
}

// Account franchisor crm task statuses
export const ACCOUNT_FRANCHISOR_TASK_STATUS_LIST = [
  {
    text: 'New',
    value: 0,
    slug: 'new'
  },
  {
    text: 'Scheduled',
    value: 1,
    slug: 'scheduled',
  },
  {
    text: 'Completed',
    value: 2,
    slug: 'completed',
  },
]

export const ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS = {
  'AT_TASK_DUE_TIME': 'AT_TASK_DUE_TIME',
  'ONE_HOUR_BEFORE': 'ONE_HOUR_BEFORE',
  'ONE_DAY_BEFORE': 'ONE_DAY_BEFORE',
  'ONE_WEEK_BEFORE': 'ONE_WEEK_BEFORE',
  'CUSTOM_DATE': 'CUSTOM_DATE',
}

export const ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS_ITEMS_LIST = [
  {
    text: 'Today',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.TODAY,
    showDayOfWeek: false,
    showDayAndMonth: false,
  },
  {
    text: 'Tomorrow',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.TOMORROW,
    showDayOfWeek: false,
    showDayAndMonth: false,
  },
  {
    text: 'In 2 business days',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_TWO_BUSINESS_DAYS,
    showDayOfWeek: true,
    showDayAndMonth: false,
  },
  {
    text: 'In 3 business days',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_THREE_BUSINESS_DAYS,
    showDayOfWeek: true,
    showDayAndMonth: false,
  },
  {
    text: 'In 1 week',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_ONE_WEEK,
    showDayOfWeek: false,
    showDayAndMonth: true,
  },
  {
    text: 'In 2 week',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_TWO_WEEK,
    showDayOfWeek: false,
    showDayAndMonth: true,
  },
  {
    text: 'In 1 month',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_ONE_MONTH,
    showDayOfWeek: false,
    showDayAndMonth: true,
  },
  {
    text: 'In 3 months',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_THREE_MONTHS,
    showDayOfWeek: false,
    showDayAndMonth: true,
  },
  {
    text: 'In 6 months',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.IN_SIX_MONTHS,
    showDayOfWeek: false,
    showDayAndMonth: true,
  },
  {
    text: 'Custom date',
    value: ACCOUNT_FRANCHISOR_DUE_DATE_PRESETS.CUSTOM_DATE,
    showDayOfWeek: false,
    showDayAndMonth: false,
  },
]
export const ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS_ITEMS_LIST = [
  {
    text: 'At task due time',
    value: ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.AT_TASK_DUE_TIME,
  },
  {
    text: '1 hour before',
    value: ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_HOUR_BEFORE,
  },
  {
    text: '1 day before',
    value: ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_DAY_BEFORE,
  },
  {
    text: '1 week before',
    value: ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.ONE_WEEK_BEFORE,
  },
  {
    text: 'Custom date',
    value: ACCOUNT_FRANCHISOR_REMINDER_DATE_PRESETS.CUSTOM_DATE,
  },
]


export const CONTACT_STATUS_LIST =[
  {
    text: 'New',
    value: 0,
  },
  {
    text: 'Attempting',
    value: 1,
  },
  {
    text: 'Not Contacted',
    value: 2,
  },
  {
    text: 'In Process',
    value: 3,
  },
]


export const COMPANY_PHONE_NUMBER = 8444750123


export const MASK_PHONE = '(###) ###-####'







